import React, { useCallback, useRef } from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import rawStyles from '../MakerCardView/index.module.scss';
import PlaylistsBuilderContent from '../MakerCardView/PlaylistsBuilderContent';
import {
  dragType,
  empty,
  emptyCallback,
  isTypeShowLikePdf,
  NewUPVViewModes,
  UiComponentTypes,
} from '../../../utils/constants';
import ApproveButton from '../../../features/ApproveButtonPlaylist';
import { actionCreator } from '../../../shared/redux/actionHelper';
import { CurrentPage } from '../../../redux/currentPage/types';
import { isRoleInPlaylist } from '../../../utils/permissions';
import ToScrollHelper from '../components/ToScrollHelper';

const styles = classNames.bind(rawStyles);


const MakerItem = React.memo(function MakerItem({
  itemIndex,
  item,
  isSelectLikeHover,
  isHoverByDnd,
  isHoverByDndOnEmpty,
  rawPages,
  selectedIds,
  selectedIndex,
  refSelectedBlocks,
  setSelectedIndex,
  activeItemIndex,
  setSelectedIds,
  playlistId,
  onEditorAreaMouseDrop,
  hasDraggable,
  setDraggablePage,
  onDragEnter,
  onDragLeave,
  createLinkPage,
  addNewLinkPageByIndex,
  duplicateHandler,
  selectionBox,
  addBlockToScrollRefs,
  lineStylesObject,
  onPasteFile,
  needToShowVioletStripe,
  playlistType,
  ...props
}) {
  const history = useHistory();
  const { type: draggableType } = useSelector((state) => state.draggable);
  const { zActiveItemId, toBeScrolledTo } = useSelector(
    (state) => state.support || empty,
  );
  const { viewMode } = useSelector((state) => state.upv);
  const isCompressView = viewMode === NewUPVViewModes.compressed;
  const { id: userID } = useSelector((state) => state.user);
  const canUpdForRedoUndo = useRef(null);
  const timerId = useRef(null);
  const elementRef = useRef(null);
  const dispatch = useDispatch();
  const { isViewer } = isRoleInPlaylist;
  const isSharedToMe = history.location.pathname.endsWith('shared/');
  const isPublishedInChannel = history.location.pathname.includes('/publish/');

  const setCanUpdForRedo = () => {
    canUpdForRedoUndo.current = true;
    if (timerId.current) {
      clearTimeout(timerId.current);
    }
    timerId.current = setTimeout(() => {
      canUpdForRedoUndo.current = false;
    }, 1000);
  };

  const goToFullScreenViewWrapperForExpanded = (itemIndex, id, position) => {
    const altPosition = elementRef.current.getBoundingClientRect();
    const isPage = item?.type === 'page' || item?.type === 'sharedPage';
    const isPDF = UiComponentTypes.pdf[item?.type];
    const isNeedZoom = isPDF || isPage;
    props.goToFullScreenView(itemIndex, id, position || altPosition, isNeedZoom);
  };

  const onDragEnterOnEmpty = useCallback((e) => {
    e.stopPropagation();
    onDragEnter(`${itemIndex}empty`);
  }, [itemIndex]);

  const onDropOnEmpty = useCallback((e) => {
    onEditorAreaMouseDrop(
      e,
      item.id,
      itemIndex,
      true,
    );
  }, [itemIndex, onEditorAreaMouseDrop]);

  const goToFullScreenView = (id, position) => {
    const itemType = item?.libraryComponent.type;
    const isPage = itemType === 'page' || itemType === 'sharedPage';
    const isPDF = isTypeShowLikePdf[itemType];
    const isNeedZoom = isPDF || isPage;

    props.goToFullScreenView(itemIndex, id, position, isNeedZoom);
  };

  return (
    <div
      data-intersect={item.id}
      data-cy="builder-page-container"
      key={rawPages[itemIndex].id + item.id}
      className={styles('page_container', {
        zActive: zActiveItemId === item.id,
        isExpanded: props.isExpanded,
        with_caption: item.isShowCaption,
        selectedItem: isSelectLikeHover,
        isText: item.type === 'elementText',
        isEmpty: !item?.type,
      })}
      ref={(ref) => {
        addBlockToScrollRefs(ref, item);
        elementRef.current = ref;
      }}
    >
      {/* {!isViewer[props.currentRole] && ( */}
      <div
        onDrop={(e) => {
          onEditorAreaMouseDrop(e, item.id, itemIndex);
        }}
        onDragEnter={(e) => {
          e.stopPropagation();
          onDragEnter(itemIndex);
        }}
        onDragLeave={onDragLeave}
      > <div
        className={styles('next_wrapper', {
          active: isHoverByDnd,
          isCompressView,
        })}
      >
        <div
          className={styles('next_item_container', 'collapsed')}
        >
          <div
            className={styles('next_item_branch', 'collapsed')}
          />
        </div>
        {isHoverByDnd
            && draggableType !== dragType.smartView
            && needToShowVioletStripe && (
              <div className={styles('dragLine_line')} />
        )}
        {lineStylesObject[item.id]?.style !== 'no_line'
            && lineStylesObject[rawPages[itemIndex - 1]?.id]
              ?.style !== 'no_line'
            && rawPages[itemIndex - 1] && (
              <div className={styles('stripe_place')} />
        )}
        </div>
        <div
          data-id={item.id}
          data-type={item.type}
          onDragEnd={() => !isViewer[props.currentRole] && setDraggablePage(false)}
          onDrop={(e) => {
            onEditorAreaMouseDrop(e, item.id, itemIndex, true);
          }}
          onDragEnter={(e) => {
            e.stopPropagation();
            onDragEnter(itemIndex);
          }}
          onDragLeave={(e) => {
            e.stopPropagation();
            onDragLeave(e);
          }}
          className={styles({
            selectedByArrow: isSelectLikeHover,
          })}
        >
          <PlaylistsBuilderContent
            isFinished={props.isFinished}
            isCompressView={isCompressView}
            playlistType={playlistType}
            onDropOnEmpty={onDropOnEmpty}
            isHoverByDndOnEmpty={isHoverByDndOnEmpty}
            onDragEnterOnEmpty={onDragEnterOnEmpty}
            onDragLeave={onDragLeave}
            isExpanded={props.isExpanded}
            onEditorAreaMouseDrop={onEditorAreaMouseDrop}
            currentRole={props.currentRole}
            clear={() => props.clear(item.id)}
            createHandler={
              !isViewer[props.currentRole]
                ? (isAddTop, e) => addNewLinkPageByIndex(itemIndex, e)
                : emptyCallback
            }
            onDragEndHandler={() => {
              // setIndexBranchHover(false);
              // setDraggablePage(false);
            }}
            onClick={props.isExpanded ? goToFullScreenViewWrapperForExpanded : goToFullScreenView}
            blinkIdsArray={props.blinkIdsArray}
            dragStartHandler={props.dragStartHandler(
              { id: item.id, itemIndex },
              item,
            )}
            onlyTextSelected={props.onlyTextSelected}
            createWithSelected={props.createWithSelected}
            deleteCb={props.deleteCb}
            key={`PlaylistsBuilderContent_${item.id}_uniq`}
            item={item}
            nextItemPosition={rawPages[itemIndex + 1]?.position}
            canUpdForRedoUndo={canUpdForRedoUndo}
            setCanUpdForRedo={setCanUpdForRedo}
            playlistId={playlistId}
            isDragHover={
              hasDraggable
              && isHoverByDnd
              && itemIndex !== 'last'
            }
            selectedIds={selectedIds}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            setSelectedIds={setSelectedIds}
            selectionBox={selectionBox}
            refSelectedBlocks={refSelectedBlocks}
            duplicateHandler={() => duplicateHandler(item, itemIndex + 1)
            }
            itemIndex={itemIndex}
            isItemFirst={itemIndex === 0}
            isItemLast={itemIndex === rawPages.length - 1}
            isItemPreLast={itemIndex === rawPages.length - 2}
            isLastElementTextType={
              rawPages[rawPages.length - 1].type === 'elementText'
            }
            isSingleElement={rawPages.length === 1}
            itemStylesObject={lineStylesObject[item.id] || empty}
            onPasteFile={onPasteFile(item, itemIndex)}
            createLinkPage={createLinkPage}
            setIsDNDInTextBlock={props.setIsDNDInTextBlock}
          />
        </div>

        <ToScrollHelper item={item} toBeScrolledTo={toBeScrolledTo} scrollRef={props.elementRef} />

        {(item.interactiveItemData?.type === 'approveButton'
        || item.approveButtonState?.type === 'approveButton') && (
        <ApproveButton
          viewMode={props.isExpanded ? NewUPVViewModes.expanded : NewUPVViewModes.card}
          itemId={item.id}
          upvCard
          saveState={(data) => {
            dispatch(
              actionCreator(CurrentPage.UpdateApproveButtonS, {
                linkPageId: item.id,
                playlistId,
                interactiveItemData: data,
              }),
            );
          }}
          close={() => {
            dispatch(
              actionCreator(
                CurrentPage.UpdateApproveButtonRedux,
                {
                  linkPageId: item.id,
                  playlistId,
                  interactiveItemData: {
                    ...item.interactiveItemData,
                    isNew: false,
                  },
                },
              ),
            );
          }}
          userInterface={
            isSharedToMe
              ? item.mainUserInterface
              : isPublishedInChannel
                ? item.mainUserInterface
                : null
          }
          isDisabled={userID === item?.owner?.id}
          isMainInterface={!!isSharedToMe}
          state={
            item.approveButtonState || item.interactiveItemData
          }
          // isView
          id="componentId"
          isEditable
          isExpandedView={props.isExpanded}
        />
        )}
      </div>
    </div>
  );
});

export default MakerItem;
