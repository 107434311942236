import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const PlaylistElementDAL = (props) => {
  const { components: uploads } = useSelector((state) => state.uploads);
  const { blinkIdsArray } = useSelector((state) => state.support);
  // const [item, setItem] = useState(props.item);
  const [error, setError] = useState('');

  useEffect(() => {
    if (uploads[props.item?.id] && uploads[props.item?.id]?.errorMsg) setError(uploads[props.item.id].errorMsg);
  }, [uploads]);
  // useEffect(() => {
  //   if (props.item.isSocket) {
  //     setItem({ ...props.item });
  //   }
  // }, [props.item]);

  return props.renderNewUiComponent({
    error,
    blinkIdsArray,
    displayItem: props.item,
    isBlink: props.isBlink,
    linkPage: props.linkPage,
  });
};

export default PlaylistElementDAL;
