import axios from 'axios';
import {
  ADD_ALL_UNLOCK_ITEM_TO_PLAYLIST,
  APIAuth,
  APICreateLink,
  APIDropboxAuth,
  APIDropboxCheckFile,
  APIErrorReport,
  APIFacebookAuth,
  APIGetPageSharing,
  APIGoogleAuth,
  APIGoogleCheckToken,
  APIGoogleFileModified,
  APIGoogleRevokeToken,
  APILinkGoogleAccount,
  APIParser,
  APIRegister,
  APISendEmailToSharing,
  APISendEmailToSharingMany,
  APISendEmailToSharingPlaylist,
  APISendEmailToSharingPlaylistMany,
  APIUpdateShareToWebPlaylistMany,
  ARR_SUBSCRIBES_URL,
  BASE_SHARE_ENDPOINT_URL,
  CARDS_ENDPOINT,
  CHANNEL_MADE_PUBLIC_URL,
  CHANNEL_REARRANGE,
  CHANNEL_ROLE,
  CHANNELS_ENDPOINT,
  CONTACT_REARRANGE,
  CHECK_CHANGE_EMAIL_JWT,
  CHECK_USER,
  COLOR_ENDPOINT,
  COLUMNS_ENDPOINT,
  COMPONENT_ENDPOINT,
  CONFIRM_EMAIL_CHANGE,
  CONFIRM_SUBSCRIBE_URL,
  CREATE_CONTACT,
  CREATE_PLAYLIST_WITH_SELECTED_LIB_ITEMS,
  CROP_PLAYLIST,
  DELETE_AVATAR,
  DELETE_CONTACT,
  DELETE_LIB_ITEM,
  DELETE_PLAYLIST,
  DISCARD_PUBLISH,
  EMOJI_ENDPOINT,
  FORGOT_PASSWORD_URL,
  GET_CHANNELS,
  GET_CONTACT,
  GET_COUNTER_SMART_VIEW,
  GET_DELETED,
  GET_HELP,
  GET_MATRIX_DATA,
  GET_PLAYLIST, GET_PLAYLIST_INFO,
  GET_PLAYLIST_METADATA,
  GET_PREVIEW_PAGES,
  GET_SHARED_PAGE_METADATA,
  GET_SINGLE_ITEM,
  GET_SINGLE_PLAYLIST,
  GET_SINGLE_SHARED_PAGE,
  GET_USER_PLAYLISTS,
  GET_USER_PLAYLISTS_PRE_CHECK,
  GET_USER_SHORTCUTS, INBOX_ENDPOINT,
  LIBRARY_COMPONENT_ENDPOINT,
  LINK_PAGES_ENDPOINT, MANY_PLAYLIST_PUBLISH,
  NEURAL_NETWORK_ENDPOINT,
  MEDIA_STATUS,
  METHOD, OUTBOX_ENDPOINT,
  PAGE_MOVE_TO_TRASH,
  PLAYLIST_ENDPOINT, PLAYLIST_MANAGER_CHANNEL_ENDPOINT,
  PLAYLIST_MANAGER_ENDPOINT,
  PLAYLIST_MOVE_TO_TRASH,
  PLAYLIST_PUBLISH,
  REMOVE_PLAYLIST_FROM_CHANNEL,
  RESET_PASSWORD_URL,
  RESTORE_LIBITEM,
  RESTORE_PLAYLIST,
  SAVE_SETTINGS,
  SETTINGS_ENDPOINT, SHARED_ENDPOINT,
  SMARTVIEW_ENDPOINT,
  TAGS_ENDPOINT,
  UNPUBLISH_PLAYLIST,
  UPLOAD_IMAGE_BY_URL,
  UPV_PLAYLIST_IDS,
  USER_ENDPOINT,
  USER_INTERFACE_ENDPOINT,
  USERS_CONTACT_MAP_URL,
  STRIPE,
} from './constants';

export const request = (url, method = METHOD.POST) => (data, params = {}) => {
  const token = sessionStorage.getItem('jwt') || localStorage.getItem('jwt');
  const headers = {
    token,
    'X-Content-Type-Options': 'nosniff',
    'X-Requested-With': 'XMLHttpRequest',
  };
  if (method === METHOD.POST || method === METHOD.PUT) {
    return axios({
      method,
      url: url.replace(/([^:]\/)\/+/g, '$1'),
      params,
      headers,
      data,
    });
  }
  if (method === METHOD.GET) {
    return axios({
      method,
      params,
      url: `${url}${data ? `/${data.join('/')}` : ''}`.replace(
        /([^:]\/)\/+/g,
        '$1',
      ),
      headers,
    });
  }
  if (method === METHOD.PUT) {
    return axios({
      method,
      url: url.replace(/([^:]\/)\/+/g, '$1'),
      params,
      headers,
      data,
    });
  }
  if (method === METHOD.PATCH) {
    return axios({
      method,
      url: url.replace(/([^:]\/)\/+/g, '$1'),
      params,
      headers,
      data,
    });
  }
  if (method === METHOD.DELETE) {
    return axios({
      method,
      url: `${url}`.replace(/([^:]\/)\/+/g, '$1'),
      headers,
    });
  }
  return null;
};

export const requestChannel = (url) => ({ channelId, data }) => {
  const token = sessionStorage.getItem('jwt') || localStorage.getItem('jwt');
  const headers = {
    token,
    'X-Content-Type-Options': 'nosniff',
    'X-Requested-With': 'XMLHttpRequest',
  };
  return axios({
    method: METHOD.POST,
    url: `${url}/${channelId}`,
    headers,
    data,
  });
};

export const get = (url) => ({ key, token }) => {
  const headers = { token, 'X-Content-Type-Options': 'nosniff' };
  return axios({
    url: `${url}/${key}`,
    method: METHOD.GET,
    headers,
  });
};
export const getUserMap = (url) => () => {
  const token = sessionStorage.getItem('jwt') || localStorage.getItem('jwt');
  const headers = {
    token,
    'X-Content-Type-Options': 'nosniff',
    'X-Requested-With': 'XMLHttpRequest',
  };
  return axios({
    url,
    method: METHOD.GET,
    headers,
  });
};

export const deleteQuery = (url) => (data) => {
  const token = sessionStorage.getItem('jwt') || localStorage.getItem('jwt');
  const headers = {
    token,
    'X-Content-Type-Options': 'nosniff',
    'X-Requested-With': 'XMLHttpRequest',
  };
  return axios({
    method: METHOD.DELETE,
    url,
    headers,
    data,
  });
};

export const requestAuth = request(APIAuth);
export const requestGoogleAuth = request(APIGoogleAuth);
export const requestCreateGoogleLinkedAccount = request(APILinkGoogleAccount);
export const requestFacebookAuth = request(APIFacebookAuth);
export const requestRegister = request(APIRegister);
export const requestErrorReport = request(APIErrorReport);
export const requestParser = request(APIParser);
export const requestCreateLinkComponent = request(APICreateLink);
export const requestSendEmailToSharing = request(APISendEmailToSharing);
export const requestSendEmailToSharingMany = request(APISendEmailToSharingMany);
export const requestSendEmailToSharingPlaylist = request(
  APISendEmailToSharingPlaylist,
);
export const requestSendEmailToSharingPlaylistMany = request(
  APISendEmailToSharingPlaylistMany,
);
export const requestShareToWebPlaylistMany = request(
  APIUpdateShareToWebPlaylistMany,
);
export const requestAddSubscribe = request(ARR_SUBSCRIBES_URL);
export const requestForgotPasswordLink = request(FORGOT_PASSWORD_URL);
export const requestResetPasswordLink = request(RESET_PASSWORD_URL);
export const requestConfirmSubscribe = get(CONFIRM_SUBSCRIBE_URL);
export const requestCheckUser = get(CHECK_USER);
export const requestChannelRoleChange = requestChannel(CHANNEL_ROLE);
export const requestContactDeletion = deleteQuery(DELETE_CONTACT);
export const requestUploadImageByUrl = request(UPLOAD_IMAGE_BY_URL);
export const requestGetUserMap = getUserMap(USERS_CONTACT_MAP_URL);
export const requestNotifyChannelPublic = request(CHANNEL_MADE_PUBLIC_URL);
export const requestPlaylistPublish = request(PLAYLIST_PUBLISH);
export const requestManyPlaylistPublish = request(MANY_PLAYLIST_PUBLISH);
export const requestPlaylistDiscard = request(DISCARD_PUBLISH);
export const requestMatrixData = request(GET_MATRIX_DATA, METHOD.GET);
export const requestPlaylistMetadata = request(
  GET_PLAYLIST_METADATA,
  METHOD.GET,
);
export const requestSharedComponentMetaData = request(
  GET_SHARED_PAGE_METADATA,
  METHOD.GET,
);
export const requestSharedMakeAllPagesIncomplete = (wrapperId) => request(`${SHARED_ENDPOINT}playlist/incomplete/${wrapperId}`, METHOD.PUT);
export const requestChannelRearrange = request(CHANNEL_REARRANGE);
export const requestContactRearrange = request(CONTACT_REARRANGE);
export const requestSaveSettings = request(SAVE_SETTINGS);
export const requestDeleteAvatar = request(DELETE_AVATAR, METHOD.DELETE);
export const requestCheckChangeEmailJwt = request(
  CHECK_CHANGE_EMAIL_JWT,
  METHOD.GET,
);
export const requestConfirmEmailChange = request(CONFIRM_EMAIL_CHANGE);
export const requestUsersShortCuts = request(GET_USER_SHORTCUTS, METHOD.GET);
export const requestGoogleTokenValid = request(APIGoogleCheckToken, METHOD.GET);
export const requestGoogleTokenRevoke = request(
  APIGoogleRevokeToken,
  METHOD.GET,
);
export const requestFileModifiedDate = request(
  APIGoogleFileModified,
  METHOD.GET,
);
export const requestDropboxAuth = request(APIDropboxAuth, METHOD.GET);
export const requestCheckDropboxFileMeta = request(APIDropboxCheckFile);
export const requestPagePreview = (type) => request(GET_PREVIEW_PAGES(type), METHOD.GET);
export const requestPlaylist = (type, id) => request(GET_PLAYLIST(type, id), METHOD.GET);
export const requestPlaylistInfo = (type, id) => request(GET_PLAYLIST_INFO(type, id), METHOD.GET);
export const requestSingleItem = (id) => request(GET_SINGLE_ITEM(id), METHOD.GET);
export const requestSingleContact = (id) => request(GET_CONTACT(id), METHOD.GET);
export const requestSinglePlaylist = (id, type) => request(GET_SINGLE_PLAYLIST(id, type), METHOD.GET);
export const requestSingleSharedPage = (id) => request(GET_SINGLE_SHARED_PAGE(id), METHOD.GET);
export const requestCounterSmartView = (id) => request(GET_COUNTER_SMART_VIEW(id), METHOD.GET);
export const requestPlaylistMoveToTrash = (id) => request(PLAYLIST_MOVE_TO_TRASH(id), METHOD.DELETE);
export const requestUnPublishPlaylist = (id) => request(UNPUBLISH_PLAYLIST(id), METHOD.DELETE);
export const requestPageMoveToTrash = (id) => request(PAGE_MOVE_TO_TRASH(id), METHOD.DELETE);
export const requestUserPlaylists = request(GET_USER_PLAYLISTS, METHOD.GET);
export const requestUserPlaylistsPreCheck = request(
  GET_USER_PLAYLISTS_PRE_CHECK,
  METHOD.GET,
);
export const requestDeletedItems = request(GET_DELETED, METHOD.GET);
export const requestDeletePlaylist = (id) => request(`${DELETE_PLAYLIST}${id}`, METHOD.DELETE);
export const requestDeleteLibItem = (id) => request(`${DELETE_LIB_ITEM}${id}`, METHOD.DELETE);
export const requestRestoreLibItems = request(RESTORE_LIBITEM, METHOD.GET);
export const requestRestorePlaylist = request(RESTORE_PLAYLIST, METHOD.GET);
export const requestMediaStatus = (id) => request(MEDIA_STATUS(id), METHOD.GET);
export const requestRemovePlaylistFromChannel = (
  channelId,
  playlistManagerId,
) => request(
  REMOVE_PLAYLIST_FROM_CHANNEL(channelId, playlistManagerId),
  METHOD.GET,
);
export const requestGetChannels = () => request(GET_CHANNELS, METHOD.GET);

export const requestGetHelpPlaylists = (order) => request(GET_HELP(order), METHOD.GET);
export const requestAddAllDownloadableItemsIntoPlaylist = (
  fromPlaylist,
  toPlaylist,
  selectedItems,
) => request(
  ADD_ALL_UNLOCK_ITEM_TO_PLAYLIST(fromPlaylist, toPlaylist),
  METHOD.POST,
);
export const requestCreatePlaylistWithSelectedLibItems = () => request(
  CREATE_PLAYLIST_WITH_SELECTED_LIB_ITEMS, METHOD.POST);
// export const requestPlaylistIDsListForUPV = (
//   playListId,
//   type,
//   sortType,
//   channelId,
// ) => request(UPV_PLAYLIST_IDS(playListId, type, sortType, channelId), METHOD.GET);

export const requestCreateCustomColor = request(COLOR_ENDPOINT);
export const requestUpdateCustomColor = request(COLOR_ENDPOINT, METHOD.PUT);
export const requestUploadInboxCard = (placement = 'all') => request(`${INBOX_ENDPOINT}/${placement}`, METHOD.GET);
export const requestUploadOutboxCard = () => request(`${OUTBOX_ENDPOINT}`, METHOD.GET);

export const requestUploadManySharedPage = (id = '') => request(`${APIGetPageSharing}/${id}`, METHOD.GET);
export const requestUpdateInboxCard = (placement = '') => request(`${INBOX_ENDPOINT}/${placement}`, METHOD.PUT);
export const requestUpdateInBoxCardUnification = request(`${INBOX_ENDPOINT}/update_card`, METHOD.PUT);
export const requestUpdateInBoxCardsMany = (type = '') => request(`${INBOX_ENDPOINT}/update_many/${type}`, METHOD.PUT);

export const requestDeleteOutBoxCard = request(`${OUTBOX_ENDPOINT}/update_card`, METHOD.PUT);
export const requestDeleteOutBoxCardsMany = request(`${OUTBOX_ENDPOINT}/update_many`, METHOD.PUT);

export const requestDeleteCustomColor = deleteQuery(COLOR_ENDPOINT);

export const requestCreateCard = request(CARDS_ENDPOINT);
export const requestUpdateCard = request(CARDS_ENDPOINT, METHOD.PUT);
export const requestDeleteCard = deleteQuery(CARDS_ENDPOINT);

export const requestGetSomeColumnsContent = request(
  COLUMNS_ENDPOINT,
  METHOD.GET,
);
export const requestCreateColumns = request(COLUMNS_ENDPOINT);
export const requestUpdateColumns = request(COLUMNS_ENDPOINT, METHOD.PUT);
export const requestDeleteColumns = deleteQuery(COLUMNS_ENDPOINT);

export const requestCreateChannels = request(CHANNELS_ENDPOINT);
export const requestLeaveChannels = request(`${CHANNELS_ENDPOINT}/leave`, METHOD.PUT);
export const requestUpdateChannels = request(CHANNELS_ENDPOINT, METHOD.PUT);
export const requestDeleteChannels = deleteQuery(CHANNELS_ENDPOINT);
export const requestGetSomeChannelContent = request(
  CHANNELS_ENDPOINT,
  METHOD.GET,
);

export const requestUpdateComponent = (placement = '') => request(`${COMPONENT_ENDPOINT}/${placement}`, METHOD.PUT);
export const requestCreateComponent = (placement = '') => request(`${COMPONENT_ENDPOINT}/${placement}`);
export const requestDeleteComponent = (placement = '') => deleteQuery(`${COMPONENT_ENDPOINT}/${placement}`);

export const requestUpdateUser = request(USER_ENDPOINT, METHOD.PUT);
export const requestUpdateUserPlacement = (placement = '') => request(`${USER_ENDPOINT}/${placement}`, METHOD.PUT);
export const requestGetSomeUserContent = request(USER_ENDPOINT, METHOD.GET);

export const requestCreateTag = request(TAGS_ENDPOINT);
export const requestUpdateTag = request(TAGS_ENDPOINT, METHOD.PUT);
export const requestGetTagCounter = request(`${TAGS_ENDPOINT}/counter`);
export const requestDeleteTag = deleteQuery(TAGS_ENDPOINT);

export const requestCreateSmartView = request(SMARTVIEW_ENDPOINT);
export const requestUpdateSmartView = request(SMARTVIEW_ENDPOINT, METHOD.PUT);
export const requestDeleteSmartView = deleteQuery(SMARTVIEW_ENDPOINT);

export const requestCreateLibraryComponent = request(
  LIBRARY_COMPONENT_ENDPOINT,
);
export const requestGetLibComponent = request(
  LIBRARY_COMPONENT_ENDPOINT,
  METHOD.GET,
);
export const requestUpdateLibraryComponent = (placement = '') => request(`${LIBRARY_COMPONENT_ENDPOINT}/${placement}`, METHOD.PUT);
export const requestUpdateLibraryComponentSeen = request(
  `${LIBRARY_COMPONENT_ENDPOINT}/seen`,
  METHOD.PUT,
);
export const requestDeleteLibraryComponent = (placement = '') => deleteQuery(`${LIBRARY_COMPONENT_ENDPOINT}/${placement}`);
export const requestCreateLibraryComponentPlacement = (placement = '') => request(`${LIBRARY_COMPONENT_ENDPOINT}/${placement}`);

export const requestUpdatePlaylist = (placement = '') => request(`${PLAYLIST_ENDPOINT}/${placement}`, METHOD.PUT);
export const requestUpdatePlaylistText = () => request(`${PLAYLIST_ENDPOINT}/text_update`, METHOD.PUT);
export const requestCreatePlaylist = (placement = '') => request(`${PLAYLIST_ENDPOINT}/${placement}`);
export const requestDeletePlaylistPlacement = (placement = '') => deleteQuery(`${PLAYLIST_ENDPOINT}/${placement}`);
export const requestGetSomePlaylistContent = request(
  PLAYLIST_ENDPOINT,
  METHOD.GET,
);

export const requestUpdateLinkPage = (placement = '') => request(`${LINK_PAGES_ENDPOINT}/${placement}`, METHOD.PUT);
export const requestCreateLinkPage = (placement = '') => request(`${LINK_PAGES_ENDPOINT}/${placement}`, METHOD.POST);
export const requestCollapseLinkPage = (linkPageId = '') => request(`${LINK_PAGES_ENDPOINT}/collapse/${linkPageId}`, METHOD.PUT);
export const requestCollapseAllLinkPages = (linkPageId = '') => request(`${LINK_PAGES_ENDPOINT}/collapse_all_in/${linkPageId}`, METHOD.PUT);
export const requestDeleteLinkPage = (placement = '') => deleteQuery(`${LINK_PAGES_ENDPOINT}/${placement}`);

export const requestCreatePlaylistManager = request(PLAYLIST_MANAGER_ENDPOINT);
export const requestUpdatePlaylistManager = request(
  PLAYLIST_MANAGER_ENDPOINT,
  METHOD.PUT,
);
export const requestUpdatePlaylistManagerInChannel = request(
  PLAYLIST_MANAGER_CHANNEL_ENDPOINT,
  METHOD.PUT,
);
export const requestUpdatePlaylistManagerPlacement = (placement = '') => request(`${PLAYLIST_MANAGER_ENDPOINT}/${placement}`, METHOD.PUT);
export const requestUpdatePlaylistManagerSeen = request(
  `${PLAYLIST_MANAGER_ENDPOINT}/seen`,
  METHOD.PUT,
);

export const requestUpdatePlaylistManagerOpen = request(
  `${PLAYLIST_MANAGER_ENDPOINT}/open`,
  METHOD.PUT,
);

export const requestUpdateUserInterface = (placement = '') => request(`${USER_INTERFACE_ENDPOINT}/${placement}`, METHOD.PUT);
export const requestUpdateUserSettingsPreview = request(`${PLAYLIST_MANAGER_ENDPOINT}/user_preview_settings`, METHOD.PUT);

export const requestGetSomeContactContent = request(CREATE_CONTACT, METHOD.GET);
export const requestCreateContactContact = request(CREATE_CONTACT, METHOD.POST);

export const requestDeleteEmoji = (key = '') => deleteQuery(`${EMOJI_ENDPOINT}/${key}`);
export const requestUpdateEmoji = (key = '') => request(`${EMOJI_ENDPOINT}/${key}`, METHOD.PUT);

export const requestGetSortOptions = request(
  `${SETTINGS_ENDPOINT}/sort`,
  METHOD.GET,
);
export const requestUpdateSortOption = request(
  `${SETTINGS_ENDPOINT}/sort`,
  METHOD.PUT,
);


export const requestGetUserShareOptions = (id, type) => request(`${BASE_SHARE_ENDPOINT_URL}/userstosharing/${id}/${type}`, METHOD.GET);
export const requestGetLinkPagesInfo = (id) => request(`${PLAYLIST_ENDPOINT}/linkpages/${id}`, METHOD.GET);
export const requestGetPublishOptions = (id, type) => request(`${PLAYLIST_MANAGER_ENDPOINT}/channelpublish/${id}`, METHOD.GET);
export const requestUpdateSingleUserShareOptions = (wrapperId, userEmail) => request(`${BASE_SHARE_ENDPOINT_URL}/single/${wrapperId}/${userEmail}`, METHOD.PUT);
export const requestUpdateSingleUserSharePageOptions = (id, userEmail) => request(`${BASE_SHARE_ENDPOINT_URL}/singlepage/${id}/${userEmail}`, METHOD.PUT);

export const requestCropPlaylistCover = (id) => request(CROP_PLAYLIST(id), METHOD.POST);


export const requestAITask = request(`${NEURAL_NETWORK_ENDPOINT}/user_task/`, METHOD.POST);
export const requestAISend = request(`${NEURAL_NETWORK_ENDPOINT}/send_to_ai_parse/`, METHOD.POST);
export const requestCheckAISend = request(`${NEURAL_NETWORK_ENDPOINT}/check_has_ai_parse/`, METHOD.POST);
export const requestAIUnvectorizeLinkPage = (linkPageId) => request(`${NEURAL_NETWORK_ENDPOINT}/clear_ai_parse/${linkPageId}/`, METHOD.PATCH);
export const requestAISearch = (playlistManagerId) => request(`${NEURAL_NETWORK_ENDPOINT}/find/${playlistManagerId}`, METHOD.PUT);

export const requestSummarizeMultiple = () => request(`${NEURAL_NETWORK_ENDPOINT}/summarize/`, METHOD.POST);
export const requestTranslateMultiple = () => request(`${NEURAL_NETWORK_ENDPOINT}/send_to_ai_translate/`, METHOD.POST);
export const requestStopAIProcessing = request(`${NEURAL_NETWORK_ENDPOINT}/close_tasks/`, METHOD.POST);

export const createPaymentIntent = request(`${STRIPE}/payment-intent`, METHOD.POST);
export const cancelStripeSubscription = request(`${STRIPE}/cancel-subscription`, METHOD.POST);
export const changeStripeSubscriptionTier = request(`${STRIPE}/update-subscription`, METHOD.POST);
export const requestInvoicesAndCards = request(`${STRIPE}/invoices-and-cards`, METHOD.GET);
export const requestAttachPaymentMethod = request(`${STRIPE}/attach-payment-method`, METHOD.POST);
export const requestChangePaymentMethod = request(`${STRIPE}/change-payment-method`, METHOD.POST);
