import {
  createTableColumn,
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
  useTableColumnSizing_unstable,
  useTableFeatures,
} from '@fluentui/react-components';
import * as React from 'react';
import { useEffect, useState } from 'react';
import './table.scss';
import styled from 'styled-components/macro';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { timeSince } from '../../utils/dateConvert';
import { singleClickHandlerWrapper } from '../../utils/tableHelpers/singleClickHandler';
import TableCheckBoxComponent from '../MiniPlaylist/NewPlaylist2023/childComponents/CheckBoxComponent';
import SelectedWrapper from '../MiniPlaylist/NewPlaylist2023/childComponents/SelectedWrapper';
import useWindowDimensions from '../../utils/hooks/useWindowDimensions';
import { generateColumns, generateColumnSizing } from './Columns';
import { GetLibComponentType } from '../../utils/UIHelpers/PlaylistBuilderElements/typeResolvers';
import { getExtention } from '../../utils/tableHelpers/common';
import { toggleSelectedWrapper } from '../../utils/tableHelpers/toggleSelected';
import TagsCell from './TagsCell';
import { empty } from '../../utils/constants';
import ImageWithPreview from './ImageWithPreview';
import { LibraryTableRow } from './LibraryTableRow';
import ActionsForTable from '../MiniPlaylist/NewPlaylist2023/childComponents/ActionsForTable';

const TableWrapper = styled.div`
  tbody:before {
    content: "@";
    display: block;
    line-height: 10px;
    text-indent: -99999px;
  }
`;

export const FluentTableViewForLib = ({ ...props }) => {
  const [data, setData] = React.useState([]);
  const { id: currentPageId } = useSelector(
    (state) => state.currentPage || empty,
  );
  const { unseenPagesManagerMap } = useSelector((state) => state.support) || empty;
  const [limiter, setLimiter] = useState(3);
  const [fhd, setFHD] = useState(false);
  const [noPaddings, setNoPaddings] = useState(false);

  const columnsDef = generateColumns(createTableColumn, props.setSort);
  const [columns] = React.useState(columnsDef);
  const [columnSizingOptions, setHer] = React.useState(generateColumnSizing());
  const { width } = useWindowDimensions();

  useEffect(() => {
    setHer(generateColumnSizing());
    if (width > 1955) {
      setFHD(true);
      setLimiter(3);
      setNoPaddings(false);
    } else if (width > 1715) {
      setLimiter(2);
      setNoPaddings(false);
    } else {
      setLimiter(1);
      setFHD(false);
      setNoPaddings(false);
    }
  }, [width]);

  const { getRows, columnSizing_unstable, tableRef } = useTableFeatures(
    {
      columns,
      items: data,
    },
    [useTableColumnSizing_unstable({ columnSizingOptions })],
  );

  const rows = getRows();
  const singleClickHandler = (event, elementProps, index, noUPV = false, isNeedZoom) => {
    singleClickHandlerWrapper({
      event,
      props: { ...props, index },
      currentPageId,
      item: elementProps,
      toggleSelected: toggleSelectedWrapper,
      dispatch,
      history,
      unseenPagesManagerMap,
      noUPV,
      isNeedZoom,
    });
  };

  useEffect(() => {
    if (props.data) {
      const keys = Object.keys(props.data);
      const dataArr = [];
      keys.forEach((key, index) => {
        const item = props.data[key];
        const objToBeInserted = {
          checkbox: {
            label:
              item.type === 'sharedPage' ? (
                <div />
              ) : (
                <TableCheckBoxComponent
                  isSimplifiedMultiselect={!props.isSimplifiedMultiselect}
                  setSimplifiedMultiselect={props.setSimplifiedMultiselect}
                  itemID={item.id}
                />
              ),
          },
          image: { label: <ImageWithPreview item={item} isLibrary /> },
          name: {
            label: (
              <SelectedWrapper itemID={item.id} maxWidth={300}>
                {item.title || 'Untitled'}
              </SelectedWrapper>
            ),
          },
          tags: {
            label: (
              <TagsCell
                type={item.type}
                tags={item.contentTags || []}
                limiter={limiter}
                fhd={fhd}
                noPaddings={noPaddings}
                itemID={item.id}
              />
            ),
          },
          type: { label: GetLibComponentType(item.type, getExtention(item)) },
          created: { label: timeSince(item.createDate * 1000) },
          modified: { label: timeSince(item.lastModifiedDate * 1000) },
          size: {
            label: item.size ? `${(item.size / 1000000).toFixed(1)}MB` : '--',
          },
          actions1: {
            label: (
              <ActionsForTable
                singleClickHandler={singleClickHandler}
                isLib
                isFavorite={item.isFavorite}
                item={item}
                index={index}
              />
            ),
          },
          elementProps: item,
        };
        dataArr.push(objToBeInserted);
      });
      setData(dataArr);
    }
  }, [props.data, props.contentIds.length]);

  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <TableWrapper
      className={`
    table_for_lib
        ${props.noHeader ? 'disable_headers' : ''}
        ${!props.noHeader && props.isRecent ? 'make_before_great_again' : ''}
     `}
    >
      <Table sortable aria-label="Table with sort" ref={tableRef}>
        {/* {!props.disableHeader && ( */}
        <TableHeader>
          <TableRow>
            {columns.map((column) => (
              <TableHeaderCell
                className={`${props.isRecent ? 'no_resize' : ''}`}
                key={column.columnId}
                {...columnSizing_unstable.getTableHeaderCellProps(
                  column.columnId,
                )}
              >
                {column.renderHeaderCell()}
              </TableHeaderCell>
            ))}
          </TableRow>
        </TableHeader>
        {/* )} */}
        <TableBody>
          {rows.map(({ item }, index) => (
            <LibraryTableRow
              key={item.id}
              item={item}
              index={index}
              columnSizing_unstable={columnSizing_unstable}
              singleClickHandler={singleClickHandler}
            />
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};
