import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import rawStyles from './elements.module.scss';
import SixDotsItem from './SixDotsItem';
import useComponentVisibleMouseDown from '../../../utils/hooks/useComponentVisibleMouseDown';
import { PlaylistUI } from '../../../utils/UIHelpers/PlaylistBuilderElements/componentPickers';
import LibraryPlaylistElement from './PlaylistElementCard/PlaylistElement';
import { ElementTypeComponent } from '../../../utils/UIHelpers/ItemTypes/playlistElements';
import DeleteContentButton from './DeleteContentButton';
import { DATA_PARENTS, NewUPVViewModes, UiComponentTypes } from '../../../utils/constants';
import { isRoleInPlaylist } from '../../../utils/permissions';
import { actionRemoveLinkPageFromPlaylist } from '../../../redux/currentPage/action';
import PlaylistElementDAL from '../MakerCommon/PlaylistElementDAL';
import EditIcon from '../../../components/EditIcon';

const cx = classNames.bind(rawStyles);

const LibraryElement = ({
  item = {},
  isDragHover,
  playlistId,
  menuOptions,
  itemStylesObject,
  createApproveButton,
  playlistType,
  ...props
}) => {
  const { t } = useTranslation();
  const [menuRef, isActive, setIsActive] = useComponentVisibleMouseDown(
    false,
    DATA_PARENTS.checkItem,
  );
  const { viewMode } = useSelector((state) => state.upv);
  const isCompressView = viewMode === NewUPVViewModes.compressed;

  const { id: userId } = useSelector((state) => state.user);
  const currentPage = useSelector((state) => state.currentPage);
  const ownerID = currentPage?.owner?.id;
  const isAdmin = userId === ownerID;
  const { isViewer } = isRoleInPlaylist;
  const dispatch = useDispatch();
  const [renameMode, setRenameMode] = useState(false);

  const otherOwner = userId !== item?.owner?.id && item.owner;

  const deleteHandler = () => {
    dispatch(
      actionRemoveLinkPageFromPlaylist(
        playlistId,
        item.id,
        item.textComponent?.id || item.libraryComponent?.id,
      ),
    );
  };

  return (
    <div
      className={cx(
        'six_dot_balancer',
        { hoverable: !props.isViewBuilder, isCompressView: props.isCompressView },
        { active: isActive },
      )}
    >
      <div
        className={cx('library_element', {
          dragginOver: isDragHover,
          viewMode: isViewer[props.currentRole],
        })}
        data-parent={item.id}
        onDragEnd={props.onDragEndHandler}
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
        onDragStart={props.dragStartHandler}
        draggable={!isViewer[props.currentRole] && !otherOwner}
      >
        <SixDotsItem
          clear={props.clear}
          userRole={props.userRole}
          createHandler={props.createHandler}
          onDragStartHandler={props.onDragStartHandler}
          onDragEndHandler={props.onDragEndHandler}
          title={item?.title || 'Untitled item '}
          playlistId={playlistId}
          menuRef={menuRef}
          dragStartHandler={props.dragStartHandler}
          isActive={isActive}
          setIsActive={setIsActive}
          state={menuOptions}
          item={item}
          itemStylesObject={itemStylesObject}
          isViewBuilder={props.isViewBuilder}
          otherOwner={otherOwner}
          setRenameMode={setRenameMode}
          duplicateHandler={props.duplicateHandler}
          itemIndex={props.itemIndex}
          createApproveButton={createApproveButton}
          addCaptionToLinkPage={props.addCaptionToLinkPage}
          removeCaptionFromLinkPage={props.removeCaptionFromLinkPage}
          deleteApproveButton={props.deleteApproveButton}
          isViewer={isViewer[props.currentRole]}
          currentRole={props.currentRole}
          ownerID={ownerID}
          isCompressView={isCompressView}
        />

        <PlaylistElementDAL
          item={item?.libraryComponent}
          linkPage={item}
          isSelected={props.builderSelectedIds.includes(item.id)}
          isBlink={props.blinkIdsArray?.includes(item.id)}
          isViewBuilder={props.isViewBuilder || userId !== item?.owner?.id}
          renderNewUiComponent={({
            error,
            blinkIdsArray,
            displayItem,
            isBlink,
            linkPage,
          }) => (
            <LibraryPlaylistElement
              isFinished={props.isFinished}
              playlistType={playlistType}
              linkPage={item}
              isCompressView={isCompressView}
              imageComponent={PlaylistUI.createPreviewComponent(
                displayItem?.type,
                displayItem || {},
                linkPage?.id,
                error,
              )}
              title={linkPage.title || displayItem?.title || t('defaultSmartPageTitleT')}
              typeIcon={ElementTypeComponent(displayItem?.type)}
              isBlink={
                isBlink
                || blinkIdsArray === linkPage.id
                || (blinkIdsArray?.length && blinkIdsArray.includes(linkPage.id))
              }
              goToFullScreenView={props.goToFullScreenView}
              renameRef={props.renameRef}
              linkPageId={linkPage.id}
              caption={linkPage.caption}
              duration={
                linkPage?.libraryComponent?.duration || linkPage.duration
              }
              isShowCaption={linkPage.isShowCaption}
              isRemixLocked={linkPage.isRemixLocked}
              isVideoAudio={
                UiComponentTypes.audio[displayItem?.type]
                || UiComponentTypes.video[displayItem?.type]
              }
              setRenameMode={setRenameMode}
              renameMode={renameMode}
              itemIndex={props.itemIndex}
              currentRole={props.currentRole}
            />
          )}
        />
        {!isViewer[props.currentRole]
          && ((item.owner && userId === item?.owner?.id) || isAdmin) && (
            <DeleteContentButton
              deleteHandler={deleteHandler}
              isCompressView={props.isCompressView}
              isPage={item.libraryComponent?.type === 'page'}
            >
              {item.libraryComponent?.type === 'page' && (item.owner && userId === item?.owner?.id)
                && <EditIcon type="MakerLibraryElement" id={item?.libraryComponent?.id} />
              }
            </DeleteContentButton>
        )}
      </div>
    </div>
  );
};

export default LibraryElement;
