import React, { useEffect, useRef, useState, useCallback } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { DEFAULT_TITLE, stopPropagation } from '../../../utils/constants';
import { getFileName } from '../../../utils/helpers';

const InputShell = styled.div`
  display: flex;
  ${({ isExpandedView, isCompressView }) => (isExpandedView || isCompressView ? '' : 'margin-bottom: 8px;')}
`;

const Input = styled.input`
  background: transparent;
  border: none;
  border-radius: 6px;
  text-overflow: ellipsis;
  resize: none;
  width: 500px;
  padding: 6px;
  max-height: 44px;
  font-family: "RobotoFlex", serif;
  font-style: normal;
  font-weight: 500;
  ${({ isCompressView }) => (isCompressView ? 'font-size: 14px;' : 'font-size: 16px;')}
  line-height: 20px;
  display:none;
  color: #484343;

  &::placeholder,
  &::-webkit-input-placeholder,
  &:-moz-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder {
    color: #a3aacc;
  }

  overflow: hidden;
 
  :focus{
    ${({ isDisabled }) => (isDisabled
    ? ''
    : `border-radius: 8px;
       border: 1px solid #ffc72c;
       background: #ffffff;`)}
  }
`;

const FakeDiv = styled.div`
  border: none;
  width: 100%;
  font-family: "RobotoFlex", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  display:block;
  max-width: 660px;
  white-space: nowrap;
    ${({ isExpandedView }) => (!isExpandedView ? ''
    : `padding-left: 20px;
    padding-bottom: 10px;
    max-width: 860px;`)}
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const TitleTextAreaComponent = ({
  title,
  renameCB,
  show,
  setShow,
  placeholder,
  isDisabled,
  isCompressView,
  ...props
}) => {
  const { t } = useTranslation();
  const [initialTitle, setInitialTitle] = useState(title);
  const [tmpTitle, setTmpTitle] = useState(title);
  const titleRef = useRef(null);
  const fakeTitleRef = useRef(null);
  useEffect(() => {
    setTmpTitle(title);
  }, [title]);

  useEffect(() => {
    if (show) {
      setTmpTitle(title);
      setInitialTitle(title);
    }
  }, [show]);

  useEffect(() => {
    if (props.renameMode) {
      titleRef?.current.focus();
    }
  }, [props.renameMode]);


  const calcThreeDotsNeed = useCallback(() => {
    const multilineSplit = tmpTitle.split('\n');
    if (multilineSplit.length > 1) {
      return `${multilineSplit[0].slice(0, 41)}...`;
    }
    return tmpTitle;
  }, [tmpTitle]);

  const onChangeTitle = (e) => {
    if (isDisabled) return;
    if (!e.currentTarget.value && tmpTitle === getFileName(initialTitle)) setTmpTitle('');
    setTmpTitle(e.currentTarget.value);
    e.preventDefault();
  };

  const handleBlur = () => {
    setTmpTitle(`${tmpTitle || t('defaultuntitledElementT')}`);
    renameCB(`${tmpTitle || t('defaultuntitledElementT')}`);
    props.setRenameMode(false);
    setShow(false);
    fakeTitleRef.current.style.display = 'block';
    titleRef.current.style.display = 'none';
  };

  const handleReplaceWithTextArea = (e) => {
    if (isDisabled) return;
    e.stopPropagation();
    const textarea = titleRef?.current;
    if (textarea) {
      textarea.style.display = 'block';
      textarea.focus();
      textarea.setSelectionRange(textarea.value.length, textarea.value.length);
      fakeTitleRef.current.style.display = 'none';
    }
  };

  const onKeyPressTitle = (e) => {
    if (isDisabled) return;
    if (e.key === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
      setTmpTitle(`${tmpTitle || t('defaultuntitledElementT')}`);
      renameCB(`${tmpTitle || t('defaultuntitledElementT')}`);
      setShow(false);
      titleRef?.current.blur();
    }
    if (e.key === 'Escape') {
      titleRef?.current.blur();
      e.stopPropagation();
      e.preventDefault();
      setShow('close');
      setTmpTitle(title || t('defaultuntitledElementT'));
    }
  };

  return (
    <InputShell isCompressView={isCompressView} isExpandedView={props.isExpandedView}>
      <Input
        onContextMenu={stopPropagation}
        isDisabled={isDisabled}
        isExpandedView={props.isExpandedView}
        cols={22}
        maxLength={255}
        placeholder={placeholder}
        value={tmpTitle}
        ref={titleRef}
        onChange={onChangeTitle}
        onClick={stopPropagation}
        onKeyDown={onKeyPressTitle}
        onBlur={handleBlur}
      />
      <FakeDiv
        ref={fakeTitleRef}
        isExpandedView={props.isExpandedView}
        onClick={handleReplaceWithTextArea}
      >
        {calcThreeDotsNeed()}
      </FakeDiv>
    </InputShell>
  );
};
