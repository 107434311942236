import React, { useEffect, useRef } from 'react';

const UseKeydown = (
  key: string,
  handleDownloadSelected: (e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent) => void,
  emptyCallback: (e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent) => void,
  lockHandler: (e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent) => void,
  onAddItemsToLibraryClickHandler: (e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent) => void,
  onAddItemsToSmartfileClickHandler: (e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent) => void,
  handleSummarize: (e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent) => void,
  deleteHandler: (e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent) => void,
) => {
  const downloadCallback = useRef(handleDownloadSelected);
  const duplicateCallback = useRef(emptyCallback);
  const lockCallback = useRef(lockHandler);
  const addToLibCallback = useRef(onAddItemsToLibraryClickHandler);
  const addToSFCallback = useRef(onAddItemsToSmartfileClickHandler);
  const summarizeCallback = useRef(handleSummarize);
  const deleteCallback = useRef(deleteHandler);

  useEffect(() => {
    downloadCallback.current = handleDownloadSelected;
    duplicateCallback.current = emptyCallback;
    lockCallback.current = lockHandler;
    addToLibCallback.current = onAddItemsToLibraryClickHandler;
    addToSFCallback.current = onAddItemsToSmartfileClickHandler;
    summarizeCallback.current = handleSummarize;
    deleteCallback.current = deleteHandler;
  });

  useEffect(() => {
    const handle = (event: any) => {
      if (event._lexicalHandled
        || event.target.tagName.toUpperCase() === 'INPUT'
        || event.target.tagName.toUpperCase() === 'TEXTAREA'
        || event.altKey || event.metaKey || event.ctrlKey || event.shiftKey
      ) return;
      switch (event.code) {
        case 'KeyD':
          event.preventDefault();
          downloadCallback.current(event);
          break;
        case 'KeyC':
          event.preventDefault();
          duplicateCallback.current(event);
          break;
        case 'KeyL':
          event.preventDefault();
          lockCallback.current(event);
          break;
        case 'KeyN':
          event.preventDefault();
          addToLibCallback.current(event);
          break;
        case 'KeyM':
          event.preventDefault();
          addToSFCallback.current(event);
          break;
        case 'KeyS':
          event.preventDefault();
          summarizeCallback.current(event);
          break;
        case 'Delete':
          event.preventDefault();
          deleteCallback.current(event);
          break;

        default:
          break;
      }
    }

    document.addEventListener('keydown', handle);
    return () => document.removeEventListener('keydown', handle);
  }, [key]);
};

export default UseKeydown;
