import { call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  actionChannel,
  all,
  cps,
  delay,
  select,
  take,
} from '@redux-saga/core/effects';

import { buffers } from 'redux-saga';
import {
  requestAuth,
  requestFacebookAuth,
  requestForgotPasswordLink,
  requestGetSomeUserContent,
  requestGoogleAuth,
  requestResetPasswordLink,
  requestSinglePlaylist,
  requestSingleSharedPage,
  requestUpdateUser,
  requestUsersShortCuts,
} from '../utils/request';
import playlistSaga from './playlistSagas';
import uploadSaga from './uploadSagas';
import downloadSaga from './downloadSagas';
import universalPlaylistViewSaga from './universalPlaylistViewSagas';
import shortcutsSaga from './shortcutsSagas';
import librarySaga from './librarySagas';
import {
  actionLogout,
  actionSaveUser,
  actionSetRequestLoader,
  actionSetServerError,
} from '../redux/user/action';
import { findCommonNotification, getCurrentPage, getSupport, getUser, showErrorMessage } from './sagasUtils';
import pageSaga from './pageSagas';
import { generateSimpleRTBState, sanitizeToLoad } from '../utils/helpers';
import hashtagsSaga from './hashtagsSagas';
import channelSaga from './channelSagas';
import SupportAction from '../redux/support/types';
import EditPlaylist from '../redux/playlists/types';
import {
  actionSaveRedirect,
  actionShowMessage,
  actionToggleRequestSpinner,
} from '../redux/support/action';
import {
  aiProcessingStatusEnum,
  DEFAULT_PAGE_PATH,
  dynamicCollectionColorCalc, INBOX_CARD_TYPE,
  MessageType, SHARED_PLAYLIST_URL,
  SOCKET_EVENT_TYPE,
} from '../utils/constants';
import undoSaga from './undoSagas';
import dynamicCollectionSaga from './dynamicCollectionSagas';
import contactsSaga from './contactsSagas';
import { SAVE_USER, ServiceUser } from '../redux/user/types';
import dashboardSaga from './dashboardSaga';
import { actionCreator } from '../shared/redux/actionHelper';
import { actionLoadUserContactsS } from '../redux/contacts/action';
import { socket } from '../App';
import { EMIT, SUBSCRIBE_SOCKET } from './SocketClusterHelper';
import { actionWriteCombination } from '../redux/shortcuts/action';
import colorsSaga from './colorsSagas';
import settingsSagas from './settingsSagas';
import { Settings } from '../redux/settings/types';
import { UPGRADE_PLANS } from '../utils/SettingsHelpers/constants';
import integrationsSagas from './integrationsSagas';
import contentSagas from './contentSaga';
import deletedSagas from './deletedSagas';
import { counterMap } from '../utils/counters';
import updateCounter from './updateCounter';
import { ContentActionType } from '../redux/content/contentTypes';
import currentPageSagas from './currentPageSagas';
import { actionSortSettingsFromDB } from '../redux/settings/actions';
import { CurrentPage } from '../redux/currentPage/types';
import {
  actionAIProcessingToggleLoading,
  actionDurationByPlaybackId,
  actionSaveSearchResultsR,
  actionSaveSummarize,
} from '../redux/currentPage/action';
import { actionUpdateLibraryComponentInRedux } from '../redux/library/actions';
import { actionRemoveMuxContent } from '../redux/muxContentStatus/actions';
import inboxSaga from './inboxSaga';
import { InboxAction } from '../redux/inbox/types';
import { calcNewUnreadState } from '../utils/dataUtil';
import OutboxSaga from './outboxSaga';
import { actionGetChannels } from '../redux/channels/action';
import neuralSaga from './neuralSaga';
import { setServiceWorkerData } from '../utils/registerServiceWorker';
import { MILLISECONDS_IN_MIN } from '../utils/dateConvert';
import { OutboxAction } from '../redux/outbox/types';


function* loginUser(action) {
  try {
    const { email, password, history } = action.payload;
    const response = yield call(requestAuth, {
      password,
      email: email.toLowerCase().trim(),
    });
    if (response.data.errors) {
      yield put(actionSetServerError(response.data.message));
    } else {
      const { id } = response.data;
      const { redirectLink } = yield select(getSupport);

      // if (isRemember) {
      localStorage.setItem('jwt', response.data.token);
      localStorage.setItem('userId', response.data.id);
      // }
      setServiceWorkerData(response.data.token);
      yield put(actionSaveUser({ id }));

      yield put({ type: 'UPLOAD_DATA', payload: { userId: id, history } });

      if (redirectLink) {
        yield put(actionSaveRedirect(null));
        history.push(redirectLink);
        return;
      }
      history.push(DEFAULT_PAGE_PATH);
    }
  } catch (err) {
    if (err.response.status === 403) {
      yield put(actionSetRequestLoader(false));
      yield put(
        actionSetServerError(
          'Sorry, your account has been blocked by administrator. Please contact feedback@quidzi.com',
        ),
      );
      yield put(actionShowMessage({ type: MessageType.userIsBlocked }));
      return;
    }
    yield showErrorMessage(err, action);
  }
}

function* uploadData(action) {
  try {
    const { userId, history } = action.payload;
    const idDefault = localStorage.getItem('userId') || sessionStorage.getItem('userId');
    const id = userId || idDefault;
    if (!id && history) {
      if (
        history.location.pathname.includes('/login')
        || history.location.pathname.includes('/forgot_password')
        || history.location.pathname.includes('/reset_password')
        || history.location.pathname.includes('/signup')
        || history.location.pathname.includes('/shared_page')
        || history.location.pathname.includes('/sharedwebpage')
        || history.location.pathname.includes(`/${SHARED_PLAYLIST_URL}/`)
        || history.location.pathname.includes('/shared_playlist')
        || history.location.pathname.includes('/publish_page')
      ) return;

      if (history.location.pathname.includes('/confirm_new_email')) {
        yield put({ type: 'IS_DOWNLOAD_USER', payload: false });
        return;
      }

      history.push('/login');
      return;
    }

    yield put(actionSortSettingsFromDB(history));
    const queryCounter = Object.values(counterMap).reduce((prev, query) => {
      if (query) return `${prev + query}_`;
      return prev;
    }, '');

    const { data } = yield requestGetSomeUserContent(['data', queryCounter]);
    const user = data.User[0];

    if (!user) {
      history.push('/login');
      return;
    }

    const {
      data: { userLinkedAccounts },
    } = yield requestGetSomeUserContent(['linked_acc']);
    // const { data: { User: [UserPlaylist] } } = yield wrapperRequestNeo4j(queryGetUserPlaylist(id || userId));
    // const UserPlaylist = {};
    const linkedAccounts = userLinkedAccounts[0]?.linkedAccounts;

    const shortcutsData = yield call(requestUsersShortCuts);

    yield put(
      actionCreator(Settings.Application.LoadUsersShortcuts, {
        shortcuts: shortcutsData.data,
      }),
    );

    const colorsObj = {
      colors: {},
      usedColors: {},
      colorsIds: [],
    };

    if (user?.colors !== null) {
      colorsObj.colorsIds = user?.colors
        ?.sort((colorA, colorB) => {
          return colorA.position - colorB.position;
        })
        .map((item) => {
          colorsObj.colors[item.id] = {
            id: item.id,
            color: item.hexColor,
            position: item.position,
          };
          colorsObj.usedColors[item.hexColor] = true;
          return item.id;
        });
    }

    if (user) {
      yield put(
        actionCreator(ServiceUser.SetUpIsAdmin, { state: !!user.isAdmin }),
      );
      yield put({ type: 'IS_DOWNLOAD_USER', payload: true });
      const {
        last_name: lastName,
        first_name: firstName,
        username,
        showUsername,
        email,
        isShowOnlyNotNestedPages,
        contentTags,
        currentPlan,
        dynamicCollection,
        tierLimits,
        activePaymentMethod,
        subscriptionCanceledAt,
      } = user;
      const tags = contentTags.reduce((acc, item) => {
        acc[item.id] = {
          ...item,
          title: sanitizeToLoad(item.title),
          type: 'tags',
          itemsCounter: item.libraryComponentsCounter + item.playlistsCounter,
        };
        return acc;
      }, {});
      const saveDynamicCollection = {};
      dynamicCollection.forEach((dc) => {
        saveDynamicCollection[dc.id] = {
          id: dc.id,
          name: sanitizeToLoad(dc.name),
          color: dc.color,
          derivedColors: dynamicCollectionColorCalc(dc.color),
          position: dc.position,
          iconKey: dc.iconKey,
          filter: sanitizeToLoad(dc.filter)
            ? JSON.parse(sanitizeToLoad(dc.filter))
            : {},
          libFilterSearch: sanitizeToLoad(dc.search),
          createDate: dc.createDate,
        };
      });

      const calcCounters = {};
      Object.entries(counterMap).forEach(
        ([counterKey, counterPath]) => (calcCounters[counterKey] = user[counterPath] || 0),
      );
      yield put({
        type: SupportAction.InitialDataFetch,
        payload: {
          inBoxCounter: calcCounters[counterMap.sharedPageNotificationCounter]
            + calcCounters[counterMap.sharedPlaylistNotificationCounter]
            + calcCounters[counterMap.inviteInChannelsNotificationCounter],
          outBoxCounter: calcCounters[counterMap.playlistOutNotificationCount]
            + calcCounters[counterMap.pagesOutNotificationCount]
            + calcCounters[counterMap.channelHasMyInviteCounter],
          counters: {
            ...calcCounters,
            'pages/all':
              calcCounters['pages/drafts'] + calcCounters['pages/shared'] + calcCounters['pages/sharedByMe'],
          },
          user: {
            last_name: lastName,
            lastName,
            first_name: firstName,
            firstName,
            email,
            username: sanitizeToLoad(username),
            isShowOnlyNotNestedPages,
            tags,
            id,
            isDownload: false,
            requestSent: false,
            avatarUrlSmall: user.avatarUrlSmall,
            avatarUrlVerySmall: user.avatarUrlVerySmall,
            keepInformed: user.keepInformed,
            newsletterSubscription: user.newsletterSubscription,
            emailNotifications: user.emailNotifications,
            showUsername,
            displayImage: user.displayImage ?? 'AvatarImage',
            currentPlan: currentPlan ?? UPGRADE_PLANS.Free,
            linkedAccounts,
            tierLimits,
            activePaymentMethod,
            subscriptionCanceledAt,
          },
          support: { ...colorsObj },
          dynamicCollections: saveDynamicCollection,
        },
      });
      yield put({ type: 'IS_DOWNLOAD_USER', payload: false });
      yield put(actionLoadUserContactsS(id));
      yield put(actionGetChannels(id));
      yield put(actionCreator(SupportAction.UploadUnseenS, { userId: id }));
    }
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* uploadUnseenOutbox(action) {
  try {
    const user = yield select(getUser);
    if (!user.id) return;
    const { data: { User: [data] } } = yield requestGetSomeUserContent(['unseen_outbox']);
    const unseenOutbox = {};

    data.playlistOutNotificationUnread.forEach((elem) => {
      unseenOutbox[elem.id] = true;
    });
    yield put(
      actionCreator(SupportAction.UploadUnseenOutboxR, { unseenOutbox }),
    );
    yield put(
      actionCreator(OutboxAction.UpdateOutboxCard, { cards: data.playlistOutNotificationUnread }),
    );
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* uploadUnseen(action) {
  try {
    const { data: { User: [data] } } = yield requestGetSomeUserContent(['unseen']);
    const newState = calcNewUnreadState(data);
    yield put(
      actionCreator(SupportAction.UploadUnseenR, { data: newState }),
    );
    while (true) {
      yield uploadUnseenOutbox(action);
      yield delay(MILLISECONDS_IN_MIN); // 1 minute
    }
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* readUnseenPlaylistInChannel(action) {
  try {
    const user = yield select(getUser);
    const { playlistId } = action.payload;
    yield call(requestUpdateUser, {
      id: user.id,
      connect: { playlistSeenByUsersInChannel: [playlistId] },
    });
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* readUnseenPlaylists(action) {
  try {
    const user = yield select(getUser);
    const unreadPlaylist = yield select(
      (state) => state.support.unseenPlaylistManagerMapInAll,
    );
    if (!Object.values(unreadPlaylist).length) return;
    const unseenPlaylistId = Object.values(unreadPlaylist)
      .map((i) => i.playlistId)
      .filter((i) => i);
    if (!unseenPlaylistId.length) return;

    yield call(requestUpdateUser, {
      id: user.id,
      connect: { playlistSeenByUsersInAll: unseenPlaylistId },
    });
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* readOneUnseenPlaylist(action) {
  try {
    const { id } = action.payload;
    const user = yield select(getUser);
    if (!user?.id) {
      return;
    }
    const unseenPlaylistId = [id];
    yield call(requestUpdateUser, {
      id: user.id,
      connect: { playlistSeenByUsersInAll: unseenPlaylistId },
    });
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* getUnseenPlaylist(action) {
  try {
    const { channelId, wrapperId, type } = action.payload;

    const { activeNavSlider, selectorType } = yield select(
      (state) => state.content,
    );

    const isInAllOrNew = activeNavSlider === 'smartfiles'
      && (selectorType === 'all' || selectorType === 'new');
    const isInSHared = activeNavSlider === 'smartfiles' && selectorType === 'shared';
    const isChannelForUpload = activeNavSlider === 'channelPlaylists' && selectorType === channelId;

    const isNeedToAddOnCurrentPage = isInAllOrNew || isChannelForUpload || (isInSHared && type === 'shared');
    if (!isNeedToAddOnCurrentPage) return;

    const { data } = yield call(requestSinglePlaylist(wrapperId, type));
    yield put(
      actionCreator(ContentActionType.updatePlaylistR, {
        content: data,
        type,
        idRemove: wrapperId,
      }),
    );
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* getUnseenPages(action) {
  try {
    const { pageId } = action.payload;

    const { activeNavSlider, selectorType } = yield select(
      (state) => state.content,
    );
    const isInSHared = activeNavSlider === 'pages' && selectorType === 'shared';
    if (!isInSHared) return;
    const { data } = yield call(requestSingleSharedPage(pageId));
    yield put(
      actionCreator(ContentActionType.updatePageR, {
        content: data,
        idRemove: pageId,
      }),
    );
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* sendForgotPasswordRequest(action) {
  try {
    let data;
    const { payload } = action;
    yield put(actionToggleRequestSpinner(true));
    try {
      data = yield call(requestForgotPasswordLink, { email: payload.email.trim() });
      if (data.status === 200) {
        yield put(
          actionShowMessage({
            type: MessageType.ForgotPassword_Success,
            email: payload.email,
          }),
        );
      }
      yield put(actionToggleRequestSpinner());
      payload.cb();
    } catch (err) {
      if (err.response?.data?.message === 'Incorrect email') {
        payload.errorCb('Incorrect email');
      } else if (err.response?.data?.message === 'User not exists') {
        payload.errorCb("User doesn't exist");
      } else {
        yield put(
          actionShowMessage({
            type: MessageType.ForgotPassword_Error,
            notice: err.message,
          }),
        );
      }


      yield put(actionToggleRequestSpinner());
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* sendResetPasswordRequest(action) {
  try {
    yield put(actionToggleRequestSpinner(true));
    let data;
    const { payload } = action;
    try {
      data = yield call(requestResetPasswordLink, {
        password: payload.password,
        key: payload.params.key,
      });
      if (data.status === 200) {
        yield put(
          actionShowMessage({
            type: MessageType.ResetPassword_Success,
            email: payload.email,
          }),
        );
      }
      yield put(actionToggleRequestSpinner());
      yield delay(300);
      yield payload.history.push('/login');
    } catch (err) {
      yield put(actionToggleRequestSpinner());
      if (err.response.status === 418) {
        yield put(
          actionShowMessage({
            type: MessageType.ResetPassword_Error,
            notice: err.response.data.message,
          }),
        );
      }
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* loginFacebook(action) {
  try {
    const { data, history } = action.payload;

    if (data.status && data.status === 'not_authorized') {
      yield put(actionSetServerError('Facebook authentication failed'));
      return;
    }
    if (data.status && data.status === 'unknown') {
      yield put(actionSetServerError('You closed authentication PopUp'));
      return;
    }

    const isAuthStatusCheck = !data.accessToken;
    if (isAuthStatusCheck) return;

    if (!data.email) {
      yield put(actionSetServerError('You have to grant email permission'));
      return;
    }

    const apiData = yield requestFacebookAuth({
      accessToken: data.accessToken,
      firstName: data.first_name,
      lastName: data.last_name,
      email: data.email,
    });

    if (apiData?.error) {
      yield put(actionSetServerError(apiData.error));
    } else if (apiData.data.errorMessage) {
      yield put(actionSetServerError(apiData.data.errorMessage));
    } else {
      localStorage.setItem('jwt', apiData.data.token);
      localStorage.setItem('userId', apiData.data.id);
      setServiceWorkerData(apiData.data.token);
      yield put(actionSetRequestLoader(false));
      // yield put({ type: 'IS_DOWNLOAD_USER', payload: false });
      yield put({
        type: 'UPLOAD_DATA',
        payload: { userId: apiData.data.id, history },
      });
      history.push(DEFAULT_PAGE_PATH);
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* loginGoogle(action) {
  try {
    const { data, history } = action.payload;

    const apiData = yield requestGoogleAuth({ code: data.code });

    if (apiData?.error) {
      yield put(actionSetServerError(apiData.error));
    } else if (apiData.data.errorMessage) {
      yield put(actionSetServerError(apiData.data.errorMessage));
    } else {
      localStorage.setItem('jwt', apiData.data.token);
      localStorage.setItem('userId', apiData.data.id);
      setServiceWorkerData(apiData.data.token);
      yield put(actionSetRequestLoader(false));
      // yield put({ type: 'IS_DOWNLOAD_USER', payload: false });
      yield put({
        type: 'UPLOAD_DATA',
        payload: { userId: apiData.data.id, history },
      });
      if (apiData?.data?.googleAccessToken) {
        yield put(
          actionCreator(SAVE_USER, {
            googleAccessToken: apiData?.data?.googleAccessToken,
          }),
        );
      }
      history.push(DEFAULT_PAGE_PATH);
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

export function* handleEmit(
  socketConnection,
  {
    event,
    autoReconnectOptions = socketConnection.autoReconnectOptions || {},
    payload,
  },
) {
  const {
    initialDelay = 10000,
    randomness = 10000,
    multiplier = 1.5,
    maxDelay = 60000,
  } = autoReconnectOptions;
  let timeout;
  let exponent = 0;

  while (true) {
    // eslint-disable-line no-constant-condition
    try {
      if (payload.payload.message.isNotificationUpdate) {
        socket.transmit('pushUpdateToChannels', {
          eventType: SOCKET_EVENT_TYPE.UPDATE_NOTIFICATION,
          ...payload.payload.message,
          uniqSocketId: sessionStorage.getItem('uniqSocketId'),
          sender:
            localStorage.getItem('userId') || sessionStorage.getItem('userId'),
        });
      }
      if (payload.payload.message.pushToChannel) {
        socket.transmit('pushToChannel', {
          dataSend: payload.payload.message.dataSend,
          pushToChannel: payload.payload.message.pushToChannel,
          uniqSocketId: sessionStorage.getItem('uniqSocketId'),
          sender:
            localStorage.getItem('userId') || sessionStorage.getItem('userId'),
        });
      } else if (payload.payload?.message?.closePlaylist) {
        socket.transmit('closePlaylist', {
          closePlaylist: payload.payload?.message?.closePlaylist,
          closeForUser: payload.payload?.message?.closeForUser,
          uniqSocketId: sessionStorage.getItem('uniqSocketId'),
          sender:
            localStorage.getItem('userId') || sessionStorage.getItem('userId'),
        });
      } else if (payload.payload?.message?.closePage) {
        socket.transmit('closePage', {
          closePage: payload.payload?.message?.closePage,
          closeForUser: payload.payload?.message?.closeForUser,
          uniqSocketId: sessionStorage.getItem('uniqSocketId'),
          sender:
            localStorage.getItem('userId') || sessionStorage.getItem('userId'),
        });
      } else if (payload.payload?.message?.dataSend?.unsubscribeChannel) {
        const idUnsubscribeChannel = payload.payload.message.unsubscribeChannel;
        socket.unsubscribe(idUnsubscribeChannel);
        yield put(actionCreator(`STOP_BACKGROUND_SYNC ${idUnsubscribeChannel}`, {}));
      } else if (payload.payload.message.sendChannel) {
        socket.transmit(
          payload.payload.message.pageId,
          payload.payload.message.id,
        );
      } else if (payload.payload.message.sendChannelPlaylist) {
        socket.transmit(
          payload.payload.message.playlistId,
          payload.payload.message,
        );
      }

      return yield cps([socket, socket.emit], event, payload);
    } catch (err) {
      if ('console' in global) {
        // eslint-disable-next-line no-console
        console.error('catched error during handleEmit', err);
      }

      // prevent memory leaks in socket clients that do not reconnect when connection is lost
      if (socket.getState() === 'closed' && socket.pendingReconnect !== true) {
        // @TODO turn into a yield put(sym('SOCKET_CLOSED'))
        if (process.env.NODE_ENV !== 'production') {
          // eslint-disable-next-line no-console
          console.error('Socket got closed before the emit was acknowledged');
        }
        return false;
      }

      const initialTimeout = Math.round(
        initialDelay + (randomness || 0) * Math.random(),
      );

      timeout = Math.round(initialTimeout * multiplier ** ++exponent);

      if (timeout > maxDelay) {
        timeout = maxDelay;
      }

      // @TODO turn into a yield put(sym('SOCKET_TIMEOUT'))
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.error(
          `Socket emit attempt #${exponent} failed, will retry in ${timeout}ms`,
        );
      }

      yield call(delay, timeout);
    }
  }
}

function* updateGoogleAccessToken(action) {
  try {
    const { access_token: accessToken } = action.payload;
    const { id: userId } = yield select(getUser);
    const fieldsUpdateObg = {};
    fieldsUpdateObg.googleAccessToken = `"${accessToken}"`;
    yield call(requestUpdateUser, {
      id: userId,
      fieldsUpdateObg,
    });
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* ShowUnread(action) {
  try {
    const awaitedNotifications = yield select(state => state.inbox.awaitedNotifications);
    const { cards } = yield select(state => state.inbox);
    const { history } = action.payload || {};

    const isUserOnInboxPage = history.location.pathname.startsWith('/inbox');
    if (isUserOnInboxPage) {
      const { idsForUpdateNotification, notExist } = findCommonNotification(awaitedNotifications, cards);
      yield put(
        actionCreator(InboxAction.UpdateNotificationNotificationAfterUpdate, { idsForUpdateNotification }),
      );
      if (Object.values(notExist).length) {
        yield put(
          actionShowMessage({
            type: MessageType.Refresh,
            text: 'Inbox has updates',
          }));
      }
    }
    const { id: idCurrentPage } = yield select(getCurrentPage);
    if (Object.values(awaitedNotifications).length) {
      yield all(Object.values(awaitedNotifications).map(i => {
        if (idCurrentPage === i.itemId) return () => {}; // For the case when the user is watching this element now
        if (i.typeUpdatedItem === 'smartfile') {
          return put(
            actionCreator(SupportAction.AddUnseenNotificationPlaylist, {
              channelsIds: i.channels,
              wrapperId: i.wrapperId,
              publishId: i.itemId,
              type: 'shared',
            }),
          );
        } if (i.typeUpdatedItem === 'page') {
          return put(actionCreator(SupportAction.AddUnseenNotificationPage, { libraryComponentId: i.itemId }));
        }
        return () => {};
      }));
      yield put(actionCreator(InboxAction.ClearAwaitedNotification));
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* bodySaga() {
  yield takeLatest('UPLOAD_DATA', uploadData);
  yield takeLatest(
    SupportAction.SendForgotPasswordRequest,
    sendForgotPasswordRequest,
  );
  yield takeLatest(SupportAction.UploadUnseenS, uploadUnseen);
  yield takeLatest(SupportAction.ShowUnread, ShowUnread);
  yield takeLatest(
    SupportAction.DeleteUnseenPlaylistInChannel,
    readUnseenPlaylistInChannel,
  );
  yield takeLatest(SupportAction.ReadUnseenPlaylist, readUnseenPlaylists);
  yield takeLatest(SupportAction.ReadOneUnseenPlaylist, readOneUnseenPlaylist);
  yield takeEvery(
    SupportAction.AddUnseenNotificationPlaylist,
    getUnseenPlaylist,
  );
  yield takeEvery(SupportAction.AddUnseenNotificationPage, getUnseenPages);
  yield takeLatest(
    SupportAction.SendResetPasswordRequest,
    sendResetPasswordRequest,
  );
  // yield takeLatest('close ch', sendResetPasswordRequest);
  yield takeLatest(ServiceUser.Login, loginUser);
  yield takeLatest(ServiceUser.GoogleLoginFlow, loginGoogle);
  yield takeLatest(ServiceUser.FacebookLoginFlow, loginFacebook);
  yield takeLatest(
    ServiceUser.UpdateGoogleAccessToken,
    updateGoogleAccessToken,
  );
  yield takeEvery(EMIT, handleEmit, socket);
}

async function socketChannelListener({ channel, dispatch, history, option }) {
  for await (const data of channel) {
    if (option.break) break;
    const userIsNotSender = data?.uniqSocketId !== sessionStorage.getItem('uniqSocketId');
    if (userIsNotSender) {
      const userId = localStorage.getItem('userId') || sessionStorage.getItem('userId');
      if (data.eventType === SOCKET_EVENT_TYPE.CHANGE_SHARE_STATE_FOR_PLAYLIST) {
        if (data.idsForUsers.includes(userId)) {
          dispatch(actionCreator(CurrentPage.updateShareStateBySocket, { shareState: data.shareState }));
        }
      }
      if (data.eventType === SOCKET_EVENT_TYPE.UPDATE_NOTIFICATION) {
        dispatch(actionCreator(InboxAction.CheckCurrentPageAddNewAwaitedNotification, { notification:
            { ...data.dataSend },
        }));
      }
      if (data.eventType === SOCKET_EVENT_TYPE.ITEM_PROCESSING_IN_AI) {
        dispatch(actionCreator(CurrentPage.aiChangeLinkPageProcessingStatus,
          { linkPageIds: [data.message.linkPageId], status: aiProcessingStatusEnum.ITEM_PROCESSING_IN_AI },
        ));
      }

      if (data.eventType === SOCKET_EVENT_TYPE.ITEM_TRANSLATED_IN_AI) {
        const parseObjText = data.message.text;
        const newText = parseObjText.simple ? generateSimpleRTBState(parseObjText.simple) : parseObjText;
        dispatch(
          actionCreator(CurrentPage.AITranslateProcessing,
            { linkPageIds: data.message.linkPageId, status: false }),
        );
        dispatch(
          actionShowMessage({
            type: MessageType.ContentTranslated,
          }),
        );
        dispatch(actionCreator(EditPlaylist.translateRTB,
          { linkPageId: data.message.linkPageId, newText },
        ));
      }

      if (data.eventType === SOCKET_EVENT_TYPE.ITEM_PROCESSED_IN_AI) {
        dispatch(
          actionCreator(CurrentPage.aiChangeLinkPageProcessingStatus,
            { linkPageIds: [data.message.linkPageId], status: aiProcessingStatusEnum.ITEM_PROCESSED_IN_AI, text: data.message?.text },
          ));
      }

      if (data.eventType === SOCKET_EVENT_TYPE.ITEM_PROCESSING_ERROR) {
        dispatch(
          actionCreator(CurrentPage.aiChangeLinkPageProcessingStatus,
            { linkPageIds: [data.message.linkPageId], status: aiProcessingStatusEnum.ITEM_PROCESSING_ERROR },
          ));
      }

      if (data.eventType === SOCKET_EVENT_TYPE.ITEM_START_IN_AI) {
        // TODO add listener
      }
      if (data.eventType === SOCKET_EVENT_TYPE.ITEM_SUMMARIZE_IN_AI) {
        const text = data?.message.text;
        dispatch(actionSaveSummarize(text));
        if (data?.message?.search?.findLinkPage?.length > 0) {
          dispatch(actionSaveSearchResultsR(
            data?.message?.search?.findLinkPage,
            data?.message?.search?.matches,
            data?.message?.search?.queryKey));
        }
        dispatch(actionAIProcessingToggleLoading(false, null));
      }
      if (data.eventType === SOCKET_EVENT_TYPE.ITEM_PROCESSING_ERROR) {
        dispatch(actionShowMessage({ type: MessageType.parseError }));
      }

      if (data.eventType === SOCKET_EVENT_TYPE.CHANGE_SHARE_STATE_FOR_PAGE) {
        if (data.idsForUsers.includes(userId)) {
          dispatch(actionCreator(CurrentPage.updateShareStateBySocketPage, { shareState: data.shareState }));
        }
      }

      // block user=> has been deactivated by admin
      if (data.eventType === SOCKET_EVENT_TYPE.BLOCK_USER) {
        if (userId === data.message) {
          dispatch(actionShowMessage({ type: MessageType.recentlyBlocked }));
          localStorage.clear();
          dispatch(actionLogout());
          history.push('/logout');
        }
      }
      if (data.eventType === SOCKET_EVENT_TYPE.CHANGE_LINK_PAGE_DURATION_ON_FLY) {
        dispatch(actionDurationByPlaybackId(data.message.duration, data.message.linkPageId));
      }
      if (data.eventType === SOCKET_EVENT_TYPE.CHANGE_LIBRARY_COMPONENT_DURATION_ON_FLY) {
        const libComponentID = data.message.lcId;
        const duration = data.message.duration;
        dispatch(actionRemoveMuxContent(libComponentID));
        dispatch(
          actionUpdateLibraryComponentInRedux({
            id: libComponentID,
            libComponentID,
            duration,
            videoProcessingStatus: 'ready',
          }),
        );
      }
      const userIsOwner = data?.owner === userId;
      // For server send event
      if (data.eventType === SOCKET_EVENT_TYPE.PUBLISH_MANY_PLAYLIST_IN_CHANNEL) {
        dispatch(
          actionCreator(ContentActionType.updateCounterInChannelS, {
            channelId: data.channelId,
          }),
        );
      }
      if (!userIsOwner) {
        if (data.eventType === SOCKET_EVENT_TYPE.PUBLISH_PLAYLIST_IN_CHANNEL) {
          dispatch(
            actionCreator(SupportAction.AddUnseenNotificationPlaylist, {
              ...data,
              type: 'publish',
            }),
          );
          dispatch(
            actionCreator(ContentActionType.updateCounterInChannelS, {
              channelId: data.channelId,
            }),
          );
          dispatch(actionCreator(InboxAction.AddInBoxCardSaga, { type: INBOX_CARD_TYPE.channelPlaylist, data }));
        }
        if (data.eventType === SOCKET_EVENT_TYPE.INVITE_TO_CHANNEL) {
          dispatch(actionCreator(InboxAction.AddInBoxCardSaga, { type: INBOX_CARD_TYPE.invite, data }));
        }
        if (data.eventType === SOCKET_EVENT_TYPE.REMOVE_PLAYLIST_IN_CHANNEL) {
          dispatch(
            actionCreator(ContentActionType.removePlaylistAfterSageR, {
              ...data,
              type: 'channel',
            }),
          );
          dispatch(
            actionCreator(ContentActionType.updateCounterInChannelS, {
              channelId: data.channelId,
            }),
          );
        }
        if (data.eventType === SOCKET_EVENT_TYPE.REMOVE_SHARED_PLAYLIST) {
          dispatch(
            actionCreator(ContentActionType.removePlaylistAfterSageR, {
              ...data,
              type: 'shared',
            }),
          );
          dispatch(
            actionCreator(ServiceUser.goToLink, {
              goToLink: '/playlists',
              paramsGoToLink: {
                isGoOut: true,
                ...data,
              },
            }),
          );
        }
        if (data.eventType === SOCKET_EVENT_TYPE.REMOVE_SHARED_PAGE) {
          dispatch(actionCreator(ContentActionType.removeSharedPage, data));
          dispatch(
            actionCreator(ServiceUser.goToLink, {
              goToLink: '/pages',
              paramsGoToLink: {
                isGoOut: true,
                ...data,
              },
            }),
          );
        }
        if (
          data.eventType === SOCKET_EVENT_TYPE.ADD_USER_TO_SHARED_IN_PLAYLIST
        ) {
          if (data.isMany) {
            data.wrappersIds.forEach((wrapperId) => {
              dispatch(
                actionCreator(SupportAction.AddUnseenNotificationPlaylist, {
                  wrapperId,
                  type: 'shared',
                }),
              );
            });
          } else {
            dispatch(
              actionCreator(SupportAction.AddUnseenNotificationPlaylist, {
                ...data,
                type: 'shared',
              }),
            );
          }
          dispatch(actionCreator(ContentActionType.updateCounterS, {
            activeNavSlider: 'smartfiles/shared',
          }));
          dispatch(actionCreator(InboxAction.AddInBoxCardSaga, { type: INBOX_CARD_TYPE.sharedPlaylist, data }));
        }
        if (data.eventType === SOCKET_EVENT_TYPE.ADD_USER_TO_SHARED_IN_PAGE) {
          if (data.isMany) {
            data.dataShared.forEach((dataShared) => {
              dispatch(
                actionCreator(SupportAction.AddUnseenNotificationPage, {
                  ...dataShared,
                  type: 'shared',
                }),
              );
            });
          } else {
            dispatch(
              actionCreator(SupportAction.AddUnseenNotificationPage, {
                ...data,
                type: 'shared',
              }),
            );
          }
          dispatch(actionCreator(InboxAction.AddInBoxCardSaga, { type: INBOX_CARD_TYPE.page, data }));
        }
      }

      if (data.dataSend?.type) {
        dispatch(actionWriteCombination('socketUpd'));
        dispatch(
          actionCreator(data.dataSend.type, {
            ...JSON.parse(sanitizeToLoad(data.dataSend.payload)),
            isSocket: true,
            isSender:
              data.sender
              === (localStorage.getItem('userId')
                || sessionStorage.getItem('userId')),
          }),
        );
      } else if (data.closePlaylist) {
        if (data.closeForUser) {
          if (data.closeForUser === localStorage.getItem('userId')) {
            dispatch(
              actionCreator(ServiceUser.goToLink, {
                goToLink: '/playlists',
              }),
            );
            dispatch(
              actionShowMessage({
                type: MessageType.RemoveAccess,
                itemName: 'playlist',
              }),
            );
          }
        } else {
          dispatch(
            actionCreator(ServiceUser.goToLink, {
              goToLink: '/playlists',
            }),
          );
          dispatch(
            actionShowMessage({
              type: MessageType.RemoveAccess,
              itemName: 'playlist',
            }),
          );
        }
      } else if (data.closePage) {
        if (data.closeForUser) {
          if (data.closeForUser === localStorage.getItem('userId')) {
            dispatch(
              actionCreator(ServiceUser.goToLink, {
                goToLink: '/pages',
              }),
            );
            dispatch(
              actionShowMessage({
                type: MessageType.RemoveAccess,
                itemName: 'page',
              }),
            );
          }
        } else {
          dispatch(
            actionCreator(ServiceUser.goToLink, {
              goToLink: '/pages',
            }),
          );
          dispatch(
            actionShowMessage({
              type: MessageType.RemoveAccess,
              itemName: 'page',
            }),
          );
        }
      }
    }
  }

  // unsubscribe function
  return () => {
    // eslint-disable-next-line no-console
    console.log('Socket off');
  };
}

export function* createSocketChannelListener(action) {
  const id = action.payload.id;
  const dispatch = action.payload.dispatch;
  const history = action.payload.history;
  const channel = socket.subscribe(id);
  const option = { break: false };
  yield fork(socketChannelListener, { channel, dispatch, history, option });
  yield take(`STOP_BACKGROUND_SYNC ${id}`);
  option.break = true;
}

function* wsSagas2() {
  yield takeEvery(SUBSCRIBE_SOCKET, createSocketChannelListener);
}

export function* rootSaga2() {
  yield fork(wsSagas2);
}

function* databaseChangesSequence() {
  const buffer = buffers.expanding();
  const requestChan = yield actionChannel(
    ServiceUser.ADD_INTO_SEQUENCE,
    buffer,
  );
  while (true) {
    const { isDatabaseSavingSequenceEmpty } = yield select(getSupport);
    if (buffer.isEmpty()) {
      yield put(
        actionCreator(ServiceUser._setDatabaseSequenceIsEmpty, { value: true }),
      );
    }
    if (!buffer.isEmpty() && isDatabaseSavingSequenceEmpty) {
      yield put(
        actionCreator(ServiceUser._setDatabaseSequenceIsEmpty, {
          value: false,
        }),
      );
    }

    const { payload } = yield take(requestChan);
    yield call(payload.logicToLaunch);
  }
}

export default function* rootSaga() {
  yield fork(bodySaga);
  yield fork(playlistSaga);
  yield fork(pageSaga);
  yield fork(librarySaga);
  yield fork(uploadSaga);
  yield fork(shortcutsSaga);
  yield fork(undoSaga);
  yield fork(downloadSaga);
  yield fork(hashtagsSaga);
  yield fork(channelSaga);
  yield fork(dynamicCollectionSaga);
  yield fork(contactsSaga);
  yield fork(dashboardSaga);
  // yield fork(viewsSaga);
  yield fork(colorsSaga);
  yield fork(settingsSagas);
  yield fork(integrationsSagas);
  yield fork(contentSagas);
  yield fork(databaseChangesSequence);
  yield fork(deletedSagas);
  yield fork(universalPlaylistViewSaga);
  yield fork(updateCounter);
  yield fork(currentPageSagas);
  yield fork(inboxSaga);
  yield fork(OutboxSaga);
  yield fork(neuralSaga);
}
