import React, { useRef, RefObject } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  PopupWrapper,
  PopupSectionTitle,
  ItemsSection, SingleItem,
  ItemName, NoMatches,
} from './ai_styled';
import { DATA_PARENTS } from '../../utils/constants';
import { actionSelectItemsInSmartfile } from '../../redux/currentPage/action';
import { aIAgents } from '../../utils/ai/helper';

interface IAIAgent {
  name: string,
  icon: React.ReactElement,
  command?: string,
}
interface IBatchActionsPopup {
  popupRef: React.MutableRefObject<HTMLDivElement | null>,
  selectedItems: { [key: string]: boolean },
  setClosed: React.Dispatch<React.SetStateAction<string | boolean>>
  selectAgent: any,
  inputValue: string,
}

const PopupSingleItem: React.FC<{
  elem: IAIAgent,
  setClosed: React.Dispatch<React.SetStateAction<string | boolean>>,
  handler: () => void,
  dispatch: any,
}> = ({ elem, setClosed, handler }) => {
  const onClickHandler = () => {
    setClosed(false);
    handler();
  };

  return (
    <SingleItem
      onClick={onClickHandler}
      data-parent={DATA_PARENTS.checkItem}
      isAI
    >
      <ItemName data-parent={DATA_PARENTS.checkItem}>
        {elem.icon}
        {elem.name}
      </ItemName>
      {elem.command}
    </SingleItem>
  )
};

export const AIAgentPopup: React.FC<IBatchActionsPopup> = ({
  popupRef,
  setClosed,
  selectAgent,
  inputValue,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const optionsObj = {
    ai: [
      {
        handler: () => selectAgent(aIAgents.pro),
        name: t(aIAgents.pro.name),
        icon: aIAgents.pro.icon,
        command: aIAgents.pro.command,
      },
      {
        handler: () => selectAgent(aIAgents.search),
        name: t(aIAgents.search.name),
        icon: aIAgents.search.icon,
        command: aIAgents.search.command,
      },
      {
        handler: () => selectAgent(aIAgents.summarize),
        name: t(aIAgents.summarize.name),
        icon: aIAgents.summarize.icon,
        command: aIAgents.summarize.command,
      },
      {
        handler: () => selectAgent(aIAgents.translate),
        name: t(aIAgents.translate.name),
        icon: aIAgents.translate.icon,
        command: aIAgents.translate.command,
      },
    ],
    // queries: [
    //   {
    //     handler: emptyCallback,
    //     name: t('Query library'),
    //     icon: <SummarizeSvg />,
    //   },
    //   {
    //     handler: emptyCallback,
    //     name: 'Recent queries',
    //     icon: <TranslateSvg />,
    //   },
    // ],
  };
  const items = optionsObj.ai.filter(elem => elem.command.includes(inputValue))
  return (
    <PopupWrapper ref={popupRef} isAgents>
      <PopupSectionTitle data-parent={DATA_PARENTS.checkItem}>
        {t('AI Agents')}
      </PopupSectionTitle>
      <ItemsSection data-parent={DATA_PARENTS.checkItem}>
        {items?.length? items.map(elem => (
          <PopupSingleItem
            elem={elem}
            setClosed={setClosed}
            handler={elem.handler}
            dispatch={dispatch}
          />
        )) : <NoMatches>No matches</NoMatches>}
      </ItemsSection>
      {/* {!inputValue && <HorizontalDivider data-parent={DATA_PARENTS.checkItem} />}
      {!inputValue && (
        <PopupSectionTitle data-parent={DATA_PARENTS.checkItem}>
          {t('Queries')}
        </PopupSectionTitle>
      )}
      <ItemsSection data-parent={DATA_PARENTS.checkItem}>
        {!inputValue && optionsObj.queries.map(elem => (
          <PopupSingleItem
            elem={elem}
            setClosed={setClosed}
            handler={elem.handler}
            dispatch={dispatch}
          />
        ))}
      </ItemsSection> */}
    </PopupWrapper>
  );
};
