import React from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  max-height: 1317px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => (props.imageSmaller ? '40px 0' : '0')};
`;

const Image = styled.img`
  max-width: 100%;
  max-height: ${(props) => (props.imageSmaller ? '100%' : '1317px;')};
  object-fit: contain;
`;

const NewImageFrame = ({ item, isImageLike }) => {
  const src = isImageLike ? item.urlSmallImage : item.urlFile;

  const imageRef = React.useRef(null);
  const [imageSmaller, setImageSmaller] = React.useState(false);
  const handleImageLoad = () => {
    const image = imageRef.current;

    if (image && image.clientHeight < 200) {
      setImageSmaller(true);
    } else {
      setImageSmaller(false);
    }
  };

  return (
    <Wrapper imageSmaller={imageSmaller}>
      <Image
        ref={imageRef}
        src={src}
        imageSmaller={imageSmaller}
        onLoad={handleImageLoad}
      />
    </Wrapper>
  );
};

export default NewImageFrame;
