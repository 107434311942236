import { put, takeLatest } from 'redux-saga/effects';
import { all, call, delay, select, takeEvery } from '@redux-saga/core/effects';
import axios from 'axios';
import {
  getCurrentPage,
  getPlaylistResolver,
  getSupport,
  getUser,
  showErrorMessage,
} from './sagasUtils';
import Playlist from '../redux/playlists/types';
import { actionSaveUniversalPlaylist } from '../redux/currentPage/action';

import {
  sanitizeToLoad,
  combineComponentsAndLibraryComponents,
} from '../utils/helpers';
import {
  APIGetPlaylistSharingInfo,
  DEFAULT_DURATION,
  DEFAULT_PAGE_PATH,
  DEFAULT_TITLE,
  DOWNLOAD_PLAYLIST_URL,
  DOWNLOAD_URL,
  isDownloadablePlaylistElement,
  MessageType,
  NOTIFICATION_DURATIONS,
  SPINNER_USAGE,
  UiComponentTypes,
} from '../utils/constants';
import { actionCreator } from '../shared/redux/actionHelper';
import SupportAction from '../redux/support/types';
import { actionClearCombinations } from '../redux/shortcuts/action';
import {
  actionShowMessage,
  actionToggleRequestSpinner,
} from '../redux/support/action';
import { LibraryComponents } from '../redux/library/types';
import { pluralize } from '../utils/dateConvert';
import {
  requestAddAllDownloadableItemsIntoPlaylist,
  requestGetLibComponent,
  requestPlaylistInfo,
  requestUpdateUserInterface,
} from '../utils/request';
import { ActionRequestProceedRemove } from '../redux/requestProceed/actions';
import { axiosAbortarium } from '../utils/axiosAbortarium';
import draftDataConvertToLoad from '../utils/draftDataConvert';
import { isRoleInPlaylist } from '../utils/permissions';
import { UnifiedHistory } from '../redux/history/types';
import { placementEnum } from '../utils/query/queryEnum';
import { parseUseInChannel } from '../utils/dataUtil';
import { actionUpdatePlaylistReadStatus } from '../redux/playlists/action';
import convertDraftStateToLexicalState from '../utils/convertDraftStateToLexicalState';
import { smartFileItemTypeCheck } from '../shared/smartFile/constant';
import i18n from '../i18n';

const { isCoEdit } = isRoleInPlaylist;

function* InitialDataLoading(action) {
  try {
    const { setIsLoaded, type } = action.payload;
    const response = yield getPlaylistResolver(action.payload);
    if (!response) return;

    const {
      data,
      playlist,
      shareState = {},
      wrapperId,
      owner = {},
      isFavorite,
      isContentEditable,
      isCanCoEdit,
      idForCoEdit,
      linkPages,
      role,
      sharedAvailableTo,
      sharedAvailableFrom,
      isFinished,
      singleUserShareState,
    } = response;

    let inputs = {};

    if (playlist) {
      const {
        duration,
        category,
        isAnyCanComment,
        isAnyCanEdit,
        audience,
        reference,
        availableFrom,
        availableTo,
        isAvailableRange,
      } = playlist;

      inputs = {
        duration,
        category,
        isAnyCanComment,
        isAnyCanEdit,
        audience,
        reference,
        availableFrom,
        availableTo,
        isAvailableRange,
      };
    }

    const img = playlist.img?.edges[0]?.node || {};
    const co_autors = {};
    let isPlaylistLocked = true;
    const filteredLinkPages = linkPages
      .filter(
        (item) => type === 'edit'
            || (type === 'shared' && isCoEdit[role])
            || (!!item.type && item.type !== 'upload'),
      )
      .map((item) => {
        co_autors[item.owner?.id] = item.owner;
        if (!item.isRemixLocked) isPlaylistLocked = false;

        let state;
        try {
          if (item.textComponent[0] && (!smartFileItemTypeCheck.isLexicalText[item?.type])) {
            state = convertDraftStateToLexicalState(draftDataConvertToLoad(item.textComponent[0]?.content));
          } else {
            state = JSON.parse(sanitizeToLoad(item.textComponent[0]?.content));
          }
        } catch (e) {
          state = '';
        }
        // TODO UPDATE
        const calcProcessingStatus = () => {
          if (item.isTextSavedToVectorDb
          || item.libraryComponent?.[0]?.hasDescriptionCreatedByAi
          || item.libraryComponent?.[0]?.parsedStatusByAi === 'DONE') {
            return 'Completed';
          }
          return null;
        };
        return {
          ...item,
          parsedStatusByAi: calcProcessingStatus(),
          title: sanitizeToLoad(item.title),
          caption: sanitizeToLoad(item.caption),
          isCollapsed: !!item.userCollapseLinkPage?.length,
          isViewed: type === 'publish'
            ? !!item.mainUserInterface?.userView?.length
            : !!item.mainUserInterface?.userView?.length,
          approveButtonState:
              item.interactiveItemData
              && JSON.parse(sanitizeToLoad(item.interactiveItemData)),
          isApprove: !!item.mainUserInterface?.userAnswer?.length,
          duration: item.duration || DEFAULT_DURATION,
          textComponent: item.textComponent[0] && {
            ...item.textComponent[0],
            state,
            id: item.textComponent[0]?.id,
            type: item.textComponent[0]?.type,
            createdByAI: item.textComponent[0]?.createdByAI,
          },
          libraryComponent: {
            ...item?.libraryComponent[0],
            linkUrl: item?.libraryComponent[0]?.linkUrl ? sanitizeToLoad(item?.libraryComponent[0].linkUrl) : '',
            components:
                combineComponentsAndLibraryComponents({
                  components: item?.libraryComponent[0]?.components,
                }) || [],
            // TODO UPDATE
            hasDescriptionCreatedByAi: item?.libraryComponent[0]?.hasDescriptionCreatedByAi || item.libraryComponent?.[0]?.parsedStatusByAi === 'DONE',
            title: sanitizeToLoad(item?.libraryComponent?.[0]?.title),
          },
        };
      })
      .sort((a, b) => a.position - b.position) || [];
    delete co_autors[owner.id];

    const totalPlaylistDuration = filteredLinkPages.reduce((acc, lp) => {
      if (!lp.type) return acc;
      if (!lp.duration) acc += 5;
      else acc += lp.duration;
      return acc;
    }, 0);
    playlist.inChannel = {};
    const inChannel = playlist?.manager2?.inChannel || playlist?.manager?.inChannel || [];
    const usersToSharing = playlist?.manager?.usersToSharing?.edges || [];

    const usedInChannels = parseUseInChannel(inChannel);

    const isPublish = !!(
      Object.keys(usedInChannels).length
    );
    const user = yield select(getUser);
    // TODO CKECK
    const hasModification = true;
    const manager = playlist.manager ?? playlist.editManager;
    const userHideApprovalButton = !!manager?.userHideApprovalButton?.length;
    const userHideDateAvatar = !!manager?.userHideDateAvatar?.length;
    yield put(
      actionSaveUniversalPlaylist({
        ...playlist,
        playerCompleted: !!playlist.manager?.readByUsers[0]?.id,
        title: sanitizeToLoad(playlist.title),
        linkPages: filteredLinkPages,
        isFavorite,
        isPublish,
        shareState,
        sharedAvailableTo,
        sharedAvailableFrom,
        hasModification,
        singleUserShareState,
        usedInChannels,
        currentRole: role,
        description: sanitizeToLoad(playlist.description),
        hasBlockedLinkPages: playlist.hasBlockedLinkPages,
        wrapperId,
        isContentEditable,
        isPlaylistLocked,
        img,
        isCanCoEdit,
        idForCoEdit,
        usersToSharing,
        co_autors: Object.values(co_autors).filter((i) => i),
        totalPlaylistDuration,
        elementsCount: playlist.linkPagesCounter,
        contentCounter: playlist.contentCounter,
        owner: {
          ...owner,
          roleInChannel: owner.channelSubscriptionConnection?.edges[0].role,
        },
        inputs,
        isFinished,
        manager:
          {
            userHideApprovalButton,
            userHideDateAvatar,
          },
      }),
    );

    if (data?.data) {
      setIsLoaded(true);
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* DownloadAllDownloadablePlaylistItems(action) {
  yield put(
    actionToggleRequestSpinner(Playlist.DownloadAllDownloadablePlaylistItems),
  );

  const { id: userId } = yield select(getUser);
  const { playlistId, playlistTitle, filesArray, isLinkAdded, smartfileLink } = action.payload;
  const token = sessionStorage.getItem('jwt') || localStorage.getItem('jwt');

  const cToken = axiosAbortarium.getToken(playlistId);
  let response;
  try {
    response = yield call(axios, {
      method: 'post',
      url: `${DOWNLOAD_PLAYLIST_URL}/${playlistId}`,
      responseType: 'blob',
      cancelToken: cToken,
      headers: {
        token: token ? `${token}` : '',
      },
      data: {
        userId,
        filesArray,
        isLinkAdded,
        smartfileLink,
      },
    });
    if (response.data.message === 'no files to download') {
      yield put(
        actionShowMessage({
          type: MessageType.PlaylistNothingToDownload,
        }),
      );

      return;
    }

    const blobUrl = URL.createObjectURL(response.data);
    const link = document.createElement('a');

    link.href = blobUrl;
    link.setAttribute(
      'download',
      `${
        sanitizeToLoad(playlistTitle || i18n.t(DEFAULT_TITLE.Playlist)).replace(/\./g, '')
        || 'quidzi_download'
      }_elements`,
    );
    document.body.appendChild(link);
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      }),
    );
    link.parentNode.removeChild(link);
    yield put(actionClearCombinations());
  } catch (err) {
    if (!axios.isCancel(err)) {
      yield showErrorMessage(err, action);
    } else {
      axiosAbortarium.clearUp(playlistId);
    }
  }
  yield put(actionToggleRequestSpinner(false));
  yield put(ActionRequestProceedRemove(playlistId));
}

function* DownloadOneElement(action) {
  const { elementId, elementTitle, elementType, isText, text, owner } = action.payload;
  const token = sessionStorage.getItem('jwt') || localStorage.getItem('jwt');
  let response;
  let blobUrl;
  const cToken = axiosAbortarium.getToken(elementId);
  try {
    if (UiComponentTypes.media[elementType]) {
      yield put(actionToggleRequestSpinner(elementId));
      const { data } = yield requestGetLibComponent(['any', elementId]);
      const [component] = data.LibraryComponent;
      const {
        data: { downloadUrl },
      } = yield call(axios, {
        method: 'get',
        url: `${DOWNLOAD_URL}/mux/${component.assetID}`,
        cancelToken: cToken,
        headers: {
          token: token ? `${token}` : '',
        },
      });
      blobUrl = downloadUrl;
      yield put(actionToggleRequestSpinner(false));
    } else {
      if (!isDownloadablePlaylistElement[elementType] && !isText) {
        yield put(
          actionShowMessage({
            type: MessageType.PlaylistNothingToDownload,
          }),
        );

        return;
      }

      response = yield call(axios, {
        method: 'get',
        url: `${DOWNLOAD_URL}/${elementId}/${owner?.id || 'undefined'}`,
        responseType: 'blob',
        cancelToken: cToken,
        headers: {
          token: token ? `${token}` : '',
        },
      });

      blobUrl = URL.createObjectURL(response.data);
    }

    const link = document.createElement('a');

    link.href = blobUrl;
    const calcName = isText
      ? response.headers?.filename || text.slice(0, 15)
      : sanitizeToLoad(elementTitle || i18n.t(DEFAULT_TITLE.PlaylistElement));

    link.setAttribute('download', calcName.replace(/\./g, ''));
    document.body.appendChild(link);
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      }),
    );
    link.parentNode.removeChild(link);
    yield put(actionClearCombinations());
    yield put(ActionRequestProceedRemove(elementId));
  } catch (err) {
    if (!axios.isCancel(err)) {
      yield showErrorMessage(err, action);
    } else {
      axiosAbortarium.clearUp(elementId);
      yield put(ActionRequestProceedRemove(elementId));
    }
  }
}

function* AddAllDownloadableItemsIntoLibrary(action) {
  try {
    const { linkPages } = yield select(getCurrentPage);
    const { id: userId } = yield select(getUser);
    let items = [];
    if (action.payload.items) {
      items = linkPages.filter(
        (item) => (!item.isRemixLocked || item.owner?.id === userId)
          && item?.libraryComponent?.id && action.payload.items.includes(item.id),
      );
    } else {
      items = linkPages.filter(
        (item) => !item.isRemixLocked && item?.libraryComponent?.id,
      );
    }

    yield all(
      items.map((item) => put(
        actionCreator(LibraryComponents.Player.DuplicateSharedComponent, {
          componentId: item?.libraryComponent?.id,
          linkPageId: item?.id,
          type: item?.libraryComponent?.type,
          isNoNotifications: true,
        }),
      ),
      ),
    );

    yield put(
      actionShowMessage({
        type: MessageType.AddToMyStudio,
        itemName: `${items.length} ${pluralize('element', items.length)} ${
          items.length === 1 ? 'was' : 'were'
        }`,
        itemType: 'element',
      }),
    );
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* UpdateReadStateLinkPage(action) {
  try {
    const { isViewed, id } = action.payload;
    const { id: userId } = yield select(getUser);
    if (!id || !userId) return;
    const connectDisconnectProperty = isViewed ? 'disconnect' : 'connect';
    yield call(requestUpdateUserInterface(), {
      id,
      [connectDisconnectProperty]: {
        userView: [userId],
      },
    });
    const { isFinished, wrapperId } = yield select(getCurrentPage);
    if (isFinished) {
      yield put(actionUpdatePlaylistReadStatus(isFinished, wrapperId));
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* UpdateApproveStateLinkPage(action) {
  try {
    const { isApprove, mainUserInterface } = action.payload;
    const { id: userId } = yield select(getUser);
    if (!mainUserInterface?.id) return;
    if (!userId) return;

    if (isApprove) {
      yield call(requestUpdateUserInterface(placementEnum.queryAddUserAnswer), {
        linkPageInterfaceId: mainUserInterface.id,
        userId,
      });
    } else {
      yield call(requestUpdateUserInterface(), {
        id: mainUserInterface.id,
        disconnect: {
          userAnswer: [userId],
        },
      });
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* AddAllDownloadableItemsIntoPlaylist(action) {
  const { fromPlaylist, toPlaylist, history, title, selectedItems } = action.payload;
  const { requestSpinner } = yield select(getSupport);

  try {
    if (requestSpinner !== SPINNER_USAGE.PageCreateNewPlaylist) {
      yield put(actionToggleRequestSpinner(SPINNER_USAGE.AddPageToPlaylist));
    }

    yield call(
      requestAddAllDownloadableItemsIntoPlaylist(fromPlaylist, toPlaylist), { selectedItems });

    if (requestSpinner !== SPINNER_USAGE.PageCreateNewPlaylist) {
      yield put(actionToggleRequestSpinner(null));
    }

    let stopRedirect = false;
    yield put(actionCreator(SupportAction.ShowContentLoader, { state: true }));
    yield put(
      actionShowMessage({
        type: MessageType.PageAddedToPlaylist,
        callback: () => {
          stopRedirect = true;
        },
        playlistTitle: sanitizeToLoad(title) || i18n.t(DEFAULT_TITLE.Playlist),
      }),
    );

    yield delay(NOTIFICATION_DURATIONS.Info);
    yield put(actionCreator(SupportAction.ShowContentLoader, { state: false }));

    const stackStep = { step: {} };

    stackStep.step.id = fromPlaylist;
    stackStep.step.from = '/library';
    stackStep.departureUrl = history.location.pathname;

    if (!stopRedirect) {
      // ??
      yield put(actionCreator(UnifiedHistory.AddPayload, stackStep));
      history.push(`/maker/${toPlaylist}/edit`);
    }
    // TBD
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}
function* UploadPlaylistInfo(action) {
  if (action.payload.type === 'shared') {
    const token = sessionStorage.getItem('jwt') || localStorage.getItem('jwt');
    const { id, accessCode } = action.payload;
    let mainInfo;
    try {
      const { data } = yield call(axios, {
        method: 'get',
        url: `${APIGetPlaylistSharingInfo}/${id}`,
        headers: {
          accessCode: accessCode ? `${accessCode}` : '',
          token: token ? `${token}` : '',
        },
      });
      mainInfo = data;
    } catch (e) {
      return;
    }

    yield put(
      actionSaveUniversalPlaylist({
        title: sanitizeToLoad(mainInfo.playlist.title),
        cropUrl: mainInfo.playlist.cropUrl,
        defaultPreviewColor: mainInfo.playlist.defaultPreviewColor,
        description: sanitizeToLoad(mainInfo.playlist.description),
        owner: mainInfo.owner,
      }),
    );
    return;
  }
  let mainInfo;
  try {
    const { data } = yield call(requestPlaylistInfo(action.payload.id), [], {
      channelID: action.payload.channelID,
      isViewAsCoEdit: action.payload.isViewAsCoEdit,
    });
    mainInfo = data;
  } catch (e) {
    if (e.response?.status === 403) {
      const history = action.payload.history;
      const isHasBack = history.action !== 'POP';
      const message = 'This playlist is no longer shared !';
      if (isHasBack) {
        history.goBack();
      } else {
        history.push(DEFAULT_PAGE_PATH);
      }
      yield put(actionShowMessage({
        type: MessageType.ErrorRegular,
        text: message,
      }));
      return;
    }
    throw e;
  }

  yield put(
    actionSaveUniversalPlaylist({
      title: sanitizeToLoad(mainInfo.data.EditPlaylist[0].title),
      cropUrl: mainInfo.data.EditPlaylist[0].cropUrl,
      defaultPreviewColor: mainInfo.data.EditPlaylist[0].defaultPreviewColor,
      currentRole: mainInfo.role,
      description: sanitizeToLoad(mainInfo.description),
      owner: {
        ...mainInfo.data.EditPlaylist[0].manager.users[0],
      },
    }),
  );
}

function* UploadPlaylist(action) {
  try {
    yield all([UploadPlaylistInfo(action), InitialDataLoading(action)]);
    yield put({ type: 'PAGE_IS_DOWNLOAD', payload: { isDownload: false } });
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

export default function* universalPlaylistViewSaga() {
  yield takeLatest(Playlist.UpdateReadStateLinkPage, UpdateReadStateLinkPage);
  yield takeLatest(
    Playlist.UpdateApproveStateLinkPage,
    UpdateApproveStateLinkPage,
  );
  yield takeLatest(Playlist.GetUniversalViewData, UploadPlaylist);
  yield takeLatest(
    Playlist.DownloadAllDownloadablePlaylistItems,
    DownloadAllDownloadablePlaylistItems,
  );
  yield takeEvery(Playlist.DownloadOneElement, DownloadOneElement);
  yield takeEvery(
    Playlist.AddAllDownloadableItemsIntoLibrary,
    AddAllDownloadableItemsIntoLibrary,
  );
  yield takeEvery(
    Playlist.AddAllDownloadableItemsIntoPlaylist,
    AddAllDownloadableItemsIntoPlaylist,
  );
}
