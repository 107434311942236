import React, { useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import rawStyles from '../elements.module.scss';
import PlaylistPhotoFrame from './PlaylistPhotoFrame';
import PlaylistElementCaption from './PlaylistElementCaption';
import useComponentVisible from '../../../../utils/hooks/useComponentVisible';
import { ReactComponent as LockSvg } from '../../../../images/icons/batchActions/lock.svg';

import ThreeDotsDropDownMaker from '../../ThreeDotsDropDownForMaker';
import PlaylistTitleInput from '../../MakerCommon/PlaylistTitleInput';
import {
  BothPartsWrapper,
  BottomInfoPartWrapper,
  ElementBase,
  ElementShell,
  MainInfo,
  VerticalPartsDivider,
  LockWrapper,
} from './styled';
import { calcAIProcessingStatus, universalPlaylistSize, calcHasIndention } from '../../../../utils/helpers';
import { empty } from '../../../../utils/constants';
import { actionCreator } from '../../../../shared/redux/actionHelper';
import { CurrentPage } from '../../../../redux/currentPage/types';
import { UsedByAIIndicatorComp } from '../../../../components/UsedByAIIndicator/UsedByAIIndicator';

const cn = classNames.bind(rawStyles);

const LibraryPlaylistElement = ({
  imageComponent,
  title,
  typeIcon,
  linkPage,
  isCompressView,
  ...props
}) => {
  const previewRef = useRef(null);
  const dispatch = useDispatch();

  const tierLimits = useSelector((state) => state.user.tierLimits) || empty;
  const { t } = useTranslation();
  const [threeDotRef, isThreeDotsActive, setIsThreeDotsActive] = useComponentVisible(false, 'miniPage');
  const currentPage = useSelector((state) => state.currentPage);
  const { id: userId } = useSelector((state) => state.user);
  const { isContentEditable, socketId } = currentPage;
  const isOwner = userId === linkPage?.owner?.id;

  const calculatedSizeMB = useMemo(() => universalPlaylistSize(linkPage, false), [linkPage]);

  const hasIndention = useMemo(() => calcHasIndention(currentPage.linkPages, props.itemIndex),
    [currentPage.linkPages, props.itemIndex]);

  const openFullScreenViewHandler = () => {
    const position = previewRef.current.getBoundingClientRect();
    props.goToFullScreenView(props.linkPageId, position);
  };
  const toggleShowThreeDots = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsThreeDotsActive(!isThreeDotsActive);
  };
  const aiTaskHandler = (e) => {
    e.stopPropagation();
    if (!tierLimits?.allowedAI) return;
    dispatch(actionCreator(CurrentPage.sendToAI, { linkPages: [linkPage.id] }));
  };

  return (
    <ElementShell
      onContextMenu={toggleShowThreeDots}
      onClick={openFullScreenViewHandler}
      hasIndention={hasIndention}
    >
      <UsedByAIIndicatorComp
        hasIndention={hasIndention}
        parsedStatusByAi={linkPage.libraryComponent?.parsedStatusByAi}
        linkPage={linkPage}
      />
      {linkPage.isRemixLocked && (
        <LockWrapper hasIndention={hasIndention}>
          <LockSvg />
        </LockWrapper>
      )}
      <ElementBase
        isCompressView={isCompressView}
        className={`${cn('selected',
          { blink_it: props.isBlink, is_active: currentPage.selectedItems[linkPage.id] })} highlightedPlace`}
        isLocked={props.isRemixLocked}
      >
        <MainInfo>
          <PlaylistPhotoFrame previewRef={previewRef} width={isCompressView ? 38 : 100} height={isCompressView ? 38 : 100}>
            {imageComponent}
          </PlaylistPhotoFrame>
          <BothPartsWrapper>
            <PlaylistTitleInput
              title={title}
              isCompressView={isCompressView}
              calculatedSizeMB={calculatedSizeMB}
              renameRef={props.renameRef}
              linkPageId={props.linkPageId}
              mode="textarea"
              setRenameMode={props.setRenameMode}
              renameMode={props.renameMode}
              isDisabled={!isOwner}
            />
            {isThreeDotsActive && (
            <ThreeDotsDropDownMaker
              playlistType={props.playlistType}
              show={isThreeDotsActive}
              itemRef={previewRef}
              refMenu={threeDotRef}
              isActive={isThreeDotsActive}
              setIsActive={setIsThreeDotsActive}
              playlistId={currentPage.id}
              isContentEditable={isContentEditable}
              socketId={socketId}
              isOwner={isOwner}
              item={linkPage}
              currentPage={currentPage}
              itemIndex={props.itemIndex}
              currentRole={props.currentRole}
              upvCard
              isCardView
            />
            )}
            {!isCompressView && <VerticalPartsDivider />}
            {!isCompressView && (
              <BottomInfoPartWrapper>
                <div>{calculatedSizeMB}</div>
              </BottomInfoPartWrapper>
            )}
          </BothPartsWrapper>
        </MainInfo>
      </ElementBase>
      <PlaylistElementCaption
        caption={props.caption}
        linkPageId={props.linkPageId}
        isShowCaption={props.isShowCaption}
        isNew={linkPage.isNew}
      />
    </ElementShell>
  );
};

export default LibraryPlaylistElement;
