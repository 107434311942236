import { put } from 'redux-saga/effects';
import { select, takeEvery } from '@redux-saga/core/effects';
import { v4 as uuid } from 'uuid';
import { shortcut } from '../redux/shortcuts/types';
import { getCurrentPage, showErrorMessage } from './sagasUtils';
import {
  decrementHistoryStep,
  historyEvents,
  historyStep,
  incrementHistoryStep,
} from '../undo';
import { actionShowMessage } from '../redux/support/action';
import EditPlaylist from '../redux/playlists/types';
import {
  actionAddImagePlaylist,
  actionChangeTextElementBlockRedux,
  actionChangeTextElementBlockReduxMaker, actionEditDescriptionPlaylistR,
  actionEditDescriptionPlaylistS,
  actionEditTitlePlaylist,
  actionPlaylistMoveToTrash,
  actionPlaylistReMoveToTrash,
  actionPlaylistToggleFavorite,
  actionToggleIsShowDescriptionPlaylist,
} from '../redux/playlists/action';
import {
  actionChangeStateButton,
  actionClearLinkPageType,
  actionCreateLibLinkPagePlaylist,
  actionCurrentEditTitle,
  actionEmptyAddLinkPageToPlaylist,
  actionMovePageWithinPlaylist,
  actionRemoveLinkPageFromPlaylist,
  actionSaveInputPlaylist,
} from '../redux/currentPage/action';
import { MessageType } from '../utils/constants';
import { actionTogglePublishMiniPlaylist } from '../redux/miniPlaylists/action';
import { LibraryComponents } from '../redux/library/types';
import {
  actionRestoreComponentFromTrash,
  actionToggleComponentFavorite,
  actionUpdateLibraryComponent,
  actionUpdateLibraryPageDescriptionShow,
  actionUpdateLibraryPageInLibraryField,
} from '../redux/library/actions';
import { CurrentPage } from '../redux/currentPage/types';
import {
  actionDeleteEditableBlock,
  actionDeleteManyBlocks,
  actionDragManyBlock,
} from '../redux/pages/action';
import { EditPage } from '../redux/pages/types';
import { ServiceUser } from '../redux/user/types';

import {
  HandleDropboxFileLinkS_REDO,
  HandleDropboxFileLinkS_UNDO,
  HandleDropboxPickedFiles_REDO,
  HandleDropboxPickedFiles_UNDO,
  handleDropboxPrevData,
} from './undoHelpers';
import i18n from '../i18n';
import { smartFileItemType } from '../shared/smartFile/constant';

const actionCreator = (type, value) => ({ type, payload: { ...value } });

export function* notUndoActionUpdate(action) {
  return yield put({ ...action, metod: 'UNDO' });
}

function* NotEditable(id) {
  yield put(
    actionShowMessage({
      type: MessageType.NotEditableNotification,
      place: 'Channel',
      callback: () => actionTogglePublishMiniPlaylist(id, false),
    }),
  );
}

const SKIP = 'SKIP';

function* elegantRedo() {
  yield notUndoActionUpdate(historyEvents[historyStep]);
  incrementHistoryStep();
}

function* playlistRedo() {
  yield notUndoActionUpdate(historyEvents[historyStep]);
  incrementHistoryStep();
}

const empty = () => {};

function* clearStepAndGoToNext() {
  historyEvents.splice(historyStep - 1, 1);
  decrementHistoryStep();
  yield undoActionHandler[historyEvents[historyStep - 1]?.type]?.UNDO();
}

const Page = {
  [LibraryComponents.Pages.updateLibraryPageDescription]: {
    getPrevData: (action, state) => {
      return state.currentPage[action.payload.libraryPage.field];
    },
    UNDO: function* UNDO() {
      const { libraryPage } = historyEvents[historyStep - 1].payload;
      if (libraryPage.field === 'isShowDescription') {
        yield notUndoActionUpdate(
          actionUpdateLibraryPageDescriptionShow({
            ...libraryPage,
            value: !libraryPage.value,
          }),
        );
      }
      if (libraryPage.field === 'description') {
        const prevData = historyEvents[historyStep - 1].prevData;

        yield notUndoActionUpdate(
          actionUpdateLibraryPageDescriptionShow({
            ...libraryPage,
            value: prevData,
          }),
        );
      }
      decrementHistoryStep();
    },
    REDO: elegantRedo,
  },
  [CurrentPage.ChangeStateComponentButton]: {
    getPrevData: (action, state) => {
      return state.currentPage.blocks.find(
        (i) => i.id === action.payload.buttonState.id,
      );
    },
    UNDO: function* UNDO() {
      // const payload = historyEvents[historyStep - 1].payload;

      const prevData = historyEvents[historyStep - 1].prevData;

      yield notUndoActionUpdate(actionChangeStateButton(prevData));

      decrementHistoryStep();
    },
    REDO: elegantRedo,
  },
  [LibraryComponents.CopyBlocksToPage]: {
    getPrevData: empty,
    UNDO: function* UNDO() {
      const { componentsIds } = historyEvents[historyStep - 1].payload;
      const id = Object.values(componentsIds)[0];
      yield notUndoActionUpdate(actionDeleteEditableBlock(id));

      decrementHistoryStep();
    },
    REDO: elegantRedo,
  },
  [EditPage.DragManyBlock]: {
    getPrevData: (action, state) => {
      const idLastItem = action.payload.draggableBlocks[
        action.payload.draggableBlocks.length - 1
      ];
      const index = state.currentPage.blocks.findIndex(
        (i) => i.id === idLastItem,
      );
      if (index + 1 === state.currentPage.blocks.length) {
        return { oldBlockPosition: 'last' };
      }
      return { oldBlockPosition: state.currentPage.blocks[index + 1].id };
    },
    UNDO: function* UNDO() {
      const payload = historyEvents[historyStep - 1].payload;
      const { oldBlockPosition } = historyEvents[historyStep - 1].prevData;

      yield notUndoActionUpdate(
        actionDragManyBlock(oldBlockPosition, payload.draggableBlocks),
      );
      decrementHistoryStep();
    },
    REDO: elegantRedo,
  },

  [LibraryComponents.Pages.UpdatePageInLibraryField]: {
    getPrevData: (action, state) => {
      const { field, id } = action.payload.page;

      if (field !== 'title') return {};
      return {
        oldTitle:
          state.content.contentData[id] && state.content.contentData[id][field],
      };
    },
    UNDO: function* UNDO() {
      const payload = historyEvents[historyStep - 1].payload;
      const { oldTitle } = historyEvents[historyStep - 1].prevData;
      yield notUndoActionUpdate(
        actionUpdateLibraryPageInLibraryField({
          ...payload.page,
          value: oldTitle,
        }),
      );
      decrementHistoryStep();
    },
    REDO: elegantRedo,
  },

  [EditPage.ChangeTypeEditableBlock]: {
    getPrevData: (action, state) => {
      const { id } = action.payload;
      const block = state.currentPage.blocks.find((i) => i.id === id);
      return { oldType: block?.type };
    },
    UNDO: function* UNDO() {
      const payload = historyEvents[historyStep - 1].payload;
      const { oldType } = historyEvents[historyStep - 1].prevData;
      yield notUndoActionUpdate(
        actionCreator(EditPage.ChangeTypeEditableBlock, {
          ...payload,
          type: oldType,
        }),
      );
      decrementHistoryStep();
    },
    REDO: elegantRedo,
  },

  [CurrentPage.ToggleComponentIsHidden]: {
    getPrevData: (action, state) => {
      const { id } = action.payload;
      const block = state.currentPage.blocks.find((i) => i.id === id);
      return { oldType: block?.type };
    },
    UNDO: function* UNDO() {
      const payload = historyEvents[historyStep - 1].payload;
      yield notUndoActionUpdate(
        actionCreator(CurrentPage.ToggleComponentIsHidden, {
          ...payload,
          value: !payload.value,
        }),
      );
      decrementHistoryStep();
    },
    REDO: elegantRedo,
  },
  [EditPage.addDuplicateBlock]: {
    getPrevData: empty,
    UNDO: function* UNDO() {
      const payload = historyEvents[historyStep - 1].payload;
      yield notUndoActionUpdate(actionDeleteEditableBlock(payload.newBlockId));
      decrementHistoryStep();
    },
    REDO: elegantRedo,
  },
  [EditPage.multipleDuplicateBlock]: {
    getPrevData: empty,
    UNDO: function* UNDO() {
      const payload = historyEvents[historyStep - 1].payload;
      yield notUndoActionUpdate(actionDeleteManyBlocks(payload.newBlockIds));
      decrementHistoryStep();
    },
    REDO: elegantRedo,
  },

  [EditPage.ResizeImageInBlock]: {
    getPrevData: (action, state) => {
      const { id } = action.payload;
      const oldBlock = state.currentPage.blocks.find((i) => i.id === id);
      return { oldBlock };
    },
    UNDO: function* UNDO() {
      const { oldBlock } = historyEvents[historyStep - 1].prevData;
      yield notUndoActionUpdate(
        actionCreator(EditPage.ResizeImageInBlock, { ...oldBlock }),
      );
      decrementHistoryStep();
    },
    REDO: elegantRedo,
  },
  [EditPage.AddNewEmptyEditableBlock]: {
    getPrevData: empty,
    UNDO: function* UNDO() {
      const { newId } = historyEvents[historyStep - 1].payload;
      yield notUndoActionUpdate(actionDeleteEditableBlock(newId));
      decrementHistoryStep();
    },
    REDO: elegantRedo,
  },
  [LibraryComponents.UpdatePdfWidth]: {
    getPrevData: (action, state) => {
      const { relationId } = action.payload;
      const index = state.currentPage.blocks.findIndex(
        (item) => item.id === relationId,
      );
      const currentBlock = state.currentPage.blocks[index];
      const width = currentBlock.width;
      return { width };
    },

    UNDO: function* UNDO() {
      const { relationId, nestedItemId, newInnerHtml, currentPageId } = historyEvents[historyStep - 1].payload;
      const { width } = historyEvents[historyStep - 1].prevData;
      yield notUndoActionUpdate(
        actionCreator(LibraryComponents.UpdatePdfWidth, {
          relationId,
          width,
          nestedItemId,
          newInnerHtml,
          currentPageId,
        }),
      );
      decrementHistoryStep();
    },
    REDO: elegantRedo,
  },
  [LibraryComponents.UpdatePdfPreview]: {
    getPrevData: (action, state) => {
      const { relationId } = action.payload;
      const index = state.currentPage.blocks.findIndex(
        (item) => item.id === relationId,
      );
      const currentBlock = state.currentPage.blocks[index];
      const content = currentBlock.representationState;
      return { content };
    },

    UNDO: function* UNDO() {
      const { relationId, currentPageId } = historyEvents[historyStep - 1].payload;
      const { content } = historyEvents[historyStep - 1].prevData;
      yield notUndoActionUpdate(
        actionCreator(LibraryComponents.UpdatePdfPreview, {
          relationId,
          isNew: content === 'attachment',
          newState: content,
          currentPageId,
        }),
      );
      decrementHistoryStep();
    },
    REDO: elegantRedo,
  },
  [EditPage.DeleteEditableBlock]: {
    getPrevData: (action, state) => {
      const { id } = action.payload;
      const index = state.currentPage.blocks.findIndex(
        (item) => item.id === id,
      );
      const currentBlock = state.currentPage.blocks[index];
      return { currentBlock, index };
    },

    UNDO: function* UNDO() {
      const { currentBlock, index } = historyEvents[historyStep - 1].prevData;
      yield notUndoActionUpdate(
        actionCreator(EditPage.RestoreAfterDeleteEditableBlock, {
          currentBlock,
          index,
        }),
      );
      decrementHistoryStep();
    },
    REDO: elegantRedo,
  },

  [EditPage.DeleteManyBlocks]: {
    getPrevData: (action, state) => {
      const { ids } = action.payload;
      const deleteState = state.currentPage.blocks.filter((item) => ids.includes(item.id),
      );
      return { deleteState };
    },

    UNDO: function* UNDO() {
      const { deleteState } = historyEvents[historyStep - 1].prevData;
      yield notUndoActionUpdate(
        actionCreator(EditPage.RestoreAfterDeleteManyEditableBlock, {
          deleteState,
        }),
      );
      decrementHistoryStep();
    },
    REDO: elegantRedo,
  },
  [LibraryComponents.CreateLibraryComponentFromBlocks]: {
    getPrevData: (action, state) => {
      const { selectedBlocks } = action.payload;
      const indexs = [selectedBlocks.length];
      const deleteState = state.currentPage.blocks
        .filter((item) => selectedBlocks.includes(item.id))
        .map((i) => ({ ...i, id: uuid() }));
      selectedBlocks.forEach((item) => indexs.push(
        state.currentPage.blocks.findIndex((block) => block.id === item),
      ),
      );
      const index = Math.min(...indexs);
      return { deleteState, index };
    },
    UNDO: function* UNDO() {
      const { deleteState } = historyEvents[historyStep - 1].prevData;
      const { blockId } = historyEvents[historyStep - 1].payload;
      yield notUndoActionUpdate(
        actionCreator(EditPage.RestoreAfterDeleteManyEditableBlock, {
          deleteState,
        }),
      );
      yield notUndoActionUpdate(
        actionCreator(EditPage.DeleteEditableBlock, {
          id: blockId,
        }),
      );
      decrementHistoryStep();
    },
    REDO: function* REDO() {
      const { index, deleteState } = historyEvents[historyStep].prevData;
      const { currentPageId, newLibraryComponentId } = historyEvents[historyStep].payload;
      const blockId = uuid();
      yield notUndoActionUpdate(
        actionCreator(EditPage.DeleteManyBlocks, {
          ids: deleteState.map((i) => i.id),
        }),
      );
      const libraryDragStateWithIdForNewBlock = {
        [newLibraryComponentId]: blockId,
      };
      historyEvents[historyStep].payload.blockId = blockId;
      yield notUndoActionUpdate(
        actionCreator(LibraryComponents.CopyBlocksToPage, {
          componentsIds: libraryDragStateWithIdForNewBlock,
          pageId: currentPageId,
          index,
        }),
      );
      incrementHistoryStep();
    },
  },
  [LibraryComponents.DetachComponent]: {
    getPrevData: (action, state) => {
      // see sagas

      const { detachComponentId } = action.payload;
      // const indexs = [selectedBlocks.length];
      const deleteState = state.currentPage.blocks
        .filter((item) => detachComponentId.includes(item.id))
        .map((i) => ({ ...i, id: uuid() }));
      // selectedBlocks.forEach(item => indexs.push(state.currentPage.blocks.findIndex((block) => block.id === item)));
      // const index = Math.min(...indexs);
      return { deleteState };
    },
    UNDO: function* UNDO() {
      const { idsBlock, idsLibComp } = historyEvents[historyStep - 1].prevData;
      const { index } = historyEvents[historyStep - 1].payload;
      const libraryDragStateWithIdForNewBlock = {};
      idsLibComp.forEach(
        (i) => (libraryDragStateWithIdForNewBlock[i] = uuid()),
      );
      historyEvents[
        historyStep - 1
      ].prevData.libraryDragStateWithIdForNewBlock = libraryDragStateWithIdForNewBlock;

      yield notUndoActionUpdate(
        actionCreator(EditPage.DeleteManyBlocks, {
          ids: idsBlock,
        }),
      );
      yield notUndoActionUpdate(
        actionCreator(LibraryComponents.CopyBlocksToPage, {
          componentsIds: libraryDragStateWithIdForNewBlock,
          index,
        }),
      );
      decrementHistoryStep();
    },
    REDO: function* REDO() {
      const { libraryDragStateWithIdForNewBlock } = historyEvents[historyStep].prevData;
      yield notUndoActionUpdate(
        actionCreator(LibraryComponents.DetachComponent, {
          ...historyEvents[historyStep].payload,
          detachComponentId: Object.values(libraryDragStateWithIdForNewBlock),
        }),
      );
      incrementHistoryStep();
    },
  },
  [EditPage.AddManyEditableBlock]: {
    getPrevData: (action, state) => {
      // see sagas
      const { blocks } = state.currentPage;

      const index = blocks.findIndex((block) => block.id === action.payload.id);

      return { index };
    },
    UNDO: function* UNDO() {
      const { blocks } = historyEvents[historyStep - 1].payload;

      yield notUndoActionUpdate(
        actionCreator(EditPage.DeleteManyBlocks, {
          ids: blocks.map((i) => i.id),
        }),
      );

      decrementHistoryStep();
    },
    REDO: function* REDO() {
      const { payload } = historyEvents[historyStep];
      const { index } = historyEvents[historyStep].prevData;

      if (payload.blocks.filter((i) => !i.libCompId).length) {
        yield notUndoActionUpdate(
          actionCreator(EditPage.AddManyEditableBlock, {
            ...payload,
            blocks: payload.blocks.filter((i) => !i.libCompId),
          }),
        );
      }
      if (payload.blocks.filter((i) => !!i.libCompId).length) {
        const componentsIds = {};

        payload.blocks
          .filter((i) => i.libCompId)
          .forEach((i) => (componentsIds[i.libCompId] = i.id));
        yield notUndoActionUpdate(
          actionCreator(LibraryComponents.CopyBlocksToPage, {
            componentsIds,
            index,
          }),
        );
      }
      incrementHistoryStep();
    },
  },

  [EditPage.ChangeEditableBlock]: {
    getPrevData: (action, state) => {
      const { blocks } = state.currentPage;
      const oldBlock = blocks.find((block) => block.id === action.payload.id);
      return { oldBlock };
    },
    UNDO: function* UNDO() {
      const { oldBlock } = historyEvents[historyStep - 1].prevData;
      yield notUndoActionUpdate(
        actionCreator(EditPage.ChangeEditableBlock, {
          id: oldBlock.id,
          newState: oldBlock.state,
          innerHtml: oldBlock.innerHtml,
        }),
      );

      decrementHistoryStep();
    },
    REDO: elegantRedo,
  },
  [EditPage.SeparateBlock]: {
    getPrevData: (action, state) => {
      // const { id } = action.payload;
      const { blocks } = state.currentPage;
      const oldBlock = blocks.find((block) => block.id === action.payload.id);
      return { oldBlock };
    },
    UNDO: function* UNDO() {
      const { oldBlock } = historyEvents[historyStep - 1].prevData;
      const { newBlockId } = historyEvents[historyStep - 1].payload;
      yield notUndoActionUpdate(
        actionCreator(EditPage.ChangeEditableBlock, {
          id: oldBlock.id,
          newState: oldBlock.state,
          innerHtml: oldBlock.innerHtml,
        }),
      );
      yield notUndoActionUpdate(
        actionCreator(EditPage.DeleteEditableBlock, {
          id: newBlockId,
        }),
      );
      decrementHistoryStep();
    },
    REDO: elegantRedo,
  },

  [LibraryComponents.ChangeLibraryComponentInLibraryFromBlocks]: {
    getPrevData: (action, state) => {
      const { componentId } = action.payload;
      const components = state.content.contentData;
      const blockInPage = state.currentPage.blocks;
      const isComponentInCurrentPageBlock = !!blockInPage.find(
        (block) => block.nestedItemId === componentId,
      );

      return {
        oldState: components[componentId],
        isComponentInCurrentPageBlock,
      };
    },
    UNDO: function* UNDO() {
      const components = yield select((state) => state.content.contentData);
      const { componentId } = historyEvents[historyStep - 1].payload;
      const { oldState, isComponentInCurrentPageBlock } = historyEvents[historyStep - 1].prevData;
      if (!isComponentInCurrentPageBlock) {
        yield clearStepAndGoToNext();
        return;
      }

      historyEvents[historyStep - 1].prevData.reduState = components[componentId];
      yield notUndoActionUpdate(
        actionCreator(
          LibraryComponents.RevertLibraryComponentInLibraryFromBlocks,
          {
            ...historyEvents[historyStep - 1].payload,
            oldState,
          },
        ),
      );

      decrementHistoryStep();
    },
    REDO: function* REDU() {
      const { reduState } = historyEvents[historyStep].prevData;

      yield notUndoActionUpdate(
        actionCreator(
          LibraryComponents.RevertLibraryComponentInLibraryFromBlocks,
          {
            ...historyEvents[historyStep].payload,
            oldState: reduState,
          },
        ),
      );

      incrementHistoryStep();
    },
  },

  // [FileUpload.CreateUpload]: {
  //   getPrevData: (action, state) => {
  //     const { blockId } = action.payload;
  //     const block = state.currentPage.blocks.filter(item => blockId === item.id);
  //
  //     return { block };
  //   },
  //
  //   UNDO: function* UNDO() {
  //     // FileUpload.Cancel
  //     const { newId } = historyEvents[historyStep - 1].payload;
  //     const { block } = historyEvents[historyStep - 1].prevData;
  //     const { components } = yield select((state) => state.uploads);
  //     if (components[newId]) {
  //       yield notUndoActionUpdate(actionCreator(FileUpload.Cancel, {
  //         id: newId,
  //       }));
  //     } else {
  //       yield notUndoActionUpdate(actionCreator(CurrentPage.ClearBlockWithFile, {
  //         blockId: block.id,
  //         oldType: block.type,
  //       }));
  //     }
  //     historyEvents.splice(historyStep - 1, 1);
  //
  //     decrementHistoryStep();
  //   },
  //   REDO: elegantRedo,
  // },
};

const Playlist = {
  [CurrentPage.AddEmptyLinkPageToPlaylist]: {
    getPrevData: empty,
    UNDO: function* UNDO() {
      const { playlistId, pageData, textComponentId } = historyEvents[historyStep - 1].payload;
      const { shareState } = yield select((state) => state.currentPage);
      if (shareState?.isPublish) {
        yield NotEditable(playlistId);
        return;
      }
      decrementHistoryStep();
      yield notUndoActionUpdate(
        actionRemoveLinkPageFromPlaylist(
          playlistId,
          pageData.id,
          textComponentId,
        ),
      );
    },
    REDO: playlistRedo,
  },
  [EditPlaylist.translateRTB]: {
    getPrevData: (action, state) => {
      const { linkPageId } = action.payload;
      return state.currentPage?.linkPages?.find(
        (linkPage) => linkPage.id === linkPageId,
      );
    },
    UNDO: function* UNDO() {
      const linkPage = historyEvents[historyStep - 1].prevData;
      const { id } = yield select(getCurrentPage);
      decrementHistoryStep();

      yield notUndoActionUpdate(actionChangeTextElementBlockReduxMaker(
        linkPage.textComponent.id,
        linkPage.textComponent.state,
        '',
        id,
        linkPage.id,
        smartFileItemType.richText,
        false,
      ));
    },
    REDO: playlistRedo,
  },
  [CurrentPage.MovePageWithinPlaylist]: {
    getPrevData: (action) => {
      const { updatedLinkPages } = action.payload;

      return updatedLinkPages;
    },
    UNDO: function* UNDO() {
      const { playlistId } = historyEvents[historyStep - 1].payload;
      const { shareState } = yield select((state) => state.currentPage);
      if (shareState?.isPublish) {
        yield NotEditable(playlistId);
        return;
      }
      const prevData = historyEvents[historyStep - 1].prevData;

      decrementHistoryStep();

      yield notUndoActionUpdate(
        actionMovePageWithinPlaylist(prevData, playlistId),
      );
    },
    REDO: playlistRedo,
  },
  [CurrentPage.MovePageWithinPlaylistMaker]: {
    getPrevData: (action, state) => {
      const { id } = action.payload;
      return state.currentPage?.linkPages?.find(
        (linkPage) => linkPage.id === id,
      );
    },
    UNDO: function* UNDO() {
      const { playlistId } = historyEvents[historyStep - 1].payload;
      const { shareState } = yield select((state) => state.currentPage);
      if (shareState?.isPublish) {
        yield NotEditable(playlistId);
        return;
      }
      const { id, position } = historyEvents[historyStep - 1].prevData;

      decrementHistoryStep();

      yield notUndoActionUpdate(
        actionCreator(CurrentPage.MovePageWithinPlaylistMaker, { id, position }),
      );
    },
    REDO: playlistRedo,
  },
  [CurrentPage.UpdateIsShowCaptionState]: {
    getPrevData: () => {},
    UNDO: function* UNDO() {
      const { playlistId, linkPageId, value } = historyEvents[historyStep - 1].payload;
      const { shareState } = yield select((state) => state.currentPage);
      if (shareState?.isPublish) {
        yield NotEditable(playlistId);
        return;
      }
      decrementHistoryStep();
      yield notUndoActionUpdate(
        actionCreator(CurrentPage.UpdateIsShowCaptionState, {
          playlistId,
          linkPageId,
          value: !value,
          isNew: !value,
        }),
      );
    },
    REDO: playlistRedo,
  },
  [CurrentPage.UpdateRemixState]: {
    getPrevData: () => {},
    UNDO: function* UNDO() {
      const { playlistId, linkPageId, value } = historyEvents[historyStep - 1].payload;
      const { shareState } = yield select((state) => state.currentPage);
      if (shareState?.isPublish) {
        yield NotEditable(playlistId);
        return;
      }
      decrementHistoryStep();
      yield notUndoActionUpdate(
        actionCreator(CurrentPage.UpdateRemixState, {
          linkPageId,
          playlistId,
          value: !value,
        }),
      );
    },
    REDO: playlistRedo,
  },
  [CurrentPage.UpdateRemixStateUPV]: {
    getPrevData: () => {},
    UNDO: function* UNDO() {
      const { playlistId, linkPageId, value } = historyEvents[historyStep - 1].payload;
      const { shareState } = yield select((state) => state.currentPage);
      if (shareState?.isPublish) {
        yield NotEditable(playlistId);
        return;
      }
      decrementHistoryStep();
      yield notUndoActionUpdate(
        actionCreator(CurrentPage.UpdateRemixStateUPV, {
          linkPageId,
          playlistId,
          value: !value,
        }),
      );
    },
    REDO: playlistRedo,
  },
  [CurrentPage.RenameLinkedPage]: {
    getPrevData: (action, state) => {
      const { linkPageId } = action.payload;
      return state.currentPage?.linkPages?.find(
        (linkPage) => linkPage.id === linkPageId,
      );
    },
    UNDO: function* UNDO() {
      const { playlistId } = historyEvents[historyStep - 1].payload;
      const prevData = historyEvents[historyStep - 1].prevData;
      decrementHistoryStep();

      yield notUndoActionUpdate(
        actionCreator(CurrentPage.RenameLinkedPage, {
          playlistId,
          linkPageId: prevData.id,
          newTitle: prevData.title,
        }),
      );
    },
    REDO: playlistRedo,
  },
  [CurrentPage.UpdateLinkPageDuration]: {
    getPrevData: (action, state) => {
      const { linkPageId } = action.payload;
      return state.currentPage?.linkPages?.find(
        (linkPage) => linkPage.id === linkPageId,
      );
    },
    UNDO: function* UNDO() {
      const { playlistId } = historyEvents[historyStep - 1].payload;
      const prevData = historyEvents[historyStep - 1].prevData;
      decrementHistoryStep();

      yield notUndoActionUpdate(
        actionCreator(CurrentPage.UpdateLinkPageDuration, {
          playlistId,
          linkPageId: prevData.id,
          value: prevData.duration,
        }),
      );
    },
    REDO: playlistRedo,
  },
  [CurrentPage.TurnIntoBlockSMaker]: {
    getPrevData: (action, state) => {
      const { linkPageId } = action.payload;
      return state.currentPage?.linkPages?.find(
        (linkPage) => linkPage.id === linkPageId,
      );
    },
    UNDO: function* UNDO() {
      const { playlistId } = historyEvents[historyStep - 1].payload;
      const prevData = historyEvents[historyStep - 1].prevData;
      decrementHistoryStep();

      yield notUndoActionUpdate(
        actionCreator(CurrentPage.TurnIntoBlockSMaker, {
          playlistId,
          linkPageId: prevData.id,
          type: prevData.textComponent?.type,
        }),
      );
    },
    REDO: playlistRedo,
  },
  [CurrentPage.RemoveLinkPageFromPlaylist]: {
    getPrevData: (action, state) => {
      const { linkPageIds } = action.payload;

      const linkPageId = linkPageIds;
      let linkPages;
      if (Array.isArray(linkPageIds)) {
        linkPages = state.currentPage?.linkPages.filter((i) => linkPageIds.includes(i.id));
      } else {
        const linkPage = state.currentPage?.linkPages?.find((linkPage) => linkPage.id === linkPageId);
        linkPages = [linkPage];
      }
      return linkPages;
    },
    UNDO: function* UNDO() {
      const { playlistId } = historyEvents[historyStep - 1].payload;
      const oldLinkPages = historyEvents[historyStep - 1].prevData;
      decrementHistoryStep();
      const newLinkPages = oldLinkPages.map(i => {
        if (i.textComponent?.id) return { ...i, textComponent: { ...i.textComponent, id: uuid() } };
        return { ...i };
      });
      yield notUndoActionUpdate(
        actionCreator(CurrentPage.MultipleCreateLibLinkPagePlaylist, {
          newLinkPages,
          playlistId,
        }),
      );
    },
    REDO: elegantRedo,
  },
  [CurrentPage.CreateApproveButtonS]: {
    getPrevData: (action) => {
      const { linkPageId } = action.payload;
      return { linkPageId };
    },
    UNDO: function* UNDO() {
      const { linkPageId } = historyEvents[historyStep - 1].payload;
      decrementHistoryStep();
      yield notUndoActionUpdate(
        actionCreator(CurrentPage.DeleteApproveButtonS, { linkPageId }),
      );
    },
    REDO: elegantRedo,
  },
  [CurrentPage.UpdateApproveButtonS]: {
    getPrevData: (action, state) => {
      const { linkPageId } = action.payload;
      // const { id: playlistId } = state.currentPage;
      const linkPages = state.currentPage?.linkPages || [];
      const interactiveItemData = linkPages.find(
        (lp) => lp.id === linkPageId,
      )?.interactiveItemData;
      return { linkPageId, interactiveItemData };
      // return { linkPageId, interactiveItemData: state.linkPage[playlistId][linkPageId]?.interactiveItemData };
    },
    UNDO: function* UNDO() {
      const { linkPageId } = historyEvents[historyStep - 1].payload;
      const { interactiveItemData } = historyEvents[historyStep - 1].prevData;
      decrementHistoryStep();
      yield notUndoActionUpdate(
        actionCreator(CurrentPage.UpdateApproveButtonS, {
          linkPageId,
          interactiveItemData,
        }),
      );
    },
    REDO: elegantRedo,
  },
  [CurrentPage.DeleteApproveButtonS]: {
    getPrevData: (action, state) => {
      const { linkPageId } = action.payload;
      // const { id: playlistId } = state.currentPage;
      const linkPages = state.currentPage?.linkPages || [];
      const interactiveItemData = linkPages.find(
        (lp) => lp.id === linkPageId,
      )?.interactiveItemData;
      return { linkPageId, interactiveItemData };
      // return { linkPageId, interactiveItemData: state.linkPage[playlistId][linkPageId]?.interactiveItemData };
    },
    UNDO: function* UNDO() {
      const { linkPageId } = historyEvents[historyStep - 1].payload;
      const { interactiveItemData } = historyEvents[historyStep - 1].prevData;
      decrementHistoryStep();
      yield notUndoActionUpdate(
        actionCreator(CurrentPage.CreateApproveButtonS, {
          linkPageId,
          interactiveItemData,
        }),
      );
    },
    REDO: elegantRedo,
  },
  [EditPlaylist.EditTitle]: {
    getPrevData: (action, state) => {
      const { id, isCurrentPage } = action.payload;
      if (isCurrentPage) {
        return state.currentPage.title;
      }
      return state.content.contentData[id]?.title;
    },
    UNDO: function* UNDO() {
      const title = historyEvents[historyStep - 1].prevData;
      const { id, isCurrentPage } = historyEvents[historyStep - 1].payload;
      const item = yield select((state) => state.content.contentData[id]);

      if (!item) {
        yield clearStepAndGoToNext();
        return;
      }
      decrementHistoryStep();
      yield put(actionCurrentEditTitle(title));
      yield notUndoActionUpdate(
        actionEditTitlePlaylist(id, title, isCurrentPage),
      );
    },
    REDO: function* REDO() {
      const { title, id, isCurrentPage } = historyEvents[historyStep].payload;

      yield put(actionCurrentEditTitle(title));
      incrementHistoryStep();

      yield notUndoActionUpdate(actionEditTitlePlaylist(id, title, isCurrentPage));
    },
  },
  [EditPlaylist.ToggleIsShowDescriptionPlaylist]: {
    getPrevData: empty,
    UNDO: function* UNDO() {
      const { id, isShowDescription } = historyEvents[historyStep - 1].payload;
      const { shareState } = yield select((state) => state.currentPage);

      decrementHistoryStep();
      yield notUndoActionUpdate(
        actionToggleIsShowDescriptionPlaylist(id, !isShowDescription),
      );
    },
    REDO: playlistRedo,
  },
  [EditPlaylist.AddImage]: {
    getPrevData: (action, state) => {
      const { oldComponentId, oldPosition, oldImg } = state.currentPage.content;
      return { oldComponentId, oldPosition, oldImg };
    },
    UNDO: function* UNDO() {
      const { oldComponentId, oldPosition, oldImg } = historyEvents[historyStep - 1].prevData;
      decrementHistoryStep();
      yield notUndoActionUpdate(
        actionAddImagePlaylist(oldPosition, oldComponentId, oldImg),
      );
    },
    REDO: playlistRedo,
  },
  [EditPlaylist.DeleteImage]: {
    getPrevData: (action, state) => {
      const { componentId, position, img } = state.currentPage.content;
      return { componentId, position, img };
    },
    UNDO: function* UNDO() {
      const { componentId, position, img } = historyEvents[historyStep - 1].prevData;
      decrementHistoryStep();
      yield notUndoActionUpdate(
        actionAddImagePlaylist(position, componentId, img),
      );
    },
    REDO: playlistRedo,
  },
  [CurrentPage.DuplicateLinkPageS]: {
    getPrevData: empty,
    UNDO: function* UNDO() {
      const { playListId, newId } = historyEvents[historyStep - 1].payload;
      decrementHistoryStep();
      yield notUndoActionUpdate(
        actionRemoveLinkPageFromPlaylist(playListId, newId),
      );
    },
    REDO: playlistRedo,
  },
  [EditPlaylist.EditDescriptionS]: {
    getPrevData: (action, state) => {
      const { description } = state.currentPage;
      return description;
    },
    UNDO: function* UNDO() {
      const description = historyEvents[historyStep - 1].prevData;
      const { id } = historyEvents[historyStep - 1].payload;

      decrementHistoryStep();
      yield notUndoActionUpdate(
        actionEditDescriptionPlaylistS(id, description),
      );
      yield notUndoActionUpdate(
        actionEditDescriptionPlaylistR(id, description),
      );
    },
    REDO: function* REDO() {
      const { description, id } = historyEvents[historyStep].payload;

      incrementHistoryStep();
      yield notUndoActionUpdate(
        actionEditDescriptionPlaylistS(id, description),
      );
      yield notUndoActionUpdate(
        actionEditDescriptionPlaylistR(id, description),
      );
    },
  },
  [EditPlaylist.ChangeTextElementBlockRedux]: {
    getPrevData: (action, state) => {
      const { linkPageId } = action.payload;
      const linkPages = state.currentPage?.linkPages || [];
      const linkPage = linkPages.find((lp) => lp.id === linkPageId);
      const old = linkPage?.textComponent;
      // const old = state.linkPage[playlistId][linkPageId]?.textComponent;
      if (!old) return SKIP;
      return { state: old?.state, innerHtml: old?.innerHtml };
    },
    UNDO: function* UNDO() {
      const { state, innerHtml } = historyEvents[historyStep - 1].prevData;
      const { linkPageId, blockId, playlistId } = historyEvents[historyStep - 1].payload;

      decrementHistoryStep();
      yield notUndoActionUpdate(
        actionChangeTextElementBlockRedux(
          blockId,
          state,
          innerHtml,
          playlistId,
          linkPageId,
        ),
      );
    },
    REDO: playlistRedo,
  },
  [EditPlaylist.ChangeTextElementBlockReduxMaker]: {
    getPrevData: (action, state) => {
      const { linkPageId } = action.payload;

      const linkPages = state.currentPage?.linkPages || [];
      const linkPage = linkPages.find((lp) => lp.id === linkPageId);
      const old = linkPage?.textComponent;
      if (!old) return SKIP;
      return { state: old?.state, innerHtml: old?.innerHtml };
    },
    UNDO: function* UNDO() {
      const { state, innerHtml } = historyEvents[historyStep - 1].prevData;
      const { linkPageId, blockId, playlistId } = historyEvents[historyStep - 1].payload;

      decrementHistoryStep();
      yield notUndoActionUpdate(
        actionChangeTextElementBlockReduxMaker(
          blockId,
          state,
          innerHtml,
          playlistId,
          linkPageId,
        ),
      );
    },
    REDO: playlistRedo,
  },
  [CurrentPage.ChangeLinkPageTypeS]: {
    getPrevData: empty,
    UNDO: function* UNDO() {
      const {
        linkPageId,
        newType: oldType,
        playlistId,
        componentId: addedElementId,
      } = historyEvents[historyStep - 1].payload;

      decrementHistoryStep();
      yield notUndoActionUpdate(
        actionClearLinkPageType(
          linkPageId,
          oldType,
          playlistId,
          addedElementId,
        ),
      );
    },
    REDO: playlistRedo,
  },
  [CurrentPage.ReplaceLinkPagePlaylistPage]: {
    getPrevData: empty,
    UNDO: function* UNDO() {
      const {
        linkPageId,
        newType: oldType,
        playlistId,
        idDragPage,
      } = historyEvents[historyStep - 1].payload;

      decrementHistoryStep();
      yield notUndoActionUpdate(
        actionClearLinkPageType(linkPageId, oldType, playlistId, idDragPage),
      );
    },
    REDO: playlistRedo,
  },
  [CurrentPage.CreateLibLinkPagePlaylist]: {
    getPrevData: empty,
    UNDO: function* UNDO() {
      const { playlistId, newLinkPage, idDragPage } = historyEvents[historyStep - 1].payload;
      decrementHistoryStep();
      yield notUndoActionUpdate(
        actionRemoveLinkPageFromPlaylist(
          playlistId,
          newLinkPage.id,
          idDragPage,
        ),
      );
    },
    REDO: playlistRedo,
  },
  [CurrentPage.CreateAndUploadLinkPage]: {
    getPrevData: empty,
    UNDO: function* UNDO() {
      const { newId } = historyEvents[historyStep - 1].payload;
      historyEvents.splice(historyStep - 1, 1);
      const { id } = yield select((state) => state.currentPage);

      decrementHistoryStep();
      yield notUndoActionUpdate(
        actionRemoveLinkPageFromPlaylist(
          id,
          newId,
        ),
      );
    },
    REDO: empty,
  },
  [EditPlaylist.updateMoveToTrash]: {
    getPrevData: (action, state) => {
      const componentId = action.payload.id;
      const block = state.content.contentData[componentId];
      const index = state.content.contentIds.findIndex(
        (id) => id === componentId,
      );
      return { block, index, oldState: action.payload.state };
    },
    UNDO: function* UNDO() {
      const oldState = historyEvents[historyStep - 1].prevData;
      const { block, index } = historyEvents[historyStep - 1].prevData;
      if (!oldState) {
        yield notUndoActionUpdate(
          actionPlaylistMoveToTrash(historyEvents[historyStep - 1].payload.id),
        );
      } else {
        yield notUndoActionUpdate(
          actionPlaylistReMoveToTrash(
            historyEvents[historyStep - 1].payload.id,
            block,
            index,
          ),
        );
      }
      decrementHistoryStep();
    },
    REDO: elegantRedo,
  },
  // [EditPlaylist.DuplicatePlaylist]: {
  //   getPrevData: empty,
  //   UNDO: function* UNDO() {
  //     const { newId } = historyEvents[historyStep - 1].payload;
  //     yield notUndoActionUpdate(actionCreator(EditPlaylist.updateMoveToTrash, { id: newId, state: true }));
  //     decrementHistoryStep();
  //   },
  //   REDO: elegantRedo,
  // },
  [EditPlaylist.ToggleFavorite]: {
    getPrevData: (action, state) => {
      const id = action.payload.id;
      const block = state.content.contentData[id];
      const index = state.content.contentIds.findIndex((id) => id === id);
      return { block, index };
    },
    UNDO: function* UNDO() {
      const { id, isFavorite, channelId } = historyEvents[historyStep - 1].payload;
      const { block, index } = historyEvents[historyStep - 1].prevData;

      // const item = yield select((state) => state.playlists[id]);
      // if (!item) {
      //   yield clearStepAndGoToNext();
      //   return;
      // }
      yield notUndoActionUpdate(
        actionPlaylistToggleFavorite(id, !isFavorite, channelId, block, index),
      );
      decrementHistoryStep();
    },
    REDO: elegantRedo,
  },
};
const Library = {
  [LibraryComponents.ToggleFavorite]: {
    getPrevData: (action, state) => {
      const componentId = action.payload.componentId;
      const block = state.content.contentData[componentId];
      const index = state.content.contentIds.findIndex(
        (id) => id === componentId,
      );
      return { block, index };
    },
    UNDO: function* UNDO() {
      const { componentId, collectionId, isFavorite, withCurrentPage } = historyEvents[historyStep - 1].payload;
      const { block, index } = historyEvents[historyStep - 1].prevData;

      yield notUndoActionUpdate(
        actionToggleComponentFavorite(
          componentId,
          collectionId,
          !isFavorite,
          withCurrentPage,
          block,
          index,
        ),
      );
      decrementHistoryStep();
    },
    REDO: elegantRedo,
  },
  [LibraryComponents.MoveComponentToTrash]: {
    getPrevData: (action, state) => {
      const componentId = action.payload.componentId;
      const block = state.content.contentData[componentId];
      const index = state.content.contentIds.findIndex(
        (id) => id === componentId,
      );
      return { block, index };
    },
    UNDO: function* UNDO() {
      const { componentId } = historyEvents[historyStep - 1].payload;
      const { block, index } = historyEvents[historyStep - 1].prevData;

      yield notUndoActionUpdate(
        actionRestoreComponentFromTrash({
          componentId,
          position: 1000000.1,
          block,
          index,
        }),
      );
      decrementHistoryStep();
    },
    REDO: elegantRedo,
  },
  [LibraryComponents.UpdateLibraryComponent]: {
    getPrevData: (action, state) => {
      const item = state.content.contentData[action.payload.id];
      return item[action.payload.field];
    },
    UNDO: function* UNDO() {
      const payload = historyEvents[historyStep - 1].payload;
      const prevData = historyEvents[historyStep - 1].prevData;
      const item = yield select(
        (state) => state.content.contentData[payload.id]
          || state.library.collections[payload.collectionId].nestedPage[
            payload.id
          ],
      );

      if (!item) {
        yield clearStepAndGoToNext();
        return;
      }
      const oldDataComponent = {
        field: payload.field,
        id: payload.id,
        value: prevData,
      };
      yield notUndoActionUpdate(
        actionUpdateLibraryComponent(oldDataComponent, payload.collectionId),
      );
      decrementHistoryStep();
    },
    REDO: elegantRedo,
  },
};
const ChannelActions = {
  [CurrentPage.saveInputPlaylist]: {
    getPrevData: (action, state) => {
      let item;
      if (action.payload.place === 'channels') {
        item = state.channels.myChannels[action.payload.itemId];
        return item[action.payload.field];
      }
      item = state.currentPage?.inputs[action.payload.field];
      return item;
    },
    UNDO: function* UNDO() {
      const { field, place, inCollectionId, itemId } = historyEvents[historyStep - 1].payload;
      const prevData = historyEvents[historyStep - 1].prevData;
      let item;
      if (place === '' || place === 'page') {
        item = yield select((state) => state.currentPage);
      }
      if (place === 'channels') {
        item = yield select((state) => state.channels.myChannels[itemId]);
      }
      if (!item) {
        yield clearStepAndGoToNext();
        return;
      }
      yield notUndoActionUpdate(
        actionSaveInputPlaylist(
          field,
          prevData || '',
          place,
          inCollectionId,
          itemId,
        ),
      );
      decrementHistoryStep();
    },
    REDO: elegantRedo,
  },
};

const DropboxActions = {
  [ServiceUser.HandleIntegrationPickedFilesS]: {
    getPrevData: handleDropboxPrevData,
    UNDO: HandleDropboxPickedFiles_UNDO,
    REDO: HandleDropboxPickedFiles_REDO,
  },
  [ServiceUser.HandleIntegrationFileLinkS]: {
    getPrevData: handleDropboxPrevData,
    UNDO: HandleDropboxFileLinkS_UNDO,
    REDO: HandleDropboxFileLinkS_REDO,
  },
};

export const undoActionHandler = {
  ...Playlist,
  ...Library,
  ...ChannelActions,
  ...Page,
  ...DropboxActions,
};

function* undo(action) {
  try {
    if (
      action.payload === 'mod+z'
      && undoActionHandler[historyEvents[historyStep - 1]?.type]
    ) {
      if (historyEvents[historyStep - 1]?.prevData === SKIP) decrementHistoryStep();
      yield undoActionHandler[historyEvents[historyStep - 1]?.type]?.UNDO();
      yield put(actionShowMessage({ type: MessageType.Regular, text: i18n.t('undoUpT') }));
      return;
    }
    if (
      action.payload === 'mod+shift+z'
      && undoActionHandler[historyEvents[historyStep]?.type]
    ) {
      yield undoActionHandler[historyEvents[historyStep]?.type]?.REDO();
      yield put(actionShowMessage({ type: MessageType.Regular, text: i18n.t('redoUpT') }));
    }
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

export default function* undoSaga() {
  yield takeEvery(shortcut.WriteCombination, undo);
}
