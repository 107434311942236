import styled from 'styled-components/macro';
import './style.scss';

export const DefaultButton = styled.button<{ isOutlined?: boolean, customWidth?: number }>`
  display: flex;
  height: var(--button-height);
  padding: 0px var(--button-paddings);
  justify-content: center;
  align-items: center;
  gap: var(--button-row-gap);
  border: 1.5px solid var(--border-default);
  background: transparent;
  border-radius: var(--button-radius);
  outline: none;
  cursor: pointer;
   ${({ customWidth }) => (customWidth ? `width: ${customWidth}px;` : '')}
`;
