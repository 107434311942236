import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import styles from './index.module.scss';
import { actionCloseAIModal, actionOpenModal } from '../../redux/user/action';
import Button2023 from '../Buttons/Button2023';
import { ReactComponent as ChevronSVG } from '../../images/2023/svg/small/complete/chevron_10_6.svg';
import { actionCreator } from '../../shared/redux/actionHelper';
import { CurrentPage } from '../../redux/currentPage/types';
import {
  actionSaveSummarize,
  actionChangeLinkPageType,
  actionEmptyAddLinkPageToPlaylist,
} from '../../redux/currentPage/action';
import {
  getLinkPagePosition,
  generateSimpleRTBState,
  sanitizeToLoad,
  sanitizeHtml,
  parseMarkdown,
  rawAiTextToLexicalState,
} from '../../utils/helpers';
import { aiProcessingStatusPreviewEnum, empty, openModalType } from '../../utils/constants';
import { smartFileItemType } from '../../shared/smartFile/constant';
import { actionChangeTextElementBlockReduxMaker } from '../../redux/playlists/action';
import { calcItemName, calcModalTitle } from '../../utils/ai/helper';
import { isRoleInPlaylist } from '../../utils/permissions';

const cx = classNames.bind(styles);
const { isOwner, isCoEdit } = isRoleInPlaylist;

const CollapsingFilesList = ({
  isMenuExpanded,
  setMenuExpanded,
  dataPayloadAI,
  itemsRef,
  data,
  singleClickHandler,
  t,
}) => {
  const status = (linkPage) => {
    const currentStatus = linkPage.libraryComponent?.parsedStatusByAi || linkPage.libraryComponent?.parsedStatusByAiR;
    if (currentStatus) {
      return t(aiProcessingStatusPreviewEnum[currentStatus]);
    }
    if (linkPage.isTextSavedToVectorDb) {
      return t(aiProcessingStatusPreviewEnum.DONE);
    }
    return '';
  };
  return (
    <>
      <div className={cx('modal_body_details_row')}>
        <div className={cx('modal_body_details_files_wrapper')}>
          <p>{t('filesT')}</p>
          <div
            className={cx('chevron_wrapper', { is_rotated: isMenuExpanded })}
            onClick={() => setMenuExpanded(!isMenuExpanded)}
          >
            <ChevronSVG />
          </div>
        </div>
        <div className={cx('files_count_wrapper')} />
      </div>

      <div className={cx('modal_body_details_table', { expanded_menu: isMenuExpanded })}>
        <div className={cx('modal_body_details_table_header')}>
          <span>№</span>
          <p className={cx('table_title')}>{t('fileNameT')}</p>
          {(dataPayloadAI.aiType === 'ai_send' || dataPayloadAI.aiType === 'ai_queue')
            && <p className={cx('table_title status')}>{t('statusT')}</p>}
        </div>
        <div className={cx('divider', 'first')} />
        <div className={cx('modal_body_details_table_body')} ref={itemsRef}>
          {data.map((linkPage = {}, index) => (
            <div
              className={cx('file_row',
                { disabled: linkPage.isRemixLocked || linkPage.libraryComponent?.type === 'page' })}
              key={linkPage.id}
              onClick={(e) => singleClickHandler(e, linkPage, index)}
            >
              <span>{index + 1}</span>
              <p className={cx('file_title',
                { is_cut: dataPayloadAI.aiType === 'ai_send' || dataPayloadAI.aiType === 'ai_queue' })}
              >
                {calcItemName(linkPage, t)}
              </p>
              {(dataPayloadAI.aiType === 'ai_send' || dataPayloadAI.aiType === 'ai_queue') && (
                <p className={cx('file_title')}>
                  {status(linkPage)}
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

const AIUniversal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const itemsRef = useRef(null);

  const {
    linkPages,
    manager,
    aiProcessing,
    currentSummarize,
    id,
    currentRole,
    currentProcessingTasks,
    wrapperId,
  } = useSelector((state) => state.currentPage);

  const { isUploadingFiles } = useSelector((state) => state.library);
  const user = useSelector((state) => state.user || empty);

  const [isMenuExpanded, setMenuExpanded] = useState(true);
  const [isExtraMenuExpanded, setExtraMenuExpanded] = useState(false);
  const [userTask, setUserTask] = useState('');

  const [dataIds, setDataIds] = useState([]);
  const [data, setData] = useState([]);
  const { dataPayloadAI } = useSelector((state) => state.user);

  const summaryAI = dataPayloadAI?.item?.libraryComponent?.summaryAI || dataPayloadAI?.item?.textComponent?.summaryAI;
  const isEditor = (isOwner[currentRole] || isCoEdit[currentRole]);

  const close = () => {
    if (dataPayloadAI.aiType === 'ai_task'
      || dataPayloadAI.aiType === 'ai_summarize_single'
      || dataPayloadAI.aiType === 'ai_summarize_all'
      || dataPayloadAI.aiType === 'ai_queue'
    ) {
      dispatch(
        actionCreator(CurrentPage.StopAIProcessingInSmartfile, {
          taskIds: currentProcessingTasks,
        }),
      );
      dispatch(actionSaveSummarize(''));
    }
    dispatch(actionCloseAIModal());
  };

  const createLinkPage = (destinationIndex, targetIndex) => {
    const newPos = getLinkPagePosition(destinationIndex, linkPages, targetIndex);
    const linkPageId = uuid();
    const pageData = {
      id: linkPageId,
      position: newPos,
      owner: {
        id: user.id,
        last_name: user.last_name,
        avatarUrlVerySmall: user.avatarUrlVerySmall,
        first_name: user.first_name,
      },
      mainUserInterface: {
        id: uuid(),
      },
    };
    dispatch(actionEmptyAddLinkPageToPlaylist(id, pageData));
    return pageData;
  };

  const summarizeResult = useMemo(() => {
    if (summaryAI) {
      const sanitizedSummary = sanitizeToLoad(summaryAI);
      return {
        rtb: sanitizedSummary.replaceAll('*', ''),
        preview: sanitizeHtml(parseMarkdown(sanitizedSummary)),
      };
    }
    return { rtb: '', preview: '' };
  }, [summaryAI]);

  const setSmartText = () => {
    const isEditor = (isOwner[currentRole] || isCoEdit[currentRole]);
    if (!isEditor) return;

    const createdItem = createLinkPage(linkPages.length - 1);
    const newState = summaryAI
      ? rawAiTextToLexicalState(summaryAI)
      : generateSimpleRTBState(currentSummarize);

    const updateCBaction = (blockId) => {
      return (
        actionChangeTextElementBlockReduxMaker(
          blockId,
          newState,
          '',
          id,
          createdItem.id,
          smartFileItemType.richText,
          true,
        ));
    };
    dispatch(actionChangeLinkPageType(createdItem.id, smartFileItemType.richText, id, null, updateCBaction));
    close();
  };

  const singleClickHandler = useCallback((event, item, index) => {
    const element = event.currentTarget;
    const elemPosition = element.getBoundingClientRect();
    dispatch(
      actionOpenModal(openModalType.UPVPreview,
        { id: item.id, index, mode: 'UPV', elemPosition, linkPages: data }),
    );
  }, [dispatch, data]);

  const handleSubmit = () => {
    if (dataPayloadAI.aiType === 'ai_task') {
      const ids = dataPayloadAI?.items.map(elem => elem.id);
      dispatch(
        actionCreator(CurrentPage.executeAITask, {
          linkPages: ids,
          userTask,
          playlistId: id,
          close: () => dispatch(actionCloseAIModal()),
        }),
      );
    }
    if (dataPayloadAI.aiType === 'ai_search') {
      dispatch(
        actionCreator(CurrentPage.requestAISearch, {
          userTask,
          playlistManagerId: manager?.id ?? wrapperId,
          setDataIds,
        }),
      );
    }
    if (dataPayloadAI.aiType === 'ai_summarize_single'
      || dataPayloadAI.aiType === 'ai_summarize_all'
    ) {
      setSmartText();
    }
    if (dataPayloadAI.aiType === 'ai_send') {
      const ids = data.map(elem => elem.id);
      dispatch(actionCreator(CurrentPage.sendToAI, { linkPages: ids }));
      dispatch(actionCloseAIModal());
    }
  };

  const modalNaming = useMemo(() => {
    return calcModalTitle(dataPayloadAI.aiType, t);
  }, [dataPayloadAI.aiType, t]);

  useEffect(() => {
    if (dataPayloadAI.aiType === 'ai_search') {
      const linkPagesMap = linkPages?.reduce((acc, cur) => {
        acc[cur.id] = cur;
        return acc;
      }, {});
      const responseLinkPages = dataIds.map(id => linkPagesMap[id]);
      setData(responseLinkPages);
    }
    if (dataPayloadAI.aiType === 'ai_task') {
      setData(dataPayloadAI.items);
    }
    if (dataPayloadAI.aiType === 'ai_send' || dataPayloadAI.aiType === 'ai_queue') {
      const items = linkPages?.reduce((acc, cur) => {
        if (dataPayloadAI?.items?.some((elem) => elem.id === cur.id)) {
          acc.push(cur);
        }
        return acc;
      }, []);
      setData(items);
    }
  }, [dataIds, linkPages, dataPayloadAI.aiType, dataPayloadAI?.items]);

  return (
    <div onMouseDown={close} className={cx('modal_wrapper')}>
      <div
        onMouseDown={(e) => e.stopPropagation()}
        className={cx('modal', 'Summarize', 'downloadPlaylist')}
      >
        <h3 className={cx('title')}>{modalNaming.title}</h3>
        {dataPayloadAI.aiType === 'ai_summarize_single' && (
          <div className={cx('item_name')}>{dataPayloadAI?.item?.libraryComponent?.title ?? ''}</div>
        )}
        {dataPayloadAI.aiType === 'ai_summarize_single' && (
          <div className={cx('summary_title')}>Summary</div>
        )}
        {!aiProcessing.isLoading && (dataPayloadAI.aiType === 'ai_search' || dataPayloadAI.aiType === 'ai_task') && (
          <div className={cx('embed_input_wrapper')}>
            <input
              type="text"
              className={cx('embed_input')}
              value={userTask}
              onChange={(e) => setUserTask(e.target.value)}
              placeholder={dataPayloadAI.aiType === 'ai_search' ? t('askAnythingT') : t('enterWhatToDoWithAFileT')}
            />
          </div>
        )}
        {(dataPayloadAI.aiType === 'ai_summarize_all') && (
          <div className={cx('break_text')}>{currentSummarize}</div>
        )}
        {!aiProcessing.isLoading && dataPayloadAI.aiType === 'ai_summarize_single' && (
          <div className={cx('break_text')} dangerouslySetInnerHTML={{ __html: summarizeResult.preview }} />
        )}
        {dataPayloadAI.aiType !== 'ai_summarize' && (
          aiProcessing.isLoading || isUploadingFiles ? (
            <>
              <div className={cx('spinner_wrapper')}>
                <div className="loader_mini">{t('loadingT')}</div>
              </div>
              {aiProcessing.type === 'summarize' && <div>{t('analyzingFilesT')}</div>}
              {aiProcessing.type === 'ai_search' && <div>{t('searchingForResultsT')}</div>}
            </>
          ) : (
            data?.length > 0 && (
              <>
                {dataPayloadAI.aiType === 'ai_search' && 'Best Result:'}
                <CollapsingFilesList
                  isMenuExpanded={isMenuExpanded}
                  setMenuExpanded={setMenuExpanded}
                  dataPayloadAI={dataPayloadAI}
                  itemsRef={itemsRef}
                  data={dataPayloadAI.aiType === 'ai_search' ? [data[0]] : data}
                  singleClickHandler={singleClickHandler}
                  t={t}
                />
                {dataPayloadAI.aiType === 'ai_search' && 'Rest Results:'}
                {dataPayloadAI.aiType === 'ai_search' && data.length > 1 && (
                  <CollapsingFilesList
                    isMenuExpanded={isExtraMenuExpanded}
                    setMenuExpanded={setExtraMenuExpanded}
                    dataPayloadAI={dataPayloadAI}
                    itemsRef={itemsRef}
                    data={data.slice(1)}
                    singleClickHandler={singleClickHandler}
                    t={t}
                  />
                )}
              </>
            )
          )
        )}
        <div className={cx('buttons_group')}>
          {dataPayloadAI.aiType !== 'ai_queue' && isEditor && (
            <Button2023
              variant="gradientGreenSquare"
              height={32}
              text={modalNaming.button}
              noMargins
              isDisabled={aiProcessing.isLoading || isUploadingFiles}
              handleButtonClick={handleSubmit}
            />
          )}
          <Button2023
            variant="gradientGreenSquare"
            height={32}
            text={t('closeT')}
            noMargins
            handleButtonClick={close}
          />
        </div>
      </div>
    </div>
  );
};

export default AIUniversal;
