import React, { useCallback, useEffect, useRef, useState } from 'react';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { useTranslation } from 'react-i18next';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { TRANSFORMERS } from '@lexical/markdown';
import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin';
import { EditorUndoRedoPlugin } from '../../shared/lexical/plugin/EditorUndoRedoPlugin';
import { EditorBlurPlugin } from '../../shared/lexical/plugin/EditorBlurPlugin';
import { UpdatePlugin } from '../../shared/lexical/plugin/UpdatePlugin';
import { editorConfig } from '../../shared/lexical/configs';
import '../../shared/lexical/ui/PlaygroundEditorTheme.css';
import '../../shared/lexical/ui/index.scss';
import './TextEditorTheme.scss';
import useThrottle from '../../utils/hooks/useThrottle';
import ToolbarPlugin from './plugin/ToolbarPlugin';
import { ParagraphPlaceholderPlugin } from '../../shared/lexical/plugin/ParagraphPlaceholderPlugin';
import DraggableBlockPlugin from './plugin/DraggableBlockPlugin';
import FloatingLinkEditorPlugin from './plugin/FloatingLinkEditorPlugin';
import FloatingTextFormatToolbarPlugin from './plugin/FloatingTextFormatToolbarPlugin';
import LinkPlugin from './plugin/LinkPlugin';
import ComponentPickerMenuPlugin from './plugin/ComponentPickerPlugin';
import { DATA_PARENTS } from '../../utils/constants';
import TableCellResizerPlugin from './plugin/TableCellResizer';
import { HorizontalRulePlugin } from './plugin/LexicalHorizontalRulePlugin';
import PlaceholderPlugin from '../../shared/lexical/plugin/PlaceHolderPlugin';
import TableActionMenuPlugin from './plugin/TableActionMenuPlugin';
import CodeHighlightPlugin from './plugin/CodeHighlightPlugin';
import ListMaxIndentLevelPlugin from './plugin/ListMaxIndentLevelPlugin';
import TabFocusPlugin from './plugin/TabFocusPlugin';
import { TabIndentationPlugin } from './plugin/LexicalTabIndentationPlugin';
import AutoLinkPlugin from '../../pages/Lexical/plugin/AutoLinkPlugin';
import YouTubePlugin from './plugin/YouTubePlugin';
import FigmaPlugin from './plugin/FigmaPlugin';
import AutoEmbedPlugin from './plugin/AutoEmbedPlugin';


const TextEditor = ({
  state,
  onChange,
  isActive,
  onBlurHandler,
  setIsDNDInTextBlock,
  idKey,
  isAIGenerated,
  isCollapsed,
  isAITranslated,
}) => {
  const [throttleSave] = useThrottle(300);
  const [isLinkEditMode, setIsLinkEditMode] = useState(false);
  const [floatingAnchorElem, setFloatingAnchorElem] = useState(null);
  const [showTollbar, setShowToolbar] = useState(null);
  const openLinkRef = useRef(false);
  const isOpenOptionsRef = useRef(false);
  const { t } = useTranslation();

  const onRef = (_floatingAnchorElem) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };
  const setIsOpenOptions = useCallback((isOpenOptions) => {
    isOpenOptionsRef.current = isOpenOptions;
  }, []);

  useEffect(() => {
    openLinkRef.current = isLinkEditMode;
  }, [isLinkEditMode]);


  const preventUpdateOutside = useRef(false);
  const onChangeHandler = useCallback((editorState) => {
    const oldState = JSON.stringify(state);
    const newState = JSON.stringify(editorState);
    if (newState === oldState) {
      return;
    }

    if (!onChange) return;
    preventUpdateOutside.current = true;
    throttleSave(() => () => onChange(editorState.toJSON()));
  }, [onChange, state]);

  const onBlurHandlerWrap = (state) => {
    setShowToolbar(false);
    onBlurHandler && onBlurHandler(state);
  };

  return (
    <div tabIndex="0" data-parent={DATA_PARENTS.TextFormatToolbar} className="editor-container-wrap">
      <LexicalComposer initialConfig={editorConfig}>
        {!isAIGenerated && (
        <ToolbarPlugin
          showTollbar={showTollbar}
          setShowToolbar={setShowToolbar}
          isLinkEditMode={isLinkEditMode}
          setIsLinkEditMode={setIsLinkEditMode}
          setIsOpenOptions={setIsOpenOptions}
        />
        )}
        <div className="editor-container">
          {isAIGenerated && !isCollapsed && !isAITranslated && <p className="ai-generated_label">Generated with Quidzi AI</p>}
          {isAITranslated && !isCollapsed && <p className="ai-generated_label">Translated with Quidzi AI</p>}
          <div className="editor-inner" data-parent={idKey} ref={onRef}>
            <RichTextPlugin
              contentEditable={<ContentEditable className="editor-input richtext-input" />}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <AutoEmbedPlugin />
            <CodeHighlightPlugin />
            <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
            <OnChangePlugin ignoreSelectionChange onChange={onChangeHandler} />
            <EditorUndoRedoPlugin />
            <LinkPlugin />
            <ListPlugin />
            <CheckListPlugin />
            <TablePlugin />
            <PlaceholderPlugin placeholderClass="placeholder-text" placeholder={t('emptyTextT')} />
            <HorizontalRulePlugin />
            <TableCellResizerPlugin />
            <ComponentPickerMenuPlugin setIsOpenOptions={setIsOpenOptions} />
            <ListMaxIndentLevelPlugin maxDepth={7} />
            <TabFocusPlugin />
            <AutoLinkPlugin />
            <YouTubePlugin />
            <FigmaPlugin />

            <TabIndentationPlugin />
            <UpdatePlugin state={state} preventUpdateOutside={preventUpdateOutside} isActive={isActive} />
            <EditorBlurPlugin
              isLinkEditMode={openLinkRef}
              idKey={idKey}
              isOpenOptionsRef={isOpenOptionsRef}
              setIsLinkEditMode={setIsLinkEditMode}
              onBlurHandler={onBlurHandlerWrap}
            />
            <FloatingTextFormatToolbarPlugin
              isHide={isLinkEditMode}
              setIsLinkEditMode={setIsLinkEditMode}
              anchorElem={floatingAnchorElem}
            />
            <ParagraphPlaceholderPlugin placeHolderClass="textPlaceHolder" />
            {floatingAnchorElem && showTollbar && (
              <>
                <DraggableBlockPlugin
                  setIsDNDInTextBlock={setIsDNDInTextBlock}
                  setIsOpenOptions={setIsOpenOptions}
                  anchorElem={floatingAnchorElem}
                />
                <TableActionMenuPlugin
                  anchorElem={floatingAnchorElem}
                  cellMerge
                />
                <FloatingLinkEditorPlugin
                  anchorElem={floatingAnchorElem}
                  isLinkEditMode={isLinkEditMode}
                  setIsLinkEditMode={setIsLinkEditMode}
                />
              </>
            )}
          </div>
        </div>
      </LexicalComposer>
    </div>

  );
};

export default TextEditor;
