import styled from 'styled-components/macro';
import { LockButtonShell } from '../../MakerCommon/LockButton';

export const TimeButtonBody = styled.div`
  position: relative;
  margin-top: 6px;
  margin-right: 6px;
  transition: opacity ease-out 0.15s;
  opacity: 1;
  ${({ isVideoAudio }) => (isVideoAudio ? 'cursor: not-allowed;' : '')}
  ${({ isTextPopup }) => (isTextPopup ? ' margin-top: 0;margin-right: 10px;' : '')}
`;

export const ElementShell = styled.div`
  width: 100%;
  ${({ hasIndention }) => (hasIndention ? 'margin-left: 50px;' : '')}  
`;

export const Controls = styled.div`
  height: 100%;
  flex-basis: 94px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const SvgWrapper = styled.div`
  width: 27px;
  height: 27px;
  border-radius: 6px;
  padding-left: 6px;
  padding-top: 6px;
  ${({ isInactive }) => (isInactive ? `  
    svg {
      opacity: 0.15;
    }
  ` : '')}
  svg {
    path {
      stroke: #484343;
    }
  }
  ${({ top }) => (top ? `top:${top}px;` : '')}
  ${({ left }) => (left ? `left:${left}px;` : '  ')}
  ${({ right }) => (right ? `right:${right}px;` : '  ')}
  ${({ isMore }) => (isMore
    ? ` 
      visibility: hidden;
      padding-left: 4px;
      padding-top: 4px;`
    : '')}
  ${({ isAbsolute }) => (isAbsolute
    ? `
  position:absolute;`
    : `
  position:relative;
  `)}
  ${({ isDisabled }) => (isDisabled
    ? `
opacity:0.5;
cursor:not-allowed;
  `
    : `
    &:hover {
    background: #FFDF85;
  }

  `)}
`;

export const SvgWrapper2 = styled.div`
  width: 27px;
  height: 27px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  
  ${({ isDisabled }) => (isDisabled
    ? `
  opacity:0.5;
  cursor:not-allowed;
  `
    : `
    &:hover {
    background: #FFDF85;
  }

  `)}
`;

export const ElementBase = styled.div`
  transition: background-color, height  ease-out 0.3s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
  height: 120px;
  right: 550px;
  top: 469px;
  ${({ isCompressView }) => (isCompressView ? ' border-radius: 8px;' : ' border-radius: 12px;')}
  background: #ffffff;
  border: 4px solid transparent;
  padding-left: 18px;
  ${({ isCompressView }) => (isCompressView ? 'height: 52px;' : '')}

    &:hover, &.is_active {
    background: #E6AC190F;
    ${TimeButtonBody}, ${LockButtonShell} {
      opacity: 1;
    }
    ${SvgWrapper} {
      visibility: visible;
    }
  }
  &.is_active {
    background: #F8EAC9;
  }
  &:focus {
    border-color: #E6AC190F;
  }
  ${({ isTimeActive }) => (isTimeActive
    ? `
    ${TimeButtonBody} {
    
      opacity: 1;
    }
  `
    : '')}
  ${({ isLocked }) => (isLocked
    ? `
    ${LockButtonShell} {
    
      opacity: 1;
    }
  `
    : '')}
`;

export const MainInfo = styled.div`
  flex-grow: 2;
  display: flex;
  align-items: center;
`;

export const TopInfoPartWrapper = styled.div`
  width: 100%;
  display: flex;
`;
export const BottomInfoPartWrapper = styled.div`
  width: 100%;
  align-items: center;
  height: 33px;
  display: flex;
  justify-content: space-between;
`;
export const OptionsShell = styled.div`
  padding: 9px 14px 0 0;
`;
export const OptionsShellCollapsed = styled.div`
  padding-right:  16px ;
`;
export const VerticalPartsDivider = styled.div`
  height: 1px;
  width: 98%;
  background: rgba(29, 29, 29, 0.08);
`;
export const LockWrapper = styled.div`
  border: 1px solid rgb(243, 212, 165);
  background: #fff;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 5px;
  margin-right: 3px;
  width: 20px;
  max-width: 20px;
  overflow: hidden;
  transition: 0.3s ease-in;
  height: 20px;
  border-radius: 20px;
  position: absolute;
  z-index: 1;
  top: 17px;

  ${({ hasIndention }) => (hasIndention ? 'left: 46px;' : 'left: -4px;')}
  padding-bottom: 2px;
  
  svg {
    width: 11px;
    height: 11px;
  }
`;
export const SizeWrapper = styled.div`
    color: #676767;
    font-size: 14px;
`;
export const TooltipWrapper = styled.div`
    position: absolute;
    ${({ isExpandViewComponent }) => (`top: ${isExpandViewComponent ? '36px' : '-7px'};`)};
    left: -7px;
`;
export const UsedByAIIndicator = styled.div`
    ${({ borderColor }) => (`border: 1px solid ${borderColor};`)}
    ${({ backgroundColor }) => (`background: ${backgroundColor};`)}
    display: flex;
    gap: 5px;
    margin-right: 3px;
    width: 20px;
    max-width: 20px;
    overflow: hidden;
    transition: all 0.3s ease-in;
    height: 20px;
    border-radius: 20px;
    position: absolute;
    z-index: 1;
    top: -10px;
    left: -4px;
    .indicator_text,
    .retry_icon {
      display: none;
      margin: 0;
    }
    &:hover {
      width: fit-content;
      max-width: 200px;
      .indicator_text,
      .retry_icon {
        display: block;
      }
    }
    ${({ svgColor }) => (`
      svg path {
        fill: ${svgColor};
      }
    `)}
    .retry_icon path {
      fill: #514E55;
    }
`;
export const FatDot = styled.div`
  color: #484343;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  font-family: Roboto, sans-serif;
  line-height: 20px;
  margin-left: 4.5px;
  margin-right: 5px;
  margin-top: 3px;
`;
export const BothPartsWrapper = styled.div`
  width: 100%;
  margin-left: 23px;
`;

export const TimeButtonShell = styled.div`
  ${({ upvCARD }) => (upvCARD
    ? `
    position: relative;
    left: 63px;

}
  `
    : `
    position: absolute;
    top: 32px;`)}
    ${({ isTextPopup }) => (isTextPopup
    ? `
      position: static;
    ` : '')}
`;


export const TimeButton = styled.div`
  border-radius: 6px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-property: background-color, color;
  transition: ease-out 0.25s;
  color: #484343;
  padding: 0 4px;

  ${({ isActive }) => (isActive ? 'background: #FFC72C;' : '')}
  ${({ isActive, isTextPopup }) => (isActive && isTextPopup ? 'background: #FFEEBF;' : '')}
  svg{
    margin-right: 2px;
  }
  & svg path {
    stroke: #484343;
  }

  ${({ isBlocked }) => (isBlocked
    ? `
  & svg path {
    stroke: rgb(163, 170, 204);
  }
  `
    : '')}
  ${({ isDisabled, isTextPopup }) => (isDisabled
    ? `
  cursor:not-allowed;
  `
    : `
    &:hover {
    background: ${isTextPopup ? '#FFEEBF' : '#FFDF85'};
  }
  `)}
`;

export const TimeHint = styled.div`
  white-space: nowrap;
  margin-right: 6px;
`;
