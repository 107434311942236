import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MomentBlock from '../MomentBlock/MomentBlock';
import styles from './index.module.css';
import { ReactComponent as PlusSVG } from '../../images/2023/svg/small/create-block/plus_grey_24.svg';

import { ReactComponent as ErrorCreateIcon } from '../../images/icons/icon_20/error_create.svg';

import { actionToggleLibraryDrag } from '../../redux/currentPage/action';

import useCreateLibraryComponentVisible from '../../utils/hooks/useCreateLibraryClickOutside';
import {
  actionBlockUnloading,
  actionUnblockUnloading,
} from '../../redux/user/action';
import { intersect } from '../../utils/helpers';
import { ReactComponent as DropLineSvg } from '../../images/icons/drop_area.svg';
import { empty } from '../../utils/constants';
import {
  actionAddSupportEditableBlockRedux,
  actionChangeSupportEditableBlockRedux,
  actionClearSupportBlocks,
  actionDeleteManyBlocksSupportRedux,
  actionDeleteSupportEditableBlockRedux,
  actionDragManySupportBlockRedux,
} from '../../redux/support/action';
import ActionDraggable from '../../redux/dragable/action';

const BlocksEditingArea = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const { id } = useParams();
  const libraryDragState = useSelector(
    (state) => state.library.dragState || empty,
  );
  // const targetPageId = useSelector(state => state.currentPage.id);
  const { libraryDragStatus } = useSelector((state) => state.currentPage);
  const editableBlocks = useSelector((state) => state.support.blocks) || [];
  const pressModPlusA = useSelector(
    (state) => state.shortcut.combination['mod+a'],
  );
  const { draggableBlocks } = useSelector((state) => state.draggable);
  // const idDragPage = useSelector(state => state.currentPage.idDragPage) || false;
  const refSelectedBlocks = { current: [] };
  const [isCreateComponentMenuVisible, setIsCreateComponentMenuVisible] = useCreateLibraryComponentVisible(false);
  // const [showComponentLabel, setShowComponentLabel] = useState(false);
  const [blocksToCreateLComponent, setBlocksToCreateLComponent] = useState({});
  const [dropDepth, setDropDepth] = useState(0);
  const [idOpenMenuBlock, setIdOpenMenuBlock] = useState('');
  const [selectedIndex, setSelectedIndex] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [mouseDown, setMouseDown] = useState(false);
  const [startPoint, setStartPoint] = useState(null);
  const [endPoint, setEndPoint] = useState(null);
  const [selectionBox, setSelectionBox] = useState(null);
  const [hasDragHover, setHasDragHover] = useState('');
  // const [createNewCollectionId, setCreateNewCollectionId] = useState(null);
  // const [isCreateNew, setIsCreateNew] = useState(false);
  const isUnloadingBlocked = useSelector(
    (state) => state.user.isUnloadingBlocked,
  );
  const isShowLastLine = hasDragHover === 'last'
    && !!(Object.keys(libraryDragState).length || !!draggableBlocks.length);

  const mouseup = () => {
    const indexArray = [];
    const idsArray = [];
    document.removeEventListener('mouseup', mouseup, true);
    setIdOpenMenuBlock('');
    if (libraryDragStatus) dispatch(actionToggleLibraryDrag(false));
    // TODO resolve clickOutSide conflict on approveButton settings menu
    const selectedBlocks = refSelectedBlocks.current
      .map((item, index) => {
        if (item && item.hasAttribute('class')) {
          indexArray.push(index);
          idsArray.push(item.id);
          const { top, bottom, height, width } = item.getBoundingClientRect();
          return { id: item.id, top, bottom, width, height };
        }
        return null;
      })
      .filter((i) => i);
    setSelectedIndex(indexArray);
    setSelectedIds(idsArray);

    if (selectedBlocks.length) {
      setMouseDown(false);
    } else {
      setMouseDown(false);
      setStartPoint(null);
    }
  };

  useEffect(() => {
    return () => dispatch(actionClearSupportBlocks());
  }, []);

  useEffect(() => {
    if (!isUnloadingBlocked && editableBlocks.length) {
      dispatch(actionBlockUnloading());
    }
    if (isUnloadingBlocked && !editableBlocks.length) dispatch(actionUnblockUnloading());
    if (props.needShowWarning !== !!editableBlocks.length) {
      props.setNeedShowWarning(!!editableBlocks.length);
    }
    return () => dispatch(actionUnblockUnloading());
  }, [editableBlocks.length]);

  const isShowManyDragHandler = (blockId, index) => {
    if (blockId === 'last') {
      return (
        !!draggableBlocks.length
        && !draggableBlocks.includes(
          editableBlocks[editableBlocks.length - 1]?.id,
        )
        && editableBlocks.length - 1 === index
      );
    }
    return (
      !!draggableBlocks.length
      && !draggableBlocks.includes(blockId)
      && !draggableBlocks.includes(editableBlocks[index - 1]?.id)
    );
  };

  useEffect(() => {
    if (Object.keys(libraryDragState).length) {
      setDropDepth(0);
      setHasDragHover('');
    }
  }, [libraryDragState]);

  useEffect(() => {
    const idsArray = [];
    const indexArray = [];

    if (pressModPlusA) {
      refSelectedBlocks.current.forEach((item, index) => {
        if (item) {
          idsArray.push(item.id);
          indexArray.push(index);
          item.setAttribute('class', 'selected_block');
        }
      });
      setSelectedIndex(indexArray);
      setSelectedIds(idsArray);
    }
  }, [pressModPlusA]);

  const selectBlock = (ids) => {
    const idsArray = [];
    const indexArray = [];

    refSelectedBlocks.current.forEach((item, index) => {
      if (item && ids.includes(item.id)) {
        idsArray.push(item.id);
        indexArray.push(index);
        item.setAttribute('class', 'selected_block');
      } else if (item) item.removeAttribute('class');
    });

    setSelectedIndex(indexArray);
    setSelectedIds(idsArray);
  };

  useEffect(() => {
    dispatch(ActionDraggable.SetHasDraggable(false));
    dispatch(ActionDraggable.BlocksOnPage([]));
    dispatch(actionToggleLibraryDrag(false));
    setIsCreateComponentMenuVisible(false);
    const readyLength = Object.values(blocksToCreateLComponent || {}).filter(
      (i) => !(i.status === 'ready'),
    ).length;
    if (readyLength) setBlocksToCreateLComponent({});
  }, [mouseDown]);

  const onMouseDown = (e) => {
    if (props.isVisibleFolderOption) return;
    if (e.button === 2 || e.nativeEvent.which === 2) {
      return;
    }
    document.addEventListener('mouseup', mouseup, true);

    setMouseDown(true);
    setStartPoint({
      x: e.pageX,
      y: e.pageY,
    });
    setEndPoint(null);
    setSelectionBox(null);
    refSelectedBlocks.current.forEach((item) => {
      if (item) {
        item.removeAttribute('class');
      }
    });
  };

  useEffect(() => {
    if (selectionBox && selectionBox.left) {
      refSelectedBlocks.current.forEach((item) => {
        if (item) {
          if (intersect(selectionBox, item.getBoundingClientRect())) {
            item.setAttribute('class', 'selected_block');
          } else item.removeAttribute('class');
        }
      });
    }
  }, [selectionBox]);

  const calculateSelectionBox = (start, end) => {
    if (!mouseDown || !end || !start) {
      return null;
    }
    const left = Math.min(start.x, end.x);
    const top = Math.min(start.y, end.y);
    const width = Math.abs(start.x - end.x);
    const height = Math.abs(start.y - end.y);
    if (height < 3 || width < 3) {
      return null;
    }
    return {
      left,
      top,
      width,
      height,
    };
  };

  const renderSelectionBox = () => {
    if (!mouseDown || !endPoint || !startPoint) {
      return null;
    }
    return <div className={styles.selection_border} style={selectionBox} />;
  };

  const onMouseMove = (e) => {
    if (mouseDown) {
      e.preventDefault();
      const end = {
        x: e.pageX,
        y: e.pageY,
      };
      setEndPoint(end);
      setSelectionBox(calculateSelectionBox(startPoint, end));
    }
  };
  const clear = () => {
    refSelectedBlocks.current.forEach((item) => {
      if (item) {
        item.removeAttribute('class');
      }
    });
    setIsCreateComponentMenuVisible(false);
    setBlocksToCreateLComponent({});
    setSelectedIds([]);
    setSelectedIndex([]);
    dispatch(actionToggleLibraryDrag(false));
  };

  useEffect(() => {
    return () => {
      document.removeEventListener('mouseup', mouseup, true);
    };
  }, []);
  //
  // useEffect(() => {
  //   dispatch(actionGetCurrentPage(id));
  //   refSelectedBlocks.current = [];
  // }, [id]);

  // useEffect(() => {
  //   // dispatch(actionGetCurrentPage(id));
  //   refSelectedBlocks.current = [];
  // }, [id]);

  const addEditableBlock = (
    blockId,
    type = 'text',
    pageId,
    isClone,
    eraseContent,
  ) => {
    if (props.showWarningCreate) props.closeShowWarningCreate();
    dispatch(
      actionAddSupportEditableBlockRedux(
        blockId,
        type,
        pageId,
        isClone,
        undefined,
        eraseContent,
      ),
    );
  };

  const deleteEditableBlock = (blockId, type) => () => {
    dispatch(actionDeleteSupportEditableBlockRedux(blockId, type));
  };

  const onChangeEditableBlock = (blockId) => (newState, innerHtml) => {
    dispatch(
      actionChangeSupportEditableBlockRedux(blockId, newState, innerHtml),
    );
  };

  const stopPropagationInput = (event) => {
    if (!selectionBox) {
      event.stopPropagation();
    }
  };
  const handlerClick = (event, open) => {
    if (open) setIdOpenMenuBlock(open);
    if (selectionBox && !open) {
      setMouseDown(false);
      event.stopPropagation();
      event.preventDefault();
    }
  };

  const onEditorAreaMouseDrop = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    setHasDragHover('');
    // if (libraryDragState) {
    //   dispatch(actionCopyBlocksToPage(targetPageId, libraryDragState, index));
    // }
    if (draggableBlocks) {
      dispatch(
        actionDragManySupportBlockRedux(id, () => selectBlock(draggableBlocks)),
      );
    }
  };

  const onCopyHandler = () => {
    // let text = '';
    // let html = `${draggableBlocks}`;
    // let html = `<meta data-test="${draggableBlocks}">`;
    // if (!draggableBlocks.length) return;
    // refSelectedBlocks.current.forEach(i => {
    //   if (draggableBlocks.includes(i.getAttribute('id'))) {
    //     if (i.getElementsByClassName('public-DraftEditorPlaceholder-inner').length) {
    //       return;
    //     }
    //     const test = i.getElementsByClassName('customEditor')[0];
    //     html += test?.innerHTML ?? '';
    //     text += test?.textContent ?? '';
    //   }
    // });
    // const data = new Blob([html], { type: 'text/html' });
    // const data1 = new Blob([text], { type: 'text/plain' });
    // const item = new ClipboardItem({ 'text/html': data, 'text/plain': data1 });
    // navigator.clipboard.write([item]);
  };

  const backSpaceHandler = (e) => {
    if (
      e.key === 'Backspace'
      && !!selectedIds.length
      && e.target?.dataset.parent !== 'createLibrary'
    ) {
      dispatch(actionDeleteManyBlocksSupportRedux(selectedIds));
    }
  };

  const onDragLeave = () => {
    const newdropDepth = dropDepth - 1;
    setDropDepth(newdropDepth);
    if (newdropDepth > 0) return;
    setHasDragHover('');
  };
  const onDragEnter = (idHover) => {
    setDropDepth(() => dropDepth + 1);
    setHasDragHover(idHover);
  };
  // const onDrag = (idHover) => {
  //   setDropDepth(() => dropDepth + 1);
  //   setHasDragHover(idHover);
  // };

  // tabIndex="0" to catch and handle onKeyDown event in react way
  return (
    <div
      tabIndex="0"
      className={styles.wrapperSelect}
      onKeyDown={backSpaceHandler}
      onCopy={onCopyHandler}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
    >
      {renderSelectionBox()}
      <div className={styles.wrapper}>
        <div
          className={`${styles.edit_content} ${
            draggableBlocks.length ? 'hasDragBlocks' : ''
          }`}
        >
          {props.showWarningCreate && (
            <div className={styles.wrong_create}>
              <ErrorCreateIcon />
              Add content first to create component
            </div>
          )}
          <div
            onDragOver={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            onDrop={(e) => onEditorAreaMouseDrop(e, editableBlocks[0]?.id, 0)}
            onDragEnter={() => onDragEnter(editableBlocks[0]?.id)}
            onDragLeave={onDragLeave}
            className={styles.top_handler}
          />
          {editableBlocks
            .sort((a, b) => a.position - b.position)
            .map(
              (
                {
                  id,
                  state,
                  type,
                  position,
                  title,
                  nestedItemId,
                  innerHtml,
                  preSave,
                  width,
                  ...other
                },
                index,
              ) => {
                return (
                  <div key={id}>
                    <div
                      className={`
                  ${styles.relative}
                  ${props.createComponentWidget ? styles.create_component : ''}
                  ${idOpenMenuBlock === id ? styles.z_index_up : ''}
                  `}
                    >
                      <div
                        id={id}
                        onDragOver={(e) => e.preventDefault()}
                        ref={(ref) => refSelectedBlocks.current.push(ref)}
                        onDrop={(e) => onEditorAreaMouseDrop(e, id, index)}
                        onDragEnter={() => onDragEnter(id)}
                        onDragLeave={onDragLeave}
                      >
                        {!!Object.keys(draggableBlocks).length
                          && isShowManyDragHandler(id, index)
                          && hasDragHover === id && (
                            <div className={styles.dragLine_wrap}>
                              <DropLineSvg className={styles.dragLineSvg} />
                            </div>
                        )}

                        <MomentBlock
                          {...other}
                          setBlocksToCreateLComponent={
                            setBlocksToCreateLComponent
                          }
                          blocksToCreateLComponent={blocksToCreateLComponent}
                          blocksIds={selectedIds}
                          blocksIndices={selectedIndex}
                          setBlocksIds={setSelectedIds}
                          setBlocksIndices={setSelectedIndex}
                          isLibraryCreate
                          mouseup={mouseup}
                          setIdOpenMenuBlock={setIdOpenMenuBlock}
                          idOpenMenuBlock={idOpenMenuBlock}
                          setIsCreateComponentMenuVisible={
                            setIsCreateComponentMenuVisible
                          }
                          stopPropagation={stopPropagationInput}
                          clear={clear}
                          handlerClick={handlerClick}
                          // isDragging={snapshot.isDragging}
                          selectBlock={selectBlock}
                          type1={type}
                          state={state}
                          componentId={id}
                          innerHtml={innerHtml}
                          position={position}
                          // currentPageId={"isLibraryCreate"}
                          index={index}
                          // dragHandleProps={provided.dragHandleProps}
                          addEditableBlock={(
                            blockType,
                            currentPageId,
                            isClone,
                            eraseContent,
                          ) => {
                            addEditableBlock(
                              id,
                              blockType,
                              currentPageId,
                              isClone,
                              eraseContent,
                            );
                          }}
                          deleteEditableBlock={deleteEditableBlock(id, type)}
                          onChangeBlocksWithData={onChangeEditableBlock(id)}
                          onChangeEditableBlock={onChangeEditableBlock(id)}
                          page={{ title, nestedItemId }}
                          createComponentWidget={props.createComponentWidget}
                          preSave={preSave}
                          isViewMode={props.isViewMode}
                          width={width}
                        />
                      </div>
                    </div>
                  </div>
                );
              },
            )}

          <div
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => onEditorAreaMouseDrop(e, 'last')}
            onDragEnter={() => onDragEnter('last')}
            onDragLeave={onDragLeave}
            className={styles.wrapper_add_block}
          >
            {isShowLastLine && (
              <div className={styles.dragLine_wrap}>
                <DropLineSvg className={`${styles.dragLineSvg} `} />
              </div>
            )}

            <div
              onClick={() => addEditableBlock(
                editableBlocks[editableBlocks.length - 1]?.id || '',
                'new',
              )
              }
              className={`${styles.add_block} ${
                editableBlocks.length && styles.add_block__on_end
              }`}
            >
              <PlusSVG />
              <div>{t('addBlockUpT')}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlocksEditingArea;
