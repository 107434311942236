import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckBoxOffSvg } from '../../images/icons/batchActions/checkbox_off.svg';
import { ReactComponent as CheckBoxOnSvg } from '../../images/icons/batchActions/checkbox_on.svg';
import styles from './index.scss';
import { actionSelectItemInSmartfile } from '../../redux/currentPage/action';
import { DATA_PARENTS, emptyCallback } from '../../utils/constants';
import Tooltip from '../../shared/Tooltips/Tooltip';

const cn = classNames.bind(styles);

const CheckBoxLinkPageLayout = React.memo(function IndexSectionLayout({
  selectItem,
  isSelected,
  isDisabled,
}) {
  const { t } = useTranslation();
  const handleSelectItem = (e) => {
    e.stopPropagation();
    selectItem(e);
  };
  return (
    <Tooltip text={t('selectT')} direction="down">
      <div
        data-parent={DATA_PARENTS.checkItem}
        className={cn('checkbox_shell', { disabled: isDisabled, is_active: isSelected })}
      >
        <div
          className={cn('svg_wrapper')}
          onClick={isDisabled ? emptyCallback : handleSelectItem}
          data-parent={DATA_PARENTS.checkItem}
        >
          {isSelected ? (
            <CheckBoxOnSvg className={cn('checkbox_svg', 'yellow_checkbox')} data-parent={DATA_PARENTS.checkItem} />
          ) : <CheckBoxOffSvg className={`${cn('checkbox_svg')} yellow_checkbox`} data-parent={DATA_PARENTS.checkItem} />}
        </div>
      </div>
    </Tooltip>
  );
});

const CheckBoxLinkPage = ({
  itemId, isDisabled, isSelected,
}) => {
  const dispatch = useDispatch();

  const selectItem = useCallback((e) => {
    e.stopPropagation();
    dispatch(actionSelectItemInSmartfile(itemId));
  }, [dispatch, itemId]);

  return (
    <CheckBoxLinkPageLayout
      isSelected={isSelected}
      selectItem={selectItem}
      isDisabled={isDisabled}
    />
  );
};


export default CheckBoxLinkPage;
