import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import rawElemStyles from './elements.module.scss';
import SixDotsItem from './SixDotsItem';
import useComponentVisible from '../../../utils/hooks/useComponentVisible';
import {
  actionRemoveLinkPageFromPlaylist,
} from '../../../redux/currentPage/action';
import DeleteContentButton from './DeleteContentButton';
import { isRoleInPlaylist } from '../../../utils/permissions';

const cx = classNames.bind(rawElemStyles);

const ShellOptionSfElement = ({
  playlistId,
  refForVis,
  item,
  isExpanded,
  isDragHover,
  itemStylesObject,
  ...props
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isViewer } = isRoleInPlaylist;
  const { id: userId } = useSelector((state) => state.user);
  const currentPage = useSelector((state) => state.currentPage);
  const ownerID = currentPage?.owner?.id;
  const isAdmin = userId === ownerID;

  const [menuRef, isActive, setIsActive] = useComponentVisible(false, item.id);

  const deleteHandler = () => {
    dispatch(
      actionRemoveLinkPageFromPlaylist(
        playlistId,
        item.id,
        item.textComponent?.id || item.libraryComponent?.id,
      ),
    );
  };

  return (
    <div
      ref={refForVis}
      className={cx('six_dot_balancer', {
        isCompressView: props.isCompressView,
        hoverable: !isDragHover,
        active: isActive,
      })}
    >
      <div className={cx('empty_element_wrapper')}>
        <div
          className={`${cx('empty_element', {
            hoverable: !isDragHover,
            dragginOver: isDragHover,
            notEdit: userId !== item?.owner?.id,
            blink_it: props.blinkIdsArray?.includes(item.id),
          })} highlightedPlace`}
          onDragEnd={props.onDragEndHandler}
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
          onDragStart={props.dragStartHandler}
          draggable
        >
          <SixDotsItem
            clear={props.clear}
            createHandler={props.createHandler}
            onDragStartHandler={props.onDragStartHandler}
            onDragEndHandler={props.onDragEndHandler}
            title={t('emptyT')}
            playlistId={playlistId}
            menuRef={menuRef}
            isActive={isActive}
            dragStartHandler={props.dragStartHandler}
            setIsActive={setIsActive}
            item={item}
            itemStylesObject={itemStylesObject}
            otherOwner={userId !== item?.owner?.id && item.owner}
            isEmptyPlaceholder
            isPlaceHolder
            currentRole={props.currentRole}
            ownerID={ownerID}
            isExpandedView={isExpanded}
            isCompressView={props.isCompressView}
          />

          {props.children}

        </div>
        {!isViewer[props.currentRole]
          && ((item.owner && userId === item?.owner?.id) || isAdmin) && (
            <DeleteContentButton
              deleteHandler={deleteHandler}
              isCompressView={props.isCompressView}
            />
        )}
      </div>

    </div>
  );
};

export default ShellOptionSfElement;
