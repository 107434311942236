import {
  CurrentPage,
  DRAG_MANY_PAGE,
  DRAG_PAGE,
  EDIT_TITLE,
  GET_CURRENT_PAGE,
  GET_CURRENT_PLAYLIST,
  GO_TO_VIEW_PAGE,
  PAGE_WILL_DOWNLOAD,
  SAVE_CURRENT_PLAYLIST,
  SET_DRAGGABLE_BLOCKS,
  SWITCH_PAGE,
  UPDATE_BLOCK,
  UPDATE_LAYERS,
} from './types';
import { TYPE } from '../../utils/constants';

export const actionCurrentEditTitle = (
  title,
  componentId,
  inCollectionId,
  lastModifiedDate,
  isUpdateForbidden,
) => ({
  type: EDIT_TITLE,
  payload: {
    title,
    componentId,
    inCollectionId,
    lastModifiedDate,
    isUpdateForbidden,
  },
});
export const actionToggleIsCollapsedContent = (value) => ({
  type: CurrentPage.ToggleIsCollapsedContent,
  payload: { value },
});

export const actionClearCurrentPage = (option) => ({
  type: CurrentPage.ClearCurrentPage,
  payload: option,
});

export const actionSwitchPage = (page) => ({
  type: SWITCH_PAGE,
  payload: page,
});
export const actionUpdateBlock = (
  id,
  state,
  width,
  innerHtml,
  playlistField,
  imageUrl,
  type,
  content,
) => ({
  type: UPDATE_BLOCK,
  payload: {
    id,
    state,
    width,
    innerHtml,
    playlistField,
    imageUrl,
    type,
    content,
  },
});
export const actionShowPagesWidget = (isShowPagesWidget) => ({
  type: CurrentPage.ShowPagesWidget,
  payload: { isShowPagesWidget },
});
export const actionShowLibraryWidget = (
  isShowLibraryWidget,
  sideBarType = '',
) => ({
  type: CurrentPage.ShowLibraryWidget,
  payload: { isShowLibraryWidget, sideBarType },
});

export const actionResizeToLargeLibraryWidget = () => ({
  type: CurrentPage.ResizeLibraryWidget,
  payload: { isLargeRightBar: true },
});

export const actionResizeToSmallLibraryWidget = () => ({
  type: CurrentPage.ResizeLibraryWidget,
  payload: { isLargeRightBar: false },
});

export const actionUpdateShareState = (updateState) => ({
  type: CurrentPage.Share.UPDATE_SHARE_STATE,
  payload: { updateState },
});

export const actionUpdateLayers = (layers, playlistField) => ({
  type: UPDATE_LAYERS,
  payload: { layers, playlistField },
});

export const actionGetCurrentPlaylist = (
  playlistId,
  isViewMode,
  isViewAsCoEdit,
  history,
  isViewAsCoEditViewMode,
  channelID,
) => ({
  type: GET_CURRENT_PLAYLIST,
  payload: {
    id: playlistId,
    isViewMode,
    isViewAsCoEdit,
    history,
    isViewAsCoEditViewMode,
    channelID,
  },
});
export const actionSetResetScale = (value) => ({
  type: CurrentPage.SetResetScale,
  payload: { value },
});

export const actionGetCurrentPage = (
  pageId,
  viewMod,
  history,
  isSharedMod,
) => ({
  type: GET_CURRENT_PAGE,
  payload: { id: pageId, viewMod, history, isSharedMod },
});

export const actionUpdateDraggableBlocks = (isDraggable) => ({
  type: SET_DRAGGABLE_BLOCKS,
  payload: isDraggable,
});
export const actionToggleLibraryDrag = (isDraggable) => ({
  type: CurrentPage.ToggleLibraryDrag,
  payload: isDraggable,
});

export const actionDragPage = (
  idDragPage,
  previousFolderIdDrag,
  isDragFolder,
  type,
) => ({
  type: DRAG_PAGE,
  payload: { idDragPage, previousFolderIdDrag, isDragFolder, type },
});

export const actionDragManyPage = (isDragManyPage) => ({
  type: DRAG_MANY_PAGE,
  payload: { isDragManyPage },
});

export const actionGoToViewPage = (
  idViewPage,
  history,
  indexViewLesson,
  playlistId,
  isPreview,
) => ({
  type: GO_TO_VIEW_PAGE,
  payload: { idViewPage, history, indexViewLesson, playlistId, isPreview },
});

export const actionPageWillDownload = () => ({
  type: PAGE_WILL_DOWNLOAD,
});

export const actionToggleIsCardView = () => ({
  type: CurrentPage.ToggleIsCardView,
});

export const actionClearPreSaveFlag = (id, playlistField) => ({
  type: CurrentPage.ClearBlockPreSaveFlag,
  payload: { id, playlistField },
});

export const actionCreateEditableBlockWithState = (
  id,
  newState,
  pageId,
  isWidget,
  playlistField,
) => ({
  type: CurrentPage.CreateEditableBlockWithState,
  payload: { id, newState, pageId, isWidget, playlistField },
});

export const actionChangeTypeBlock = (type, id, playlistField) => ({
  type: CurrentPage.ChangeTypeBlock,
  payload: { type, id, playlistField },
});

export const actionSaveUniversalPlaylist = (playlist) => {
  return {
    type: SAVE_CURRENT_PLAYLIST,
    payload: playlist,
  };
};

export const actionSaveCurrentPlaylist = (playlist) => {
  const {
    id,
    img,
    description,
    title,
    users,
    cropUrl,
    hasModification,
    lastModifiedDate,
    isPublish,
    card,
    tags,
    defaultPreviewColor,
    category,
    publishPlaylistId,
    isShowDescription,
    usedInChannels,
    owner,
    usedLibraryComponents,
    isFinished,
  } = playlist;
  const payload = {
    id,
    title,
    content: {
      position: img?.edges && img?.edges[0]?.position,
      img: img?.edges && img?.edges[0]?.node?.urlFile,
      componentId: img?.edges && img?.edges[0]?.node?.id,
      description,
    },
    type: TYPE.PLAYLIST,
    playlistId: id,
    users,
    cropUrl,
    isFinished,
    card,
    publishPlaylistId,
    tags,
    hasModification,
    isPublish,
    isShowDescription,
    usedInChannels,
    owner,
    defaultPreviewColor,
    category,
    usedLibraryComponents,
    lastModifiedDate,
  };
  // if (hashtags) {
  //   payload.hashtags = hashtags.map(item => ({ ...item, title: item.hashtagTitle }));
  // }

  return {
    type: SAVE_CURRENT_PLAYLIST,
    payload,
  };
};

export const actionToggleIsBlockHidden = (
  id,
  value,
  isLibComponent,
  playlistField,
) => {
  return {
    type: CurrentPage.ToggleComponentIsHidden,
    payload: { id, value, isLibComponent, playlistField },
  };
};
export const actionBulkToggleIsBlockHidden = (
  idsValuesArray,
  playlistField,
) => {
  return {
    type: CurrentPage.BulkToggleComponentIsHidden,
    payload: { idsValuesArray, playlistField },
  };
};

export const actionChangeStateButton = (buttonState) => {
  return {
    type: CurrentPage.ChangeStateComponentButton,
    payload: { buttonState },
  };
};
export const actionChangeStateButtonREDUX = (buttonState) => {
  return {
    type: CurrentPage.ChangeStateComponentButtonREDUX,
    payload: { buttonState },
  };
};

export const actionSaveInputPlaylist = (
  field,
  state,
  place,
  inCollectionId,
  itemId,
) => {
  return {
    type: CurrentPage.saveInputPlaylist,
    payload: { field, state, place, inCollectionId, itemId },
  };
};

export const actionToggleHideAdditionalInformation = (field, state) => {
  return {
    type: CurrentPage.toggleHideAdditionalInformation,
    payload: { field, state },
  };
};

export const actionTryPublishPlaylist = () => {
  return {
    type: CurrentPage.TryPublishPlaylist,
  };
};
export const actionSetAvailabilityDate = ({ availableFrom, availableTo }) => ({
  type: CurrentPage.SetAvailabilityDate,
  payload: { availableFrom, availableTo },
});

export const actionUpdatePlaylistLastModified = ({ id, lastModifiedDate }) => ({
  type: CurrentPage.UpdatePlaylistLastModifiedDate,
  payload: { id, lastModifiedDate },
});
export const actionUpdateCurrentPageTitle = (newTitle) => ({
  type: CurrentPage.UpdateCurrentPageTitle,
  payload: { newTitle },
});

export const actionDuplicateLinkPageSInCurrentPage = (
  item,
  newPosition,
  playListId,
  newId,
) => ({
  type: CurrentPage.DuplicateLinkPageS,
  payload: { item, newPosition, playListId, newId },
});

export const actionUpdateCollapseLinkPageRS = ({ isCollapsed, linkPageId, isCollapsedAll }) => ({
  type: CurrentPage.UpdateCollapseLinkPageRS,
  payload: { isCollapsed, linkPageId, isCollapsedAll },
});

export const actionEmptyAddLinkPageToPlaylist = (
  playlistId,
  pageData,
  callback,
) => ({
  type: CurrentPage.AddEmptyLinkPageToPlaylist,
  payload: { playlistId, pageData, callback },
});

export const actionRemoveLinkPageFromPlaylist = (
  playlistId,
  linkPageIds,
  textComponentIds,
) => ({
  type: CurrentPage.RemoveLinkPageFromPlaylist,
  payload: { playlistId, linkPageIds, textComponentIds },
});

export const actionRemoveLinkPagesFromPlaylist = (
  playlistId,
  linkPagesIds,
  textComponentsIds,
) => ({
  type: CurrentPage.RemoveLinkPagesFromPlaylist,
  payload: { playlistId, linkPagesIds, textComponentsIds },
});

export const actionSelectItemInSmartfile = (
  itemId, isMouseSelection,
) => ({
  type: CurrentPage.SelectItemInSmartfile,
  payload: { itemId, isMouseSelection },
});
export const actionSelectItemsInSmartfile = (
  checkBoxState,
) => ({
  type: CurrentPage.SelectAllItemsInSmartfile,
  payload: { checkBoxState },
});
export const actionClearItemsSelectionInSmartfile = () => ({
  type: CurrentPage.ClearItemsSelectionInSmartfile,
});

export const actionChangeLinkPageTypeR = (playlistId, pageData) => ({
  type: CurrentPage.ChangeLinkPageTypeR,
  payload: { playlistId, pageData },
});

export const actionChangeLinkPageType = (
  linkPageId,
  newType,
  playlistId,
  text,
  updateCBaction,
) => ({
  type: CurrentPage.ChangeLinkPageTypeS,
  payload: { linkPageId, newType, playlistId, text, updateCBaction },
});

export const actionCancelUploadAndChangeLinkPageType = (
  linkPageId,
  playlistId,
  componentId,
) => ({
  type: CurrentPage.CancelUploadAndChangeLinkPageTypeS,
  payload: { linkPageId, playlistId, componentId },
});

export const actionUpdateLinkPage = (playlistId, pageData, isNew, replace) => ({
  type: CurrentPage.UpdateLinkPage,
  payload: { playlistId, pageData, isNew, replace },
});

export const actionManyUpdatePositionLinkPagesR = (
  playlistId,
  linkPagesData,
  isSocket,
) => ({
  type: CurrentPage.ManyUpdatePositionLinkPages,
  payload: { playlistId, linkPagesData, isSocket },
});

export const actionUpdateLinkPageR = (playlistId, pageData, isNew) => ({
  type: CurrentPage.UpdateLinkPageR,
  payload: { playlistId, pageData, isNew },
});

export const actionReplaceLinkPagePlaylistPageR = (
  linkPageId,
  playlistId,
  linkPageData,
) => ({
  type: CurrentPage.ReplaceLinkPagePlaylistPageR,
  payload: { linkPageId, playlistId, linkPageData },
});

export const actionReplaceLinkPagePlaylist = (
  linkPageId,
  playlistId,
  previousFolderIdDrag,
  idDragPage,
) => ({
  type: CurrentPage.ReplaceLinkPagePlaylistPage,
  payload: { linkPageId, playlistId, previousFolderIdDrag, idDragPage },
});

export const actionMovePageWithinPlaylist = (updatedLinkPages, playlistId) => ({
  type: CurrentPage.MovePageWithinPlaylist,
  payload: { updatedLinkPages, playlistId },
});

export const actionCreateLibLinkPagePlaylist = (
  newLinkPage,
  playlistId,
  previousFolderIdDrag,
  idDragPage,
  metod,
) => ({
  metod,
  type: CurrentPage.CreateLibLinkPagePlaylist,
  payload: { newLinkPage, playlistId, previousFolderIdDrag, idDragPage },
});

export const actionCreateLinkPageR = (playlistId, pageData) => ({
  type: CurrentPage.CreateLinkPageR,
  payload: { playlistId, pageData },
});

export const actionCreateAndUploadLinkPage = (
  file,
  index,
  history,
  newPosition,
  currentItemId,
  isMultiUpload,
  isAddFavorites,
  localTags = [],
  isGoogleEmbed,
  linkedAccountId,
  newId,
) => ({
  type: CurrentPage.CreateAndUploadLinkPage,
  payload: {
    file,
    index,
    history,
    newPosition,
    currentItemId,
    isMultiUpload,
    isAddFavorites,
    tags: localTags,
    isGoogleEmbed,
    linkedAccountId,
    newId,
  },
});

export const actionClearLinkPageType = (
  linkPageId,
  oldType,
  playlistId,
  addedElementId,
) => ({
  type: CurrentPage.ClearLinkPageType,
  payload: { linkPageId, oldType, playlistId, addedElementId },
});
// 2 actions above will be used to scroll to element in Maker.
export const actionClearToBeScrollTo = () => ({
  type: CurrentPage.ClearToBeScrollTo,
});
export const actionAddToBeScrollTo = (id) => ({
  type: CurrentPage.AddElementToBeScrollTo,
  payload: id,
});

export const actionSetSingleUserShareState = (shareState) => ({
  type: CurrentPage.setSingleUserShareState,
  payload: shareState,
});

export const actionSetAllUserShareState = (shareState) => ({
  type: CurrentPage.setAllUserShareState,
  payload: shareState,
});

export const actionSetGlobalShareStateToSingleUserState = (shareState) => ({
  type: CurrentPage.setGlobalShareStateToSingleUserState,
  payload: shareState,
});

export const actionMarkAllLinkPagesAsIncomplete = (wrapperId) => ({
  type: CurrentPage.markAllLinkPagesAsIncomplete,
  payload: { wrapperId },
});

export const actionSetNewTotalPlaylistDuration = (duration) => ({
  type: CurrentPage.changePlaylistDurationRedux,
  payload: duration,
});

export const actionDurationByPlaybackId = (duration, linkPageId) => ({
  type: CurrentPage.changeLinkPageDurationByLinkPageIdRedux,
  payload: { duration, linkPageId },
});

export const actionToggleSideBarExpand = (condition) => ({
  type: CurrentPage.ToggleSideBarExpand,
  payload: condition,
});

export const actionAddMultiplePagesToPlaylistFromSideBar = () => ({
  type: CurrentPage.addMultiplePagesToPlaylistFromSideBar,
});

export const actionAddPageToPlaylistFromSideBar = (idDragPage, newPosition) => ({
  type: CurrentPage.addPageToPlaylistFromSideBarDnD,
  payload: { idDragPage, newPosition },
});

export const actionAddMultiplePageToPlaylistFromSideBarDnD = (index, selectedPage) => ({
  type: CurrentPage.addMultiplePagesToPlaylistFromSideBarDnD,
  payload: { selectedPage, index },
});

export const actionSaveSummarize = (responseText) => ({
  type: CurrentPage.SaveSummarize,
  payload: { responseText },
});

export const actionSaveSearchResultsR = (ids, matchesObj, keywords) => ({
  type: CurrentPage.SaveSearchResults,
  payload: { ids, matchesObj, keywords },
});

export const actionAIProcessingToggleLoading = (isLoading, aiType) => ({
  type: CurrentPage.AIProcessingToggleLoading,
  payload: { isLoading, aiType },
});

export const actionAIToggleVectorizeLinkPageRS = (linkPageIds, newState) => ({
  type: CurrentPage.aiToggleVectorizeLinkPageRS,
  payload: { linkPageIds, newState },
});

export const actionToggleSettingPreviewRS = (playlistManagerId, settingType, isHidden) => ({
  type: CurrentPage.ToggleSettingPreviewRS,
  payload: { playlistManagerId, settingType, isHidden },
});
