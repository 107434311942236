import { v4 as uuid } from 'uuid';
import {
  calculateIndex,
  getManyNewLinkPagePositionByIndex, getNewLinkPagePositionByIndex,
  validateURL,
} from '../helpers';
import { DEFAULT_POSITION_STEP, openModalType } from '../constants';
import { actionCreator } from '../../shared/redux/actionHelper';
import {
  actionAddMultiplePageToPlaylistFromSideBarDnD,
  actionAddPageToPlaylistFromSideBar,
  actionCreateAndUploadLinkPage, actionDuplicateLinkPageSInCurrentPage,
  actionMovePageWithinPlaylist,
  actionReplaceLinkPagePlaylist,
} from '../../redux/currentPage/action';
import { CurrentPage } from '../../redux/currentPage/types';
import { actionOpenAIModal } from '../../redux/user/action';
import { actionAddLinkPagesToDragState, actionToggleUploadingFilesState } from '../../redux/library/actions';

const dropInPlaylistHandler = (
  e,
  linkPageId,
  index,
  replace,
  linkPages,
  dispatch,
  history,
  draggablePage,
  selectedPage,
  idDragPage,
  selectedIndex,
  setBlinkId,
  selectedIds,
  id,
  filteredFilesArr,
  user,
  draggableItems,
  rawPages,
) => {
  const aiOn = localStorage.getItem('aiOn');
  const previousFolderIdDrag = null;
  const isDroppedWithPosition = (index || index === 0) && index !== 'last';
  // dropDepthRef.current = 0;
  let newPosition;
  const files = filteredFilesArr ?? e.dataTransfer.files;
  if (index === 0) {
    newPosition = calculateIndex(0, linkPages[index]?.position);
  } else if (isDroppedWithPosition) {
    newPosition = calculateIndex(
      linkPages[index]?.position,
      linkPages[index - 1]?.position
      || linkPages[index]?.position + DEFAULT_POSITION_STEP,
    );
  } else {
    newPosition = calculateIndex(linkPages[linkPages.length - 1]?.position);
  }

  if (
    e.dataTransfer.types.filter(
      (i) => i === 'text/plain' || i === 'text/uri-list',
    ).length
  ) {
    const dropDataText = e.dataTransfer.getData('text/plain');
    // const dropDataHtml = e.dataTransfer.getData('text/html');
    // let hasOneImage;
    // let urlTextToCreate;
    const isUrl = validateURL(dropDataText);
    // let imageDataTitle = '';
    // if (dropDataHtml) {
    //   const nodes = createNodeFromHtml(dropDataHtml);
    //   const images = nodes.content.querySelectorAll('img');
    //   const paragraphs = nodes.content.querySelectorAll('p');
    //   imageDataTitle = images[0]?.alt;
    //   if (images.length === 1 && !paragraphs.length) {
    //     hasOneImage = images?.length === 1;
    //     urlTextToCreate = images[0]?.src;
    //   }
    // }

    if (isUrl) {
      dispatch(
        actionCreator(CurrentPage.CreateLinkPageWithURL, {
          history,
          urlText: dropDataText,
          newPosition,
        }),
      );
    } else if (dropDataText) {
      // const newId = uuidv4();
      // const newState = EditorState.createEmpty();
      // dispatch(actionOpenCreateComponent());
      // const result = pastedHandlerHelper(newState, 'new', newId, dropDataText, dropDataHtml);
      //
      // const { type, payload } = result;
      // switch (type) {
      //   case ('ADD_BLOCKS'): {
      //     // dispatch(actionAddManyEditableBlock(
      //     //   newId, payload, null,
      //     // ));
      //     break;
      //   }
      //   case ('CREATE_AS_TEXT'): {
      //     // dispatch(actionAddEditableBlockRedux(newId, 'text', null, null, dropDataText));
      //     break;
      //   }
      //   case ('CREATE_AS_LINK'):
      //
      //   default:
      // }
    }
  } else if (files.length) {
    dispatch(actionToggleUploadingFilesState(true));
    const dndFiles = [...files];
    const customItems = [];
    dndFiles.forEach((item, indexFile) => {
      let step;
      let previousPosition;
      if (index === 'last') {
        previousPosition = linkPages[linkPages.length - 1]?.position || DEFAULT_POSITION_STEP;
        const nextPosition = previousPosition + DEFAULT_POSITION_STEP;
        step = (nextPosition - previousPosition)
          / (Object.values(dndFiles).length + 1) || DEFAULT_POSITION_STEP;
      } else {
        const nextPosition = linkPages[index]?.position || DEFAULT_POSITION_STEP;
        previousPosition = linkPages[index - 1]?.position || 0;
        step = (nextPosition - previousPosition)
          / (Object.values(dndFiles).length + 1) || DEFAULT_POSITION_STEP;
      }
      const itemPosition = previousPosition + step * (indexFile + 1);
      const newId = uuid();
      customItems.push({ title: item.name, id: newId });
      dispatch(
        actionCreateAndUploadLinkPage(item, index, history, itemPosition,
          null, null, null, null, null,
          null, newId),
      );
    });
    // TODO clarify the behavior
    // if (aiOn) {
    //   dispatch(actionOpenAIModal(openModalType.AIUniversal, {
    //     items: customItems,
    //     aiType: 'ai_task',
    //   }));
    // }
    return;
  }

  const isSamePlace = index === draggablePage.itemIndex
    || index === draggablePage.itemIndex + 1
    || (selectedIndex.length && selectedIndex.includes(index));
  if (isSamePlace) return;

  const dndFromLibSideBar = !!idDragPage;
  const multipleDndFromLibSideBar = !!Object.values(selectedPage).length;
  if (replace && !linkPages[index]?.type && dndFromLibSideBar) {
    dispatch(
      actionReplaceLinkPagePlaylist(
        linkPageId,
        id,
        previousFolderIdDrag,
        idDragPage,
      ),
    );
  } else if (dndFromLibSideBar) {
    dispatch(actionAddPageToPlaylistFromSideBar(idDragPage, newPosition));
  } else if (multipleDndFromLibSideBar) {
    dispatch(actionAddMultiplePageToPlaylistFromSideBarDnD(index, selectedPage));
  } else if (!selectedIds.length && draggablePage.id) {
    if (draggablePage.isDuplicate) {
      const item = linkPages.find(i => i.id === draggablePage.id);
      const clearedIndex = index === 'last' ? rawPages.length : index;
      const newPos = getNewLinkPagePositionByIndex(clearedIndex, rawPages);
      const newId = uuid();
      dispatch(
        actionDuplicateLinkPageSInCurrentPage(
          item,
          newPos,
          id,
          newId,
        ),
      );
      setBlinkId(newId);
      return;
    }
    setBlinkId(draggablePage.id);
    dispatch(
      actionCreator(CurrentPage.MovePageWithinPlaylistMaker, {
        id: draggablePage.id,
        position: newPosition,
      }),
    );
  } else if (selectedIds.length) {
    const newPosAr = getManyNewLinkPagePositionByIndex(
      index,
      linkPages,
      selectedIndex,
    );
    const movedUpdatedLinks = newPosAr.map((newPos, itemIndex) => ({
      ...linkPages[selectedIndex[itemIndex]],
      position: newPos,
    }));
    dispatch(actionMovePageWithinPlaylist(movedUpdatedLinks, id));
  }
  // TODO clarify the behavior
  // if (aiOn && (dndFromLibSideBar || multipleDndFromLibSideBar)) {
  //   dispatch(actionOpenAIModal(openModalType.AIUniversal, {
  //     items: draggableItems,
  //     aiType: 'ai_task',
  //   }));
  // }
  dispatch(actionAddLinkPagesToDragState([]));
};

export default dropInPlaylistHandler;
